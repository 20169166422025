import React from 'react'
import {
  AsyncAutocompleteField,
  AutocompleteField,
  MultiAutocompleteField,
} from '@jsluna/autocomplete'

export default {
  title: 'Autocomplete',
  component: AutocompleteField,
  subcomponents: { MultiAutocompleteField },
}

const options = [
  { label: "Sainsbury's Groceries", value: 'groceries' },
  { label: "Sainsbury's Bank", value: 'bank' },
  { label: 'Tu', value: 'tu' },
  { label: 'Argos', value: 'argos' },
  { label: 'Habitat', value: 'habitat' },
  { label: 'Nectar', value: 'nectar' },
]

export const Single = () => {
  return (
    <div data-testid="autocomplete-1">
      <AutocompleteField
        name="autocomplete-field-1"
        placeholder="Example: Nectar, Argos"
        label="Choose a Sainsbury's brand"
        options={options}
        role="search"
      />
    </div>
  )
}

const SingleSourceCode = `
import { AutocompleteField } from '@jsluna/autocomplete'

const options = [
  { label: "Sainsbury's Groceries", value: 'groceries' },
  { label: "Sainsbury's Bank", value: 'bank' },
  { label: 'Tu', value: 'tu' },
  { label: 'Argos', value: 'argos' },
  { label: 'Habitat', value: 'habitat' },
  { label: 'Nectar', value: 'nectar' },
]

export const Single = () => {
  return (
    <div data-testid="autocomplete-1">
      <AutocompleteField
        name="autocomplete-field-1"
        placeholder="Example: Nectar, Argos"
        label="Choose a Sainsbury's brand"
        options={options}
        role="search"
      />
    </div>
  )
}
`

Single.parameters = {
  docs: {
    source: {
      code: SingleSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Multiselect = () => {
  return (
    <div data-testid="multi-autocomplete-1">
      <MultiAutocompleteField
        name="multi-autocomplete-field-1"
        placeholder="Example: Nectar, Argos"
        label="Choose a Sainsbury's brand"
        options={options}
      />
    </div>
  )
}

const MultiselectSourceCode = `
import { MultiAutocompleteField } from '@jsluna/autocomplete'

const options = [
  { label: "Sainsbury's Groceries", value: 'groceries' },
  { label: "Sainsbury's Bank", value: 'bank' },
  { label: 'Tu', value: 'tu' },
  { label: 'Argos', value: 'argos' },
  { label: 'Habitat', value: 'habitat' },
  { label: 'Nectar', value: 'nectar' },
]


export const Multiselect = () => {
  return (
    <div data-testid="multi-autocomplete-1">
      <MultiAutocompleteField
        name="multi-autocomplete-field-1"
        placeholder="Example: Nectar, Argos"
        label="Choose a Sainsbury's brand"
        options={options}
      />
    </div>
  )
}
`

Multiselect.parameters = {
  docs: {
    source: {
      code: MultiselectSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const SingleAsync = () => {
  const loadOptions = value =>
    fetch(`https://api.github.com/search/repositories?q=${value}`)
      .then(response => response.json())
      .then(response =>
        response.items.map(item => ({
          label: item.name,
          value: item.id.toString(),
        })),
      )

  return (
    <div data-testid="autocomplete-async-1">
      <AsyncAutocompleteField
        name="async-autocomplete-field-1"
        placeholder="Please select"
        label="Select GitHub project"
        loadOptions={loadOptions}
        role="search"
      />
    </div>
  )
}

const SingleAsyncSourceCode = `
import { AsyncAutocompleteField } from '@jsluna/autocomplete'

export const SingleAsync = () => {
  const loadOptions = value =>
  fetch(\`https://api.github.com/search/repositories?q=\${value}\`)
      .then(response => response.json())
      .then(response =>
        response.items.map(item => ({
          label: item.name,
          value: item.id.toString(),
        })),
      )

  return (
    <div data-testid="autocomplete-async-1">
      <AsyncAutocompleteField
        name="async-autocomplete-field-1"
        placeholder="Please select"
        label="Select GitHub project"
        loadOptions={loadOptions}
        role="search"
      />
    </div>
  )
}
`

SingleAsync.parameters = {
  docs: {
    source: {
      code: SingleAsyncSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const MultiselectAsync = () => {
  const loadOptions = value =>
    fetch(`https://api.github.com/search/repositories?q=${value}`)
      .then(response => response.json())
      .then(response =>
        response.items.map(item => ({
          label: item.name,
          value: item.id.toString(),
        })),
      )
  return (
    <AsyncAutocompleteField
      name="async-autocomplete-field-2"
      placeholder="Please select"
      label="Select GitHub project"
      loadOptions={loadOptions}
    >
      <MultiAutocompleteField />
    </AsyncAutocompleteField>
  )
}

const MultiselectAsyncSourceCode = `
import { 
  AsyncAutocompleteField, 
  MultiAutocompleteField
} from '@jsluna/autocomplete'

export const MultiselectAsync = () => {
  const loadOptions = value =>
  fetch(\`https://api.github.com/search/repositories?q=\${value}\`)
      .then(response => response.json())
      .then(response =>
        response.items.map(item => ({
          label: item.name,
          value: item.id.toString(),
        })),
      )

  return (
    <AsyncAutocompleteField
      name="async-autocomplete-field-2"
      placeholder="Please select"
      label="Select GitHub project"
      loadOptions={loadOptions}
    >
      <MultiAutocompleteField />
    </AsyncAutocompleteField>
  )
}
`

MultiselectAsync.parameters = {
  docs: {
    source: {
      code: MultiselectAsyncSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
