import React from 'react'
import { List, ListItem } from '@jsluna/list'

export default {
  title: 'Lists',
  component: List,
}

export const Bare = () => (
  <List
    items={['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5']}
    type="bare"
    spaced
  />
)

const BareSourceCode = `
import { List } from '@jsluna/list'
 
export const Bare = () => (
  <List
    items={['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5']}
    type="bare"
    spaced
  />
)
`

Bare.parameters = {
  docs: {
    source: {
      code: BareSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Inline = () => (
  <List
    items={['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5']}
    type="inline"
    spaced
  />
)

const InlineSourceCode = `
import { List } from '@jsluna/list'
 
export const Inline = () => (
  <List
    items={['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5']}
    type="inline"
    spaced
  />
)
`

Inline.parameters = {
  docs: {
    source: {
      code: InlineSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Matrix = () => (
  <List
    items={Array(20)
      .fill(0)
      .map((_, index) => `Item ${index + 1}`)}
    type="matrix"
    spaced
  />
)

const MatrixSourceCode = `
import { List } from '@jsluna/list'
 
export const Matrix = () => (
  <List
    items={Array(20)
      .fill(0)
      .map((_, index) => \`Item \${index + 1}\`)}
    type="matrix"
    spaced
  />
)
`

Matrix.parameters = {
  docs: {
    source: {
      code: MatrixSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Justified = () => (
  <List items={['Item 1', 'Item 2', 'Item 3']} type="justified" />
)

const JustifiedSourceCode = `
import { List } from '@jsluna/list'
 
export const Justified = () => (
  <List items={['Item 1', 'Item 2', 'Item 3']} type="justified" />
)
`

Justified.parameters = {
  docs: {
    source: {
      code: JustifiedSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Custom = () => (
  <List type="matrix" spaced>
    {Array(9)
      .fill(0)
      .map((_, index) => (
        <ListItem key={index}>
          <span className="ln-u-display-inline-block ln-u-soft ln-u-bg-color-light">
            {index + 1}
          </span>
        </ListItem>
      ))}
  </List>
)

const CustomSourceCode = `
import { List, ListItem } from '@jsluna/list'
 
export const Custom = () => (
  <List type="matrix" spaced>
    {Array(9)
      .fill(0)
      .map((_, index) => (
        <ListItem key={index}>
          <span className="ln-u-display-inline-block ln-u-soft ln-u-bg-color-light">
            {index + 1}
          </span>
        </ListItem>
      ))}
  </List>
)
`

Custom.parameters = {
  docs: {
    source: {
      code: CustomSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
