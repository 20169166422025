import React from 'react'
import { Tabs, TabLink, TabPanel } from '@jsluna/tabs'
import { Card } from '@jsluna/card'

export default {
  title: 'Tabs',
  component: Tabs,
}

export const Standard = () => {
  const tabs = [
    { name: 'Fruit & Veg', key: 'fruit' },
    { name: 'Meat & Fish', key: 'meat' },
    { name: 'Dairy', key: 'dairy' },
    { name: 'Chilled', key: 'chilled' },
    { name: 'Bakery', key: 'bakery' },
  ]

  const [activeTab, setActiveTab] = React.useState('fruit')

  return (
    <div>
      <Tabs>
        {tabs.map(tab => (
          <TabLink
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            active={activeTab === tab.key}
          >
            {tab.name}
          </TabLink>
        ))}
      </Tabs>
      <TabPanel>
        <Card>
          <h3>{tabs.find(tab => activeTab === tab.key).name} Section</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </Card>
      </TabPanel>
    </div>
  )
}

const StandardSourceCode = `
import { Tabs, TabLink, TabPanel } from '@jsluna/tabs'
import { Card } from '@jsluna/card'

export const Standard = () => {
  const tabs = [
    { name: 'Fruit & Veg', key: 'fruit' },
    { name: 'Meat & Fish', key: 'meat' },
    { name: 'Dairy', key: 'dairy' },
    { name: 'Chilled', key: 'chilled' },
    { name: 'Bakery', key: 'bakery' },
  ]

  const [activeTab, setActiveTab] = React.useState('fruit')

  return (
    <div>
      <Tabs>
        {tabs.map(tab => (
          <TabLink
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            active={activeTab === tab.key}
          >
            {tab.name}
          </TabLink>
        ))}
      </Tabs>
      <TabPanel>
        <Card>
          <h3>{tabs.find(tab => activeTab === tab.key).name} Section</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </Card>
      </TabPanel>
    </div>
  )
}
`

Standard.parameters = {
  docs: {
    source: {
      code: StandardSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Deep = () => {
  const tabs = [
    { name: 'Fruit & Veg', key: 'fruit' },
    { name: 'Meat & Fish', key: 'meat' },
    { name: 'Dairy', key: 'dairy' },
    { name: 'Chilled', key: 'chilled' },
    { name: 'Bakery', key: 'bakery' },
  ]
  const [activeTab, setActiveTab] = React.useState('fruit')

  return (
    <div>
      <Tabs type="deep">
        {tabs.map(tab => (
          <TabLink
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            active={activeTab === tab.key}
          >
            {tab.name}
          </TabLink>
        ))}
      </Tabs>
      <TabPanel>
        <Card>
          <h3>{tabs.find(tab => activeTab === tab.key).name} Section</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </Card>
      </TabPanel>
    </div>
  )
}

const DeepSourceCode = `
import { Tabs, TabLink, TabPanel } from '@jsluna/tabs'
import { Card } from '@jsluna/card'

export const Deep = () => {
  const tabs = [
    { name: 'Fruit & Veg', key: 'fruit' },
    { name: 'Meat & Fish', key: 'meat' },
    { name: 'Dairy', key: 'dairy' },
    { name: 'Chilled', key: 'chilled' },
    { name: 'Bakery', key: 'bakery' },
  ]
  const [activeTab, setActiveTab] = React.useState('fruit')

  return (
    <div>
      <Tabs type="deep">
        {tabs.map(tab => (
          <TabLink
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            active={activeTab === tab.key}
          >
            {tab.name}
          </TabLink>
        ))}
      </Tabs>
      <TabPanel>
        <Card>
          <h3>{tabs.find(tab => activeTab === tab.key).name} Section</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </Card>
      </TabPanel>
    </div>
  )
}
`

Deep.parameters = {
  docs: {
    source: {
      code: DeepSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Fill = () => {
  const tabs = [
    { name: 'Dairy', key: 'dairy' },
    { name: 'Chilled', key: 'chilled' },
    { name: 'Bakery', key: 'bakery' },
  ]
  const [activeTab, setActiveTab] = React.useState('dairy')

  return (
    <div>
      <Tabs type="fill">
        {tabs.map(tab => (
          <TabLink
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            active={activeTab === tab.key}
          >
            {tab.name}
          </TabLink>
        ))}
      </Tabs>
      <TabPanel>
        <Card>
          <h3>{tabs.find(tab => activeTab === tab.key).name} Section</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </Card>
      </TabPanel>
    </div>
  )
}

const FillSourceCode = `
import { Tabs, TabLink, TabPanel } from '@jsluna/tabs'
import { Card } from '@jsluna/card'

export const Fill = () => {
  const tabs = [
    { name: 'Dairy', key: 'dairy' },
    { name: 'Chilled', key: 'chilled' },
    { name: 'Bakery', key: 'bakery' },
  ]
  const [activeTab, setActiveTab] = React.useState('dairy')

  return (
    <div>
      <Tabs type="fill">
        {tabs.map(tab => (
          <TabLink
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            active={activeTab === tab.key}
          >
            {tab.name}
          </TabLink>
        ))}
      </Tabs>
      <TabPanel>
        <Card>
          <h3>{tabs.find(tab => activeTab === tab.key).name} Section</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </Card>
      </TabPanel>
    </div>
  )
}
`

Fill.parameters = {
  docs: {
    source: {
      code: FillSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Pill = () => {
  const tabs = [
    { name: 'Fruit & Veg', key: 'fruit' },
    { name: 'Meat & Fish', key: 'meat' },
    { name: 'Dairy', key: 'dairy' },
    { name: 'Chilled', key: 'chilled' },
    { name: 'Bakery', key: 'bakery' },
  ]

  const [activeTab, setActiveTab] = React.useState('fruit')

  return (
    <div>
      <Tabs type="pill">
        {tabs.map(tab => (
          <TabLink
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            active={activeTab === tab.key}
          >
            {tab.name}
          </TabLink>
        ))}
      </Tabs>
      <TabPanel>
        <Card>
          <h3>{tabs.find(tab => activeTab === tab.key).name} Section</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </Card>
      </TabPanel>
    </div>
  )
}

const PillSourceCode = `
import { Tabs, TabLink, TabPanel } from '@jsluna/tabs'
import { Card } from '@jsluna/card'

export const Pill = () => {
  const tabs = [
    { name: 'Fruit & Veg', key: 'fruit' },
    { name: 'Meat & Fish', key: 'meat' },
    { name: 'Dairy', key: 'dairy' },
    { name: 'Chilled', key: 'chilled' },
    { name: 'Bakery', key: 'bakery' },
  ]

  const [activeTab, setActiveTab] = React.useState('fruit')

  return (
    <div>
      <Tabs type="pill">
        {tabs.map(tab => (
          <TabLink
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            active={activeTab === tab.key}
          >
            {tab.name}
          </TabLink>
        ))}
      </Tabs>
      <TabPanel>
        <Card>
          <h3>{tabs.find(tab => activeTab === tab.key).name} Section</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </Card>
      </TabPanel>
    </div>
  )
}
`

Pill.parameters = {
  docs: {
    source: {
      code: PillSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
