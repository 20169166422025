import headerBarsExpanded from '@images/header/header-bars-expanded.png';
import headerElementCentre from '@images/header/element-placement-centre-1.png';
import headerElementCentre2 from '@images/header/element-placement-centre-2.png';
import headerElementCentre3 from '@images/header/element-placement-centre-3.png';
import headerGlobal from '@images/header/bar-global-nav.png';
import headerMainNav from '@images/header/bar-main-nav.png';
import headerSearch from '@images/header/bar-search.png';
import headerTabs from '@images/header/bar-tabs.png';
import headerElementPlacement from '@images/header/element-placement-left-1.png';
import headerElementPlacement2 from '@images/header/element-placement-right-1.png';
import headerSkipLink from '@images/header/accessibility-skip-link.png';
import headerNativeLeftIcon from '@images/header/LeftIcon.png';
import headerNativeRightIcon from '@images/header/RightIcon.png';
import headerNativeTitle from '@images/header/Title.png';
import headerNativeLeftAction from '@images/header/LeftAction.png';
import headerNativeRightAction from '@images/header/RightAction.png';
import headerNativeReadingOrder from '@images/header/ReadingOrder.png';
import * as React from 'react';
export default {
  headerBarsExpanded,
  headerElementCentre,
  headerElementCentre2,
  headerElementCentre3,
  headerGlobal,
  headerMainNav,
  headerSearch,
  headerTabs,
  headerElementPlacement,
  headerElementPlacement2,
  headerSkipLink,
  headerNativeLeftIcon,
  headerNativeRightIcon,
  headerNativeTitle,
  headerNativeLeftAction,
  headerNativeRightAction,
  headerNativeReadingOrder,
  React
};