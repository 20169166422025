import React from 'react'

import AccessibilityIcon from '@images/icons/accessibility_icon.svg'
import ColourIcon from '@images/icons/colour_icon.svg'
import ComponentsIcon from '@images/icons/components_icon.svg'
import CopyIcon from '@images/icons/copy_icon.svg'
import DesignerIcon from '@images/icons/designer_icon.svg'
import DeveloperIcon from '@images/icons/developer_icon.svg'
import ErrorMessagesIcon from '@images/icons/error-messages_icon.svg'
import FoundationsIcon from '@images/icons/foundations_icon.svg'
import GetInTouchIcon from '@images/icons/get-in-touch_icon.svg'
import IconsIcon from '@images/icons/icons_icon.svg'
import InclusiveLanguageIcon from '@images/icons/inclusive-language_icon.svg'
import LayoutIcon from '@images/icons/layout_icon.svg'
import LogosIcon from '@images/icons/logos_icon.svg'
import ResourcesIcon from '@images/icons/resources_icon.svg'
import StyleGuideIcon from '@images/icons/style-guide_icon.svg'
import ThirdPartyIcon from '@images/icons/third-party_icon.svg'
import TypographyIcon from '@images/icons/typography_icon.svg'
import WritingForColleaguesIcon from '@images/icons/writing-for-colleagues_icon.svg'
import WritingPrinciplesIcon from '@images/icons/writing-principles_icon.svg'

const IconList = {
  'accessibility': <AccessibilityIcon />,
  'colour': <ColourIcon />,
  'components': <ComponentsIcon />,
  'copy': <CopyIcon />,
  'designer': <DesignerIcon />,
  'developer': <DeveloperIcon />,
  'error-messages': <ErrorMessagesIcon />,
  'foundations': <FoundationsIcon />,
  'get-in-touch': <GetInTouchIcon />,
  'icons': <IconsIcon />,
  'inclusive-language': <InclusiveLanguageIcon />,
  'layout': <LayoutIcon />,
  'logos': <LogosIcon />,
  'resources': <ResourcesIcon />,
  'style-guide': <StyleGuideIcon />,
  'third-party': <ThirdPartyIcon />,
  'typography': <TypographyIcon />,
  'writing-for-colleagues': <WritingForColleaguesIcon />,
  'writing-principles': <WritingPrinciplesIcon />,
}

const Icon = ({ iconName }) => {
  return <div style={{ paddingBottom: '22px' }}>{IconList[iconName]}</div>
}

export default Icon
