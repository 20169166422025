import React from 'react'
import { Tooltip, TooltipIcon, TooltipMessage } from '@jsluna/labs'
import { onKeyPress } from '@jsluna/utils'
import { SelectField } from '@jsluna/form'
import { Form, FormGroup, Label, TextInput } from '@jsluna/form'

export default {
  title: 'Tooltip',
  component: Tooltip,
}

export const Standard = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Tooltip open={isOpen} tooltipId="tooltip-1" position="right">
      <TooltipIcon
        onClick={e => {
          setIsOpen(!isOpen)
          e.preventDefault()
        }}
        onBlur={() => {
          setIsOpen(false)
        }}
        onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
          setIsOpen(false),
        )}
      />
      <TooltipMessage>Right</TooltipMessage>
    </Tooltip>
  )
}

const StandardSourceCode = `
import { Tooltip, TooltipIcon, TooltipMessage } from '@jsluna/labs'
import { onKeyPress } from '@jsluna/utilities'

export const Standard = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Tooltip open={isOpen} tooltipId="tooltip-1" position="right">
      <TooltipIcon
        onClick={e => {
          setIsOpen(!isOpen)
          e.preventDefault()
        }}
        onBlur={() => {
          setIsOpen(false)
        }}
        onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
          setIsOpen(false),
        )}
      />
      <TooltipMessage>Right</TooltipMessage>
    </Tooltip>
  )
}
`

Standard.parameters = {
  docs: {
    source: {
      code: StandardSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Custom = () => {
  const [position, setPosition] = React.useState('left')

  const options = [
    { label: 'Left', value: 'left' },
    { label: 'Left, Start', value: 'left-start' },
    { label: 'Left, End', value: 'left-end' },
    { label: 'Right', value: 'right' },
    { label: 'Right, Start', value: 'right-start' },
    { label: 'Right, End', value: 'right-end' },
    { label: 'Top', value: 'top' },
    { label: 'Top, Start', value: 'top-start' },
    { label: 'Top, End', value: 'top-end' },
    { label: 'Bottom', value: 'bottom' },
    { label: 'Bottom, Start', value: 'bottom-start' },
    { label: 'Bottom, End', value: 'bottom-end' },
  ]

  return (
    <>
      <div style={{ maxWidth: '20rem' }}>
        <SelectField
          name="select-field"
          value={position}
          onChange={e => setPosition(e.target.value)}
          label="Select position"
          options={options}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: 50,
        }}
      >
        <Tooltip open={true} tooltipId="tooltip-1" position={position}>
          <TooltipIcon />
          <TooltipMessage>{position}</TooltipMessage>
        </Tooltip>
      </div>
    </>
  )
}

const CustomSourceCode = `
import { Tooltip, TooltipIcon, TooltipMessage } from '@jsluna/labs'
import { SelectField } from '@jsluna/form'

export const Custom = () => {
  const [position, setPosition] = React.useState('left')

  const options = [
    { label: 'Left', value: 'left' },
    { label: 'Left, Start', value: 'left-start' },
    { label: 'Left, End', value: 'left-end' },
    { label: 'Right', value: 'right' },
    { label: 'Right, Start', value: 'right-start' },
    { label: 'Right, End', value: 'right-end' },
    { label: 'Top', value: 'top' },
    { label: 'Top, Start', value: 'top-start' },
    { label: 'Top, End', value: 'top-end' },
    { label: 'Bottom', value: 'bottom' },
    { label: 'Bottom, Start', value: 'bottom-start' },
    { label: 'Bottom, End', value: 'bottom-end' },
  ]

  return (
    <>
      <div style={{ maxWidth: '20rem' }}>
        <SelectField
          name="select-field"
          value={position}
          onChange={e => setPosition(e.target.value)}
          label="Select position"
          options={options}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: 50,
        }}
      >
        <Tooltip open={true} tooltipId="tooltip-1" position={position}>
          <TooltipIcon />
          <TooltipMessage>{position}</TooltipMessage>
        </Tooltip>
      </div>
    </>
  )
}
`

Custom.parameters = {
  docs: {
    source: {
      code: CustomSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithinForm = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Form>
      <FormGroup name="tooltip-form">
        {inputProps => (
          <React.Fragment>
            <div className="ln-u-display-flex@sm ln-u-align-items-center ln-u-margin-bottom*1/2">
              <Tooltip
                open={isOpen}
                tooltipId="tooltip-4"
                position="right"
                priority={['bottom', 'top', 'left', 'right']}
                popper={{
                  modifiers: {
                    flip: {
                      enabled: true,
                      behaviour: 'clockwise',
                    },
                  },
                }}
              >
                <Label
                  htmlFor={inputProps.name}
                  className="ln-u-flush-bottom ln-u-margin-right"
                >
                  Email address
                </Label>
                <TooltipIcon
                  onClick={e => {
                    setIsOpen(!isOpen)
                    e.preventDefault()
                  }}
                  onBlur={e => {
                    setIsOpen(false)
                  }}
                  onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
                    setIsOpen(false),
                  )}
                />

                <TooltipMessage>
                  We’ll only email you with information about your account
                </TooltipMessage>
              </Tooltip>
            </div>

            <TextInput
              {...inputProps}
              type="email"
              placeholder="Example: ben@email.com"
            />
          </React.Fragment>
        )}
      </FormGroup>
    </Form>
  )
}

const WithinFormSourceCode = `
import { Form, FormGroup, Label, TextInput } from '@jsluna/form'
import { Tooltip, TooltipIcon, TooltipMessage } from '@jsluna/labs'
import { onKeyPress } from '@jsluna/utilities'

export const WithinForm = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Form>
      <FormGroup name="tooltip-form">
        {inputProps => (
          <React.Fragment>
            <div className="ln-u-display-flex@sm ln-u-align-items-center ln-u-margin-bottom*1/2">
              <Tooltip
                open={isOpen}
                tooltipId="tooltip-4"
                position="right"
                priority={['bottom', 'top', 'left', 'right']}
                popper={{
                  modifiers: {
                    flip: {
                      enabled: true,
                      behaviour: 'clockwise',
                    },
                  },
                }}
              >
                <Label
                  htmlFor={inputProps.name}
                  className="ln-u-flush-bottom ln-u-margin-right"
                >
                  Email address
                </Label>
                <TooltipIcon
                  onClick={e => {
                    setIsOpen(!isOpen)
                    e.preventDefault()
                  }}
                  onBlur={e => {
                    setIsOpen(false)
                  }}
                  onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
                    setIsOpen(false),
                  )}
                />

                <TooltipMessage>
                  We’ll only email you with information about your account
                </TooltipMessage>
              </Tooltip>
            </div>

            <TextInput
              {...inputProps}
              type="email"
              placeholder="Example: ben@email.com"
            />
          </React.Fragment>
        )}
      </FormGroup>
    </Form>
  )
}
`

WithinForm.parameters = {
  docs: {
    source: {
      code: WithinFormSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const DoTooltipOne = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Tooltip open={isOpen} tooltipId="tooltip-1" position="right">
      <TooltipIcon
        onClick={e => {
          setIsOpen(!isOpen)
          e.preventDefault()
        }}
        onBlur={() => {
          setIsOpen(false)
        }}
        onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
          setIsOpen(false),
        )}
      />
      <TooltipMessage>
        Your account reference could be called your account number, customer
        reference or customer number.
      </TooltipMessage>
    </Tooltip>
  )
}

const DoTooltipOneSourceCode = `
import { Tooltip, TooltipIcon, TooltipMessage } from '@jsluna/labs'
import { onKeyPress } from '@jsluna/utilities'

export const DoTooltipOne = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Tooltip open={isOpen} tooltipId="tooltip-1" position="right">
      <TooltipIcon
        onClick={e => {
          setIsOpen(!isOpen)
          e.preventDefault()
        }}
        onBlur={() => {
          setIsOpen(false)
        }}
        onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
          setIsOpen(false),
        )}
      />
      <TooltipMessage>
        Your account reference could be called your account number, customer
        reference or customer number.
      </TooltipMessage>
    </Tooltip>
  )
}
`

DoTooltipOne.parameters = {
  docs: {
    source: {
      code: DoTooltipOneSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const DontTooltipOne = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Tooltip open={isOpen} tooltipId="tooltip-1" position="right">
      <TooltipIcon
        onClick={e => {
          setIsOpen(!isOpen)
          e.preventDefault()
        }}
        onBlur={() => {
          setIsOpen(false)
        }}
        onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
          setIsOpen(false),
        )}
      />
      <TooltipMessage>
        <p>What’s my customer reference</p>
        <p>
          Depending on what type of account you have with us, your account
          reference could be called one of the following:
        </p>
        <p>Account number, customer number, customer reference or similar.</p>
        <p>
          You’ll be able to find it on a letter that we’ve sent you, or in your
          welcome pack, or in the emails we sent when you signed up.
        </p>
      </TooltipMessage>
    </Tooltip>
  )
}

const DontTooltipOneSourceCode = `
import { Tooltip, TooltipIcon, TooltipMessage } from '@jsluna/labs'
import { onKeyPress } from '@jsluna/utilities'

export const DontTooltipOne = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Tooltip open={isOpen} tooltipId="tooltip-1" position="right">
      <TooltipIcon
        onClick={e => {
          setIsOpen(!isOpen)
          e.preventDefault()
        }}
        onBlur={() => {
          setIsOpen(false)
        }}
        onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
          setIsOpen(false),
        )}
      />
      <TooltipMessage>
        <p>What’s my customer reference</p>
        <p>
          Depending on what type of account you have with us, your account
          reference could be called one of the following:
        </p>
        <p>Account number, customer number, customer reference or similar.</p>
        <p>
          You’ll be able to find it on a letter that we’ve sent you, or in your
          welcome pack, or in the emails we sent when you signed up.
        </p>
      </TooltipMessage>
    </Tooltip>
  )
}
`

DontTooltipOne.parameters = {
  docs: {
    source: {
      code: DontTooltipOneSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const DontTooltipTwo = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Form>
      <FormGroup name="tooltip-form">
        {inputProps => (
          <React.Fragment>
            <div className="ln-u-display-flex@sm ln-u-align-items-center ln-u-margin-bottom*1/2">
              <Tooltip
                open={isOpen}
                tooltipId="tooltip-4"
                position="right"
                priority={['bottom', 'top', 'left', 'right']}
                popper={{
                  modifiers: {
                    flip: {
                      enabled: true,
                      behaviour: 'clockwise',
                    },
                  },
                }}
              >
                <Label
                  htmlFor={inputProps.name}
                  className="ln-u-flush-bottom ln-u-margin-right"
                >
                  Delivery address
                </Label>
                <TooltipIcon
                  onClick={e => {
                    setIsOpen(!isOpen)
                    e.preventDefault()
                  }}
                  onBlur={e => {
                    setIsOpen(false)
                  }}
                  onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
                    setIsOpen(false),
                  )}
                />

                <TooltipMessage>
                  Your email address will be used to send you important
                  information about your account
                </TooltipMessage>
              </Tooltip>
            </div>

            <TextInput {...inputProps} type="email" />
          </React.Fragment>
        )}
      </FormGroup>
    </Form>
  )
}

const DontTooltipTwoSourceCode = `
import { Form, FormGroup, Label, TextInput } from '@jsluna/forms'
import { Tooltip, TooltipIcon, TooltipMessage } from '@jsluna/labs'
import { onKeyPress } from '@jsluna/utilities'

export const DontTooltipTwo = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Form>
      <FormGroup name="tooltip-form">
        {inputProps => (
          <React.Fragment>
            <div className="ln-u-display-flex@sm ln-u-align-items-center ln-u-margin-bottom*1/2">
              <Tooltip
                open={isOpen}
                tooltipId="tooltip-4"
                position="right"
                priority={['bottom', 'top', 'left', 'right']}
                popper={{
                  modifiers: {
                    flip: {
                      enabled: true,
                      behaviour: 'clockwise',
                    },
                  },
                }}
              >
                <Label
                  htmlFor={inputProps.name}
                  className="ln-u-flush-bottom ln-u-margin-right"
                >
                  Delivery address
                </Label>
                <TooltipIcon
                  onClick={e => {
                    setIsOpen(!isOpen)
                    e.preventDefault()
                  }}
                  onBlur={e => {
                    setIsOpen(false)
                  }}
                  onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
                    setIsOpen(false),
                  )}
                />

                <TooltipMessage>
                  Your email address will be used to send you important
                  information about your account
                </TooltipMessage>
              </Tooltip>
            </div>

            <TextInput {...inputProps} type="email" />
          </React.Fragment>
        )}
      </FormGroup>
    </Form>
  )
}
`

DontTooltipTwo.parameters = {
  docs: {
    source: {
      code: DontTooltipTwoSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
