import React from 'react'
import { TableHeader, TableHeaderRow, TableCell } from '@jsluna/table'

const TypographyTableHeader = () => (
  <TableHeaderRow>
    <TableCell>Name</TableCell>
    <TableCell>Style</TableCell>
    <TableCell>Font size</TableCell>
    <TableCell>Line height</TableCell>
    <TableCell>Responsive alternative for screens larger than 720px</TableCell>
  </TableHeaderRow>
)

export default TypographyTableHeader
