import GlobalTokens from '@sainsburys-tech/tokens/dist/global/js/json/color/index.json';
import ColourBlock from '@components/colour-block';
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableRow, TableCell } from '@jsluna/table';
import { Tick, Cancel } from '@jsluna/icons';
import { PrimaryColours, SecondaryColours, PromotionalColours, BaseColours, ExtendedColours, MonochromeColours, SemanticColours, ColourLookup } from '@components/design-tokens';
import * as React from 'react';
export default {
  GlobalTokens,
  ColourBlock,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  Tick,
  Cancel,
  PrimaryColours,
  SecondaryColours,
  PromotionalColours,
  BaseColours,
  ExtendedColours,
  MonochromeColours,
  SemanticColours,
  ColourLookup,
  React
};