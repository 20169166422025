import React from 'react'

import { CheckboxField, Checkbox } from '@jsluna/form'

export default {
  title: 'Checkbox',
  component: CheckboxField,
}

export const Stacked = () => (
  <CheckboxField
    label="Do you have any dietary requirements?"
    name="checkbox-field-1"
    options={[
      { value: 'lactose', label: 'Lactose intolerant' },
      { value: 'gluten', label: 'Gluten-free', defaultChecked: true },
      { value: 'vegan', label: 'Vegan' },
      { value: 'vegetarian', label: 'Vegetarian' },
    ]}
  />
)

const StackedSourceCode = `
import { CheckboxField } from '@jsluna/form'

export const Stacked = () => (
  <CheckboxField
    label="Do you have any dietary requirements?"
    name="checkbox-field-1"
    options={[
      { value: 'lactose', label: 'Lactose intolerant' },
      { value: 'gluten', label: 'Gluten-free', defaultChecked: true },
      { value: 'vegan', label: 'Vegan' },
      { value: 'vegetarian', label: 'Vegetarian' },
    ]}
  />
)
`

Stacked.parameters = {
  docs: {
    source: {
      code: StackedSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Inline = () => (
  <CheckboxField
    label="How would you like to be contacted?"
    name="checkbox-field-2"
    listType="inline"
    options={[
      { value: 'email', label: 'Email', defaultChecked: true },
      { value: 'phone', label: 'Phone' },
      { value: 'post', label: 'Post' },
    ]}
  />
)

const InlineSourceCode = `
import { CheckboxField } from '@jsluna/form'

export const Inline = () => (
  <CheckboxField
    label="How would you like to be contacted?"
    name="checkbox-field-2"
    listType="inline"
    options={[
      { value: 'email', label: 'Email', defaultChecked: true },
      { value: 'phone', label: 'Phone' },
      { value: 'post', label: 'Post' },
    ]}
  />
)
`

Inline.parameters = {
  docs: {
    source: {
      code: InlineSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Outlined = () => (
  <div style={{ maxWidth: '20rem' }}>
    <CheckboxField
      label="Do you have any dietary requirements?"
      name="checkbox-field-3"
      options={[
        { value: 'lactose', label: 'Lactose intolerant' },
        { value: 'gluten', label: 'Gluten-free', defaultChecked: true },
        { value: 'vegan', label: 'Vegan' },
        { value: 'vegetarian', label: 'Vegetarian' },
      ]}
      outlined
      fullWidth
    />
  </div>
)

const OutlinedSourceCode = `
import { CheckboxField } from '@jsluna/form'

export const Outlined = () => (
  <div style={{ maxWidth: '20rem' }}>
    <CheckboxField
      label="Do you have any dietary requirements?"
      name="checkbox-field-3"
      options={[
        { value: 'lactose', label: 'Lactose intolerant' },
        { value: 'gluten', label: 'Gluten-free', defaultChecked: true },
        { value: 'vegan', label: 'Vegan' },
        { value: 'vegetarian', label: 'Vegetarian' },
      ]}
      outlined
      fullWidth
    />
  </div>
)
`

Outlined.parameters = {
  docs: {
    source: {
      code: OutlinedSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const OutlinedInline = () => (
  <CheckboxField
    label="How would you like to be contacted?"
    name="checkbox-field-4"
    listType="inline"
    options={[
      { value: 'email', label: 'Email', defaultChecked: true },
      { value: 'phone', label: 'Phone' },
      { value: 'post', label: 'Post' },
    ]}
    outlined
  />
)

const OutlinedInlineSourceCode = `
import { CheckboxField } from '@jsluna/form'

export const OutlinedInline = () => (
  <CheckboxField
    label="How would you like to be contacted?"
    name="checkbox-field-4"
    listType="inline"
    options={[
      { value: 'email', label: 'Email', defaultChecked: true },
      { value: 'phone', label: 'Phone' },
      { value: 'post', label: 'Post' },
    ]}
    outlined
  />
)
`

OutlinedInline.parameters = {
  docs: {
    source: {
      code: OutlinedInlineSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithErrorMessage = () => (
  <CheckboxField
    label="What products would you like to receive news and offers on?"
    name="checkbox-field-5"
    error="Please select two or more options to create your personalised newsletter"
    fullWidth
    options={[
      { value: 'credit', label: 'Credit cards' },
      { value: 'insurance', label: 'Insurance', defaultChecked: true },
      { value: 'loans', label: 'Loans' },
      { value: 'mortgages', label: 'Mortgages' },
      { value: 'travel', label: 'Travel' },
    ]}
  />
)

const WithErrorMessageSourceCode = `
import { CheckboxField } from '@jsluna/form'

export const WithErrorMessage = () => (
  <CheckboxField
    label="What products would you like to receive news and offers on?"
    name="checkbox-field-5"
    error="Please select two or more options to create your personalised newsletter"
    fullWidth
    options={[
      { value: 'credit', label: 'Credit cards' },
      { value: 'insurance', label: 'Insurance', defaultChecked: true },
      { value: 'loans', label: 'Loans' },
      { value: 'mortgages', label: 'Mortgages' },
      { value: 'travel', label: 'Travel' },
    ]}
  />
)
`

WithErrorMessage.parameters = {
  docs: {
    source: {
      code: WithErrorMessageSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Standalone = () => (
  <React.Fragment>
    <Checkbox
      name="checkbox-with-hidden-label"
      label="Hidden label"
      hideLabel
    />
    <Checkbox
      name="checkbox-with-hidden-label-checked"
      label="Hidden label (default checked)"
      defaultChecked
      hideLabel
    />
  </React.Fragment>
)

const StandaloneSourceCode = `
import { Checkbox } from '@jsluna/form'

export const Standalone = () => (
  <React.Fragment>
    <Checkbox
      name="checkbox-with-hidden-label"
      label="Hidden label"
      hideLabel
    />
    <Checkbox
      name="checkbox-with-hidden-label-checked"
      label="Hidden label (default checked)"
      defaultChecked
      hideLabel
    />
  </React.Fragment>
)
`

Standalone.parameters = {
  docs: {
    source: {
      code: StandaloneSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
