export const formatTableText = str => {
  return str.charAt(0).toUpperCase().concat(str.slice(1)).replace('-', ' ')
}

export const responsiveScreenSizes = fontStyle => {
  const repsonsiveStyles = [
    { style: 'display-6', alt: 'display-7' },
    { style: 'display-5', alt: 'display-6' },
    { style: 'display-4', alt: 'display-5' },
    { style: 'display-3', alt: 'display-4' },
    { style: 'display-2', alt: 'display-3' },
    { style: 'display-1', alt: 'display-2' },
  ]
  let repsonsiveStyle = null

  repsonsiveStyles.forEach(display => {
    if (fontStyle === display.style) {
      repsonsiveStyle = display.alt
    }
  })

  repsonsiveStyle !== null
    ? (repsonsiveStyle = formatTableText(repsonsiveStyle))
    : (repsonsiveStyle = null)

  return repsonsiveStyle
}

export const formatFontName = (fontName, brand, display) => {
  const formatName = fontName
    .split(',')[0]
    .replace(/'/g, '')
    .replace(/([A-Z])/g, ' $1')
    .trim()

  if (formatName === 'Mary Ann') {
    return formatName.concat(' Extra Bold')
  }

  if (
    formatName === 'Arial' &&
    brand === "Sainsbury's" &&
    display !== 'label-2'
  ) {
    return formatName.concat(' Regular')
  }

  if (
    formatName === 'Arial' &&
    brand === "Sainsbury's" &&
    display === 'label-2'
  ) {
    return formatName.concat(' Bold')
  }

  return formatName
}
