import React from 'react'
import { BreadcrumbsWrapper, BreadcrumbsItem } from '@jsluna/breadcrumbs'

export default {
  title: 'Breadcrumbs',
  component: BreadcrumbsWrapper,
}

export const Narrow = () => (
  <BreadcrumbsWrapper className="ln-u-flush-bottom" backToParent>
    <BreadcrumbsItem>
      <a href="#grandparent">[grandparent page]</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#parent">[previous page]</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem active>
      <a href="#child">[current page]</a>
    </BreadcrumbsItem>
  </BreadcrumbsWrapper>
)

const NarrowSourceCode = `
import { BreadcrumbsWrapper, BreadcrumbsItem } from '@jsluna/breadcrumbs'

export const Narrow = () => (
  <BreadcrumbsWrapper className="ln-u-flush-bottom" backToParent>
    <BreadcrumbsItem>
      <a href="#grandparent">[grandparent page]</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#parent">[previous page]</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem active>
      <a href="#child">[current page]</a>
    </BreadcrumbsItem>
  </BreadcrumbsWrapper>
)
`

Narrow.parameters = {
  docs: {
    source: {
      code: NarrowSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Standard = () => (
  <BreadcrumbsWrapper className="ln-u-flush-bottom">
    <BreadcrumbsItem>
      <a href="#home">Home</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#page-one">Page one</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#page-two">Page two</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem active>Page three</BreadcrumbsItem>
  </BreadcrumbsWrapper>
)

const StandardSourceCode = `
import { BreadcrumbsWrapper, BreadcrumbsItem } from '@jsluna/breadcrumbs'

export const Standard = () => (
  <BreadcrumbsWrapper className="ln-u-flush-bottom">
    <BreadcrumbsItem>
      <a href="#home">Home</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#page-one">Page one</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#page-two">Page two</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem active>Page three</BreadcrumbsItem>
  </BreadcrumbsWrapper>
)
`

Standard.parameters = {
  docs: {
    source: {
      code: StandardSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Light = () => (
  <BreadcrumbsWrapper className="ln-u-flush-bottom">
    <BreadcrumbsItem>
      <a href="#home">Home</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#page-one">Page one</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#page-two">Page two</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem active>Page three</BreadcrumbsItem>
  </BreadcrumbsWrapper>
)

const LightSourceCode = `
import { BreadcrumbsWrapper, BreadcrumbsItem } from '@jsluna/breadcrumbs'

export const Light = () => (
  <BreadcrumbsWrapper className="ln-u-flush-bottom">
    <BreadcrumbsItem>
      <a href="#home">Home</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#page-one">Page one</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#page-two">Page two</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem active>Page three</BreadcrumbsItem>
  </BreadcrumbsWrapper>
)
`

Light.parameters = {
  backgrounds: { default: 'grey-light' },
  docs: {
    source: {
      code: LightSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Dark = () => (
  <BreadcrumbsWrapper className="ln-u-flush-bottom" color="light">
    <BreadcrumbsItem>
      <a href="#home">Home</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#page-one">Page one</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#page-two">Page two</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem active>Page three</BreadcrumbsItem>
  </BreadcrumbsWrapper>
)

const DarkSourceCode = `
import { BreadcrumbsWrapper, BreadcrumbsItem } from '@jsluna/breadcrumbs'

export const Dark = () => (
  <BreadcrumbsWrapper className="ln-u-flush-bottom" color="light">
    <BreadcrumbsItem>
      <a href="#home">Home</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#page-one">Page one</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem>
      <a href="#page-two">Page two</a>
    </BreadcrumbsItem>
    <BreadcrumbsItem active>Page three</BreadcrumbsItem>
  </BreadcrumbsWrapper>
)
`

Dark.parameters = {
  backgrounds: { default: 'dark' },
  docs: {
    source: {
      code: DarkSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
