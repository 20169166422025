import React from 'react'
import { ToggleButton, ToggleButtonGroup } from '@jsluna/toggle-button'
import { GridView, ListView, Like, Dislike } from '@jsluna/icons'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Card } from '@jsluna/card'

export default {
  title: 'Toggle Button',
  component: ToggleButton,
}

export const Text = () => {
  const buttons = [
    { label: 'Weekly', value: 'week' },
    { label: 'Monthly', value: 'month' },
    { label: 'Yearly', value: 'year' },
  ]
  const [buttonGroupValue, setButtonGroupValue] = React.useState('month')

  return (
    <ToggleButtonGroup
      label="How frequently would you like to receive promotional emails?"
      value={buttonGroupValue}
      onChange={(event, value) => setButtonGroupValue(value)}
    >
      {buttons.map(button => (
        <ToggleButton key={button.value} value={button.value}>
          {button.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

const TextSourceCode = `
import { ToggleButton, ToggleButtonGroup } from '@jsluna/toggle-button'

export const Text = () => {
  const buttons = [
    { label: 'Weekly', value: 'week' },
    { label: 'Monthly', value: 'month' },
    { label: 'Yearly', value: 'year' },
  ]
  const [buttonGroupValue, setButtonGroupValue] = React.useState('month')

  return (
    <ToggleButtonGroup
      label="How frequently would you like to receive promotional emails?"
      value={buttonGroupValue}
      onChange={(event, value) => setButtonGroupValue(value)}
    >
      {buttons.map(button => (
        <ToggleButton key={button.value} value={button.value}>
          {button.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
`

Text.parameters = {
  docs: {
    source: {
      code: TextSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Icons = () => {
  const items = [
    'Fruit & vegetables',
    'Meat & fish',
    'Dairy, eggs & juice',
    'Bakery',
  ]
  const buttons = [
    { label: 'Grid', value: 'grid', icon: GridView },
    { label: 'List', value: 'list', icon: ListView },
  ]

  const [buttonGroupValue, setButtonGroupValue] = React.useState('list')

  return (
    <div>
      <ToggleButtonGroup
        inline
        label="Results view"
        value={buttonGroupValue}
        onChange={(event, value) => setButtonGroupValue(value)}
      >
        {buttons.map(({ label, value, icon: Icon }) => (
          <ToggleButton key={value} value={value}>
            <Icon className="ln-c-icon--large" />
            <span className="ln-u-visually-hidden">{label}</span>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <GridWrapper matrix equalHeight gutterSize="sm" className="ln-u-push-top">
        {items.map(item => (
          <GridItem key={item} size={buttonGroupValue === 'grid' ? '1/2' : '1'}>
            <Card className="ln-u-display-2">{item}</Card>
          </GridItem>
        ))}
      </GridWrapper>
    </div>
  )
}

const IconsSourceCode = `
import { ToggleButton, ToggleButtonGroup } from '@jsluna/toggle-button'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Card } from '@jsluna/card'
import { GridView, ListView } from '@jsluna/icons'


export const Icons = () => {
  const items = [
    'Fruit & vegetables',
    'Meat & fish',
    'Dairy, eggs & juice',
    'Bakery',
  ]
  const buttons = [
    { label: 'Grid', value: 'grid', icon: GridView },
    { label: 'List', value: 'list', icon: ListView },
  ]

  const [buttonGroupValue, setButtonGroupValue] = React.useState('list')

  return (
    <div>
      <ToggleButtonGroup
        inline
        label="Results view"
        value={buttonGroupValue}
        onChange={(event, value) => setButtonGroupValue(value)}
      >
        {buttons.map(({ label, value, icon: Icon }) => (
          <ToggleButton key={value} value={value}>
            <Icon className="ln-c-icon--large" />
            <span className="ln-u-visually-hidden">{label}</span>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <GridWrapper matrix equalHeight gutterSize="sm" className="ln-u-push-top">
        {items.map(item => (
          <GridItem key={item} size={buttonGroupValue === 'grid' ? '1/2' : '1'}>
            <Card className="ln-u-display-2">{item}</Card>
          </GridItem>
        ))}
      </GridWrapper>
    </div>
  )
}
`

Icons.parameters = {
  docs: {
    source: {
      code: IconsSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
  backgrounds: { default: 'grey-light' },
}

export const TextAndIcons = () => {
  const buttons = [
    { label: 'Like', value: 'like', icon: Like },
    { label: 'Dislike', value: 'dislike', icon: Dislike },
  ]

  const [buttonGroupValue, setButtonGroupValue] = React.useState()

  return (
    <ToggleButtonGroup
      inline
      alpha
      label="Like / dislike"
      value={buttonGroupValue}
      onChange={(event, value) =>
        setButtonGroupValue(buttonGroupValue !== value ? value : undefined)
      }
    >
      {buttons.map(({ label, value, icon: Icon }) => (
        <ToggleButton key={value} value={value}>
          <Icon className="ln-u-margin-right" />
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

const TextAndIconsSourceCode = `
import { ToggleButton, ToggleButtonGroup } from '@jsluna/toggle-button'
import { Like, Dislike } from '@jsluna/icons'

export const TextAndIcons = () => {
  const buttons = [
    { label: 'Like', value: 'like', icon: Like },
    { label: 'Dislike', value: 'dislike', icon: Dislike },
  ]

  const [buttonGroupValue, setButtonGroupValue] = React.useState()

  return (
    <ToggleButtonGroup
      inline
      alpha
      label="Like / dislike"
      value={buttonGroupValue}
      onChange={(event, value) =>
        setButtonGroupValue(buttonGroupValue !== value ? value : undefined)
      }
    >
      {buttons.map(({ label, value, icon: Icon }) => (
        <ToggleButton key={value} value={value}>
          <Icon className="ln-u-margin-right" />
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
`

TextAndIcons.parameters = {
  docs: {
    source: {
      code: TextAndIconsSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
