import React from 'react'
import { Accordion, AccordionItem } from '@jsluna/accordion'

const AccordionStory = {
  title: 'Accordion',
  name: 'Accordion',
  component: Accordion,
}

export const Grouped = args => (
  <Accordion {...args}>
    <AccordionItem
      id="accordion-item-1"
      title="First item"
      data-testid="first-item"
    >
      <p data-testid="first-item-body">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis, aut?
      </p>
    </AccordionItem>
    <AccordionItem
      defaultOpen
      id="accordion-item-2"
      title="Second item"
      data-testid="second-item"
    >
      <p data-testid="second-item-body">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis, aut?
      </p>
    </AccordionItem>
    <AccordionItem
      defaultOpen
      id="accordion-item-3"
      title="Third item"
      data-testid="third-item"
    >
      <p data-testid="third-item-body">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis, aut?
      </p>
    </AccordionItem>
  </Accordion>
)

const ExampleSourceCode = (story, props) => `
import { Accordion, AccordionItem } from '@jsluna/accordion'

export const ${story} = () => (
  <Accordion titleElement="h3"${props ? ` ${props}` : ''}>
    <AccordionItem
      id="accordion-item-1"
      title="First item"
      data-testid="first-item"
    >
      <p data-testid="first-item-body">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis, aut?
      </p>
    </AccordionItem>
    <AccordionItem
      defaultOpen
      id="accordion-item-2"
      title="Second item"
      data-testid="second-item"
    >
      <p data-testid="second-item-body">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis, aut?
      </p>
    </AccordionItem>
    <AccordionItem
      defaultOpen
      id="accordion-item-3"
      title="Third item"
      data-testid="third-item"
    >
      <p data-testid="third-item-body">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis, aut?
      </p>
    </AccordionItem>
  </Accordion>
)`

Grouped.args = {
  titleElement: 'h3',
}

Grouped.parameters = {
  docs: {
    source: {
      code: ExampleSourceCode('Grouped'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Grouped__MultipleOpen = args => <Grouped {...args}></Grouped>

Grouped__MultipleOpen.args = {
  multipleOpen: true,
}

Grouped__MultipleOpen.parameters = {
  docs: {
    source: {
      code: ExampleSourceCode(
        'MultiOpen',
        Object.keys(Grouped__MultipleOpen.args).join(' '),
      ),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Standalone = args => <Grouped {...args}></Grouped>

Standalone.args = {
  standalone: true,
  multipleOpen: true,
}

Standalone.parameters = {
  docs: {
    source: {
      code: ExampleSourceCode(
        'Standalone',
        Object.keys(Standalone.args).join(' '),
      ),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Responsive = args => <Grouped {...args}></Grouped>

Responsive.args = {
  respondAt: 'md',
}

Responsive.parameters = {
  docs: {
    source: {
      code: ExampleSourceCode('Responsive', 'respondAt="md"'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export default AccordionStory
