import React from 'react'
import { Table } from '@jsluna/table'
import data from './mocks/table.json'

export default {
  title: 'Tables',
  component: Table,
}

export const Standard = () => (
  <Table
    rowKey="orderedLine.sku"
    data={data}
    caption="Captions that have no significance to sighted users, can be toggled off and still be readable by screen readers."
    visuallyHiddenCaption
    columns={[
      {
        name: 'Description',
        accessor: rowData => ({ value: rowData.orderedLine.description }),
        hideLabel: true,
        className: 'customCol',
      },
      {
        name: 'Quantity',
        accessor: rowData => ({
          value: rowData.orderedLine.orderedQuantity,
          uom: rowData.orderedLine.unitOfMeasure.toLowerCase(),
        }),
        sort: (accessor, ascending) => (a, b) => {
          const valueA = parseInt(accessor(a).value, 10)
          const valueB = parseInt(accessor(b).value, 10)

          if (valueA < valueB) {
            return ascending ? -1 : 1
          }
          if (valueA > valueB) {
            return ascending ? 1 : -1
          }
          return 0
        },
        className: {
          th: 'customHead',
        },
        render: ({ value, uom }) => (
          <span>
            {value}
            {uom !== 'each' && uom}
          </span>
        ),
      },
    ]}
  />
)

const StandardSourceCode = `
import { Table } from '@jsluna/table'

export const Standard = () => (
  <Table
    rowKey="orderedLine.sku"
    data={data}
    caption="Captions that have no significance to sighted users, can be toggled off and still be readable by screen readers."
    visuallyHiddenCaption
    columns={[
      {
        name: 'Description',
        accessor: rowData => ({ value: rowData.orderedLine.description }),
        hideLabel: true,
        className: 'customCol',
      },
      {
        name: 'Quantity',
        accessor: rowData => ({
          value: rowData.orderedLine.orderedQuantity,
          uom: rowData.orderedLine.unitOfMeasure.toLowerCase(),
        }),
        sort: (accessor, ascending) => (a, b) => {
          const valueA = parseInt(accessor(a).value, 10)
          const valueB = parseInt(accessor(b).value, 10)

          if (valueA < valueB) {
            return ascending ? -1 : 1
          }
          if (valueA > valueB) {
            return ascending ? 1 : -1
          }
          return 0
        },
        className: {
          th: 'customHead',
        },
        render: ({ value, uom }) => (
          <span>
            {value}
            {uom !== 'each' && uom}
          </span>
        ),
      },
    ]}
  />
)
`

Standard.parameters = {
  docs: {
    source: {
      code: StandardSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Sortable = () => (
  <Table
    rowKey="orderedLine.sku"
    sortable
    data={data}
    caption="Use this variation when a comparison is not a priority and you want to give users the option to order content."
    visuallyHiddenCaption
    columns={[
      {
        name: 'Description',
        accessor: rowData => ({ value: rowData.orderedLine.description }),
        hideLabel: true,
        className: 'customCol',
      },
      {
        name: 'Quantity',
        accessor: rowData => ({
          value: rowData.orderedLine.orderedQuantity,
          uom: rowData.orderedLine.unitOfMeasure.toLowerCase(),
        }),
        sort: (accessor, ascending) => (a, b) => {
          const valueA = parseInt(accessor(a).value, 10)
          const valueB = parseInt(accessor(b).value, 10)

          if (valueA < valueB) {
            return ascending ? -1 : 1
          }
          if (valueA > valueB) {
            return ascending ? 1 : -1
          }
          return 0
        },
        className: {
          th: 'customHead',
        },
        render: ({ value, uom }) => (
          <span>
            {value}
            {uom !== 'each' && uom}
          </span>
        ),
      },
    ]}
  />
)

const SortableSourceCode = `
import { Table } from '@jsluna/table'

export const Sortable = () => (
  <Table
    rowKey="orderedLine.sku"
    sortable
    data={data}
    caption="Use this variation when a comparison is not a priority and you want to give users the option to order content."
    visuallyHiddenCaption
    columns={[
      {
        name: 'Description',
        accessor: rowData => ({ value: rowData.orderedLine.description }),
        hideLabel: true,
        className: 'customCol',
      },
      {
        name: 'Quantity',
        accessor: rowData => ({
          value: rowData.orderedLine.orderedQuantity,
          uom: rowData.orderedLine.unitOfMeasure.toLowerCase(),
        }),
        sort: (accessor, ascending) => (a, b) => {
          const valueA = parseInt(accessor(a).value, 10)
          const valueB = parseInt(accessor(b).value, 10)

          if (valueA < valueB) {
            return ascending ? -1 : 1
          }
          if (valueA > valueB) {
            return ascending ? 1 : -1
          }
          return 0
        },
        className: {
          th: 'customHead',
        },
        render: ({ value, uom }) => (
          <span>
            {value}
            {uom !== 'each' && uom}
          </span>
        ),
      },
    ]}
  />
)
`

Sortable.parameters = {
  docs: {
    source: {
      code: SortableSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Responsive = () => (
  <Table
    rowKey="orderedLine.sku"
    responsive
    data={data}
    columns={[
      {
        name: 'Description',
        accessor: rowData => ({ value: rowData.orderedLine.description }),
        hideLabel: true,
        className: 'customCol',
      },
      {
        name: 'Quantity',
        accessor: rowData => ({
          value: rowData.orderedLine.orderedQuantity,
          uom: rowData.orderedLine.unitOfMeasure.toLowerCase(),
        }),
        sort: (accessor, ascending) => (a, b) => {
          const valueA = parseInt(accessor(a).value, 10)
          const valueB = parseInt(accessor(b).value, 10)

          if (valueA < valueB) {
            return ascending ? -1 : 1
          }
          if (valueA > valueB) {
            return ascending ? 1 : -1
          }
          return 0
        },
        className: {
          th: 'customHead',
        },
        render: ({ value, uom }) => (
          <span>
            {value}
            {uom !== 'each' && uom}
          </span>
        ),
      },
    ]}
  />
)

const ResponsiveSourceCode = `

export const Responsive = () => (
  <Table
    rowKey="orderedLine.sku"
    responsive
    data={data}
    columns={[
      {
        name: 'Description',
        accessor: rowData => ({ value: rowData.orderedLine.description }),
        hideLabel: true,
        className: 'customCol',
      },
      {
        name: 'Quantity',
        accessor: rowData => ({
          value: rowData.orderedLine.orderedQuantity,
          uom: rowData.orderedLine.unitOfMeasure.toLowerCase(),
        }),
        sort: (accessor, ascending) => (a, b) => {
          const valueA = parseInt(accessor(a).value, 10)
          const valueB = parseInt(accessor(b).value, 10)

          if (valueA < valueB) {
            return ascending ? -1 : 1
          }
          if (valueA > valueB) {
            return ascending ? 1 : -1
          }
          return 0
        },
        className: {
          th: 'customHead',
        },
        render: ({ value, uom }) => (
          <span>
            {value}
            {uom !== 'each' && uom}
          </span>
        ),
      },
    ]}
  />
)
`

Responsive.parameters = {
  docs: {
    source: {
      code: ResponsiveSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const ResponsiveAndSortable = () => (
  <Table
    rowKey="orderedLine.sku"
    responsive
    sortable
    data={data}
    caption="This responsive view can also be applied to sortable content."
    visuallyHiddenCaption
    columns={[
      {
        name: 'Description',
        accessor: rowData => ({ value: rowData.orderedLine.description }),
        hideLabel: true,
        className: 'customCol',
      },
      {
        name: 'Quantity',
        accessor: rowData => ({
          value: rowData.orderedLine.orderedQuantity,
          uom: rowData.orderedLine.unitOfMeasure.toLowerCase(),
        }),
        sort: (accessor, ascending) => (a, b) => {
          const valueA = parseInt(accessor(a).value, 10)
          const valueB = parseInt(accessor(b).value, 10)

          if (valueA < valueB) {
            return ascending ? -1 : 1
          }
          if (valueA > valueB) {
            return ascending ? 1 : -1
          }
          return 0
        },
        className: {
          th: 'customHead',
        },
        render: ({ value, uom }) => (
          <span>
            {value}
            {uom !== 'each' && uom}
          </span>
        ),
      },
    ]}
  />
)

const ResponsiveAndSortableSourceCode = `
import { Table } from '@jsluna/table'

export const ResponsiveAndSortable = () => (
  <Table
    rowKey="orderedLine.sku"
    responsive
    sortable
    data={data}
    caption="This responsive view can also be applied to sortable content."
    visuallyHiddenCaption
    columns={[
      {
        name: 'Description',
        accessor: rowData => ({ value: rowData.orderedLine.description }),
        hideLabel: true,
        className: 'customCol',
      },
      {
        name: 'Quantity',
        accessor: rowData => ({
          value: rowData.orderedLine.orderedQuantity,
          uom: rowData.orderedLine.unitOfMeasure.toLowerCase(),
        }),
        sort: (accessor, ascending) => (a, b) => {
          const valueA = parseInt(accessor(a).value, 10)
          const valueB = parseInt(accessor(b).value, 10)

          if (valueA < valueB) {
            return ascending ? -1 : 1
          }
          if (valueA > valueB) {
            return ascending ? 1 : -1
          }
          return 0
        },
        className: {
          th: 'customHead',
        },
        render: ({ value, uom }) => (
          <span>
            {value}
            {uom !== 'each' && uom}
          </span>
        ),
      },
    ]}
  />
)
`

ResponsiveAndSortable.parameters = {
  docs: {
    source: {
      code: ResponsiveAndSortableSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
