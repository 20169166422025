import React from 'react'

import { Modal, ModalHeading } from '@jsluna/modal'
import {
  FilledButton,
  OutlinedButton,
  TextButton,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
} from '@jsluna/button'
import { List, ListItem } from '@jsluna/list'

export default {
  title: 'Modal',
  component: Modal,
}

export const Standard = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div
      style={{
        position: 'relative',
        minHeight: '20rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999,
      }}
    >
      <FilledButton onClick={() => setIsOpen(true)}>
        Open information modal
      </FilledButton>
      <Modal
        small
        handleClose={() => setIsOpen(false)}
        open={isOpen}
        headingId="info-modal"
      >
        <ModalHeading element="h3">
          Some information you may be interested in
        </ModalHeading>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
      </Modal>
    </div>
  )
}

const StandardSourceCode = `
import { Modal, ModalHeading } from '@jsluna/modal'
import { FilledButton } from '@jsluna/button'

export const Standard = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div
      style={{
        position: 'relative',
        minHeight: '20rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999,
      }}
    >
      <FilledButton onClick={() => setIsOpen(true)}>
        Open information modal
      </FilledButton>
      <Modal
        small
        handleClose={() => setIsOpen(false)}
        open={isOpen}
        headingId="info-modal"
      >
        <ModalHeading element="h3">
          Some information you may be interested in
        </ModalHeading>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
      </Modal>
    </div>
  )
}
`

Standard.parameters = {
  docs: {
    source: {
      code: StandardSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Dialog = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div
      style={{
        position: 'relative',
        minHeight: '20rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999,
      }}
    >
      <FilledButton onClick={() => setIsOpen(true)}>
        Open dialog modal
      </FilledButton>
      <Modal
        fullScreen
        restrictClose
        alert
        handleClose={() => setIsOpen(false)}
        open={isOpen}
        headingId="dialog-modal"
      >
        <ModalHeading element="h3">An action is required</ModalHeading>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis,
          aut?
        </p>
        <List>
          <ListItem>Lorem ipsum dolor sit amet</ListItem>
          <ListItem>Lorem ipsum dolor sit amet</ListItem>
          <ListItem>Lorem ipsum dolor sit amet</ListItem>
        </List>
        <p>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est
          laborum.
        </p>
        <p>
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat.
        </p>
        <ButtonGroupWrapper actionbar>
          <ButtonGroupSecondary>
            <TextButton onClick={() => setIsOpen(false)}>Cancel</TextButton>
          </ButtonGroupSecondary>
          <ButtonGroupPrimary>
            <OutlinedButton
              className="ln-u-margin-right"
              onClick={() => setIsOpen(false)}
            >
              Decline
            </OutlinedButton>
            <FilledButton onClick={() => setIsOpen(false)}>Accept</FilledButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </div>
  )
}

const DialogSourceCode = `
import { Modal, ModalHeading } from '@jsluna/modal'
import {
  FilledButton,
  OutlinedButton,
  TextButton,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
} from '@jsluna/button'
import { List, ListItem } from '@jsluna/list'

export const Dialog = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div
      style={{
        position: 'relative',
        minHeight: '20rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999,
      }}
    >
      <FilledButton onClick={() => setIsOpen(true)}>
        Open dialog modal
      </FilledButton>
      <Modal
        fullScreen
        restrictClose
        alert
        handleClose={() => setIsOpen(false)}
        open={isOpen}
        headingId="dialog-modal"
      >
        <ModalHeading element="h3">An action is required</ModalHeading>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis,
          aut?
        </p>
        <List>
          <ListItem>Lorem ipsum dolor sit amet</ListItem>
          <ListItem>Lorem ipsum dolor sit amet</ListItem>
          <ListItem>Lorem ipsum dolor sit amet</ListItem>
        </List>
        <p>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est
          laborum.
        </p>
        <p>
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat.
        </p>
        <ButtonGroupWrapper actionbar>
          <ButtonGroupSecondary>
            <TextButton onClick={() => setIsOpen(false)}>Cancel</TextButton>
          </ButtonGroupSecondary>
          <ButtonGroupPrimary>
            <OutlinedButton
              className="ln-u-margin-right"
              onClick={() => setIsOpen(false)}
            >
              Decline
            </OutlinedButton>
            <FilledButton onClick={() => setIsOpen(false)}>Accept</FilledButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </div>
  )
}
`

Dialog.parameters = {
  docs: {
    source: {
      code: DialogSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
