import React from 'react'
import { BreadcrumbsWrapper, BreadcrumbsItem } from '@jsluna/breadcrumbs'

const Breadcrumbs = ({ title, type }) => {
  return (
    <BreadcrumbsWrapper className="c-breadcrumbs--wrapper">
      <BreadcrumbsItem
        className="c-breadcrumbs--item"
        style={{
          textTransform: 'capitalize',
        }}
      >
        {type}
      </BreadcrumbsItem>
      <BreadcrumbsItem className="c-breadcrumbs--item">{title}</BreadcrumbsItem>
    </BreadcrumbsWrapper>
  )
}

export default Breadcrumbs
