import React, { useState } from 'react'

import GlobalTokens from '@sainsburys-tech/tokens/dist/global/js/flat.json'
import SainsburysTokens from '@sainsburys-tech/tokens/dist/sainsburys/js/flat.json'
import ArgosTokens from '@sainsburys-tech/tokens/dist/argos/js/flat.json'
import NectarTokens from '@sainsburys-tech/tokens/dist/nectar/js/flat.json'
import HabitatTokens from '@sainsburys-tech/tokens/dist/habitat/js/flat.json'
import TuTokens from '@sainsburys-tech/tokens/dist/tu/js/flat.json'

import { SearchField } from '@jsluna/form'
import { Tabs, TabLink, TabPanel } from '@jsluna/tabs'
import { Card } from '@jsluna/card'
import { Search } from '@jsluna/icons'

import Fuse from 'fuse.js'
import { Display1 } from '@jsluna/typography'

const brandTokens = {
  argos: ArgosTokens,
  global: GlobalTokens,
  habitat: HabitatTokens,
  nectar: NectarTokens,
  sainsburys: SainsburysTokens,
  tu: TuTokens,
}

const options = {
  // isCaseSensitive: false,
  includeScore: true,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  minMatchCharLength: 2,
  location: 0,
  threshold: 0.4,
  // distance: 100,
  useExtendedSearch: false,
  ignoreLocation: true,
  ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ['value', 'token', 'name', 'comments'],
}

const TokenSearch = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [activeTab, setActiveTab] = useState('global')
  const [dirtySearch, setDirtySearch] = useState(false)
  const allTokens = [
    {
      tokens: GlobalTokens,
      selected: true,
      theme: 'Global',
      value: 'global',
    },
    {
      tokens: SainsburysTokens,
      selected: false,
      theme: "Sainsbury's",
      value: 'sainsburys',
    },
    {
      tokens: ArgosTokens,
      selected: false,
      theme: 'Argos',
      value: 'argos',
    },
    {
      tokens: TuTokens,
      selected: false,
      theme: 'Tu',
      value: 'tu',
    },
    {
      tokens: HabitatTokens,
      selected: false,
      theme: 'Habitat',
      value: 'habitat',
    },
    {
      tokens: NectarTokens,
      selected: false,
      theme: 'Nectar',
      value: 'nectar',
    },
  ]

  let fuse = new Fuse(brandTokens[activeTab], options)
  const results = fuse.search(searchQuery)

  function onSearch({ currentTarget }) {
    setSearchQuery(currentTarget.value)
    setDirtySearch(true)
  }

  return (
    <>
      {allTokens && (
        <>
          <Tabs>
            {allTokens.map(tab => (
              <TabLink
                key={tab.value}
                onClick={() => setActiveTab(tab.value)}
                active={activeTab === tab.value}
              >
                {tab.theme}
              </TabLink>
            ))}
          </Tabs>
          <TabPanel>
            <Card className="ln-u-hard-sides">
              <SearchField
                name="search-field"
                label="Search design tokens"
                hideLabel
                placeholder={`Search ${
                  allTokens.find(x => x.value === activeTab).theme
                } design tokens`}
                hasIcon
                icon={<Search />}
                onChange={onSearch}
                value={searchQuery}
              />

              {searchQuery && (
                <div className="ln-u-soft-bottom">
                  {results.length} results for <em>"{searchQuery}"</em> in{' '}
                  <strong>
                    {allTokens.find(x => x.value === activeTab).theme}
                  </strong>
                </div>
              )}
              <div>
                {results.map(result => (
                  <Card
                    className="ln-u-border ln-u-push-bottom-sm"
                    rounded
                    style={{
                      textDecoration:
                        result.item.comments === 'deprecated' && 'line-through',
                    }}
                  >
                    <Display1 className="ln-u-display-block ln-u-push-bottom-sm">
                      {result.item.isColor && (
                        <div
                          style={{
                            backgroundColor: `var(--ds-${result.item.token.replace(
                              /\./gi,
                              '-',
                            )})`,
                            display: 'inline-block',
                            width: `var(--ds-space-spacing-medium)`,
                            height: `var(--ds-space-spacing-medium)`,
                            borderRadius: '50%',
                            border: `var(--ds-modifier-border-width) solid var(--ds-mode-dark-border-interactive)`,
                            marginRight: `var(--ds-space-spacing-default)`,
                          }}
                        ></div>
                      )}
                      {result.item.name}
                    </Display1>
                    {result.item.comments && (
                      <div>
                        Comments: <strong>{result.item.comments}</strong>
                      </div>
                    )}
                    <div>
                      Value: <strong>{result.item.value}</strong>
                    </div>
                    <div>
                      Brand:{' '}
                      <strong>
                        {
                          allTokens.find(x => x.value === result.item.brand)
                            .theme
                        }
                      </strong>
                    </div>
                    <div>
                      Token: <strong>{result.item.token}</strong>
                    </div>
                    <div>
                      CSS custom property:{' '}
                      <code>
                        var(--ds-{result.item.token.replace(/\./gi, '-')})
                      </code>
                    </div>
                  </Card>
                ))}
              </div>
            </Card>
          </TabPanel>
        </>
      )}
    </>
  )
}

export default TokenSearch
