import React from 'react'
import { TextInputField } from '@jsluna/form'
import {
  Calendar,
  VoiceSearch,
  StoreLocation,
  Geolocation,
} from '@jsluna/icons'
import { Button, SearchButton } from '@jsluna/button'

export default {
  title: 'Text Input',
  component: TextInputField,
}

export const Standard = () => (
  <TextInputField name="text-input-field-1" label="Username" />
)

const StandardSourceCode = `
import { TextInputField } from '@jsluna/form'

export const Standard = () => (
  <TextInputField name="text-input-field-1" label="Username" />
)
`

Standard.parameters = {
  docs: {
    source: {
      code: StandardSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithRequiredIndicator = () => (
  <TextInputField name="text-input-field-2" required="*" label="Username" />
)

const WithRequiredIndicatorSourceCode = `
import { TextInputField } from '@jsluna/form'

export const WithRequiredIndicator = () => (
  <TextInputField
    name="text-input-field-2"
    required="*"
    label="Username"
  />
)
`

WithRequiredIndicator.parameters = {
  docs: {
    source: {
      code: WithRequiredIndicatorSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithSupportingText = () => (
  <TextInputField
    name="text-input-field-2"
    info="This can't include spaces"
    label="Username"
  />
)

const WithSupportingTextSourceCode = `
import { TextInputField } from '@jsluna/form'

export const WithSupportingText = () => (
  <TextInputField
    name="text-input-field-2"
    info="This can't include spaces"
    label="Username"
  />
)
`

WithSupportingText.parameters = {
  docs: {
    source: {
      code: WithSupportingTextSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithErrorMessage = () => (
  <TextInputField
    name="text-input-field-3"
    label="Username"
    error="Sorry this username is already taken"
    defaultValue={`Luna${new Date().getFullYear()}`}
  />
)

const WithErrorMessageSourceCode = `
import { TextInputField } from '@jsluna/form'

export const WithErrorMessage = () => (
  <TextInputField
    name="text-input-field-3"
    label="Username"
    error="Sorry this username is already taken"
    defaultValue={\`Luna\${new Date().getFullYear()}\`}
  />
)
`

WithErrorMessage.parameters = {
  docs: {
    source: {
      code: WithErrorMessageSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithPlaceholder = () => (
  <TextInputField
    name="text-input-field-4"
    label="First name"
    placeholder="Example: Mary"
  />
)

const WithPlaceholderSourceCode = `
import { TextInputField } from '@jsluna/form'

export const WithPlaceholder = () => (
  <TextInputField
    name="text-input-field-4"
    label="First name"
    placeholder="Example: Mary"
  />
)
`

WithPlaceholder.parameters = {
  docs: {
    source: {
      code: WithPlaceholderSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithIcon = () => (
  <TextInputField
    name="text-input-field-5"
    label="Date of birth"
    icon={<Calendar />}
  />
)

const WithIconSourceCode = `
import { TextInputField } from '@jsluna/form'

export const WithIcon = () => (
  <TextInputField
    name="text-input-field-5"
    label="Date of birth"
    icon={<Calendar />}
  />
)
`

WithIcon.parameters = {
  docs: {
    source: {
      code: WithIconSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithAction = () => (
  <TextInputField
    name="text-input-field-6"
    label="Voice search"
    action={
      <Button>
        <span className="ln-u-visually-hidden">Use microphone input</span>
        <VoiceSearch />
      </Button>
    }
  />
)

const WithActionSourceCode = `
import { TextInputField } from '@jsluna/form'
import { Button } from '@jsluna/button'
import { VoiceSearch } from '@jsluna/icons'

export const WithAction = () => (
  <TextInputField
    name="text-input-field-6"
    label="Voice search"
    action={
      <Button>
        <span className="ln-u-visually-hidden">Use microphone input</span>
        <VoiceSearch />
      </Button>
    }
  />
)
`

WithAction.parameters = {
  docs: {
    source: {
      code: WithActionSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithMultipleButtons = () => (
  <TextInputField
    name="text-input-field-7"
    label="Delivery location"
    icon={
      <label htmlFor="text-input-field-5" aria-hidden tabIndex="-1">
        <StoreLocation />
      </label>
    }
    action={
      <Button>
        <span className="ln-u-visually-hidden">Use current location</span>
        <Geolocation />
      </Button>
    }
  />
)

const WithMultipleButtonsSourceCode = `
import { TextInputField } from '@jsluna/form'
import { Button } from '@jsluna/button'
import { StoreLocation, Geolocation } from '@jsluna/icons'

export const WithMultipleButtons = () => (
  <TextInputField
    name="text-input-field-7"
    label="Delivery location"
    icon={
      <label htmlFor="text-input-field-5" aria-hidden tabIndex="-1">
        <StoreLocation />
      </label>
    }
    action={
      <Button>
        <span className="ln-u-visually-hidden">Use current location</span>
        <Geolocation />
      </Button>
    }
  />
)
`

WithMultipleButtons.parameters = {
  docs: {
    source: {
      code: WithMultipleButtonsSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithButton = () => (
  <TextInputField
    name="text-input-field-8"
    label="Search"
    button={<SearchButton />}
  />
)

const WithButtonSourceCode = `
import { TextInputField } from '@jsluna/form'
import { SearchButton } from '@jsluna/button'

export const WithButton = () => (
  <TextInputField
    name="text-input-field-8"
    label="Search"
    button={<SearchButton />}
  />
)
`

WithButton.parameters = {
  docs: {
    source: {
      code: WithButtonSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
