import { useStaticQuery, graphql } from 'gatsby'

export const useQueryAllTokens = () => {
  const data = useStaticQuery(graphql`
    query {
      allTokens {
        nodes {
          id
          type
          colours {
            name
            color {
              brand_1 {
                base
                lightest
                lighter
                light
                dark
                darker
                darkest
              }
              brand_2 {
                base
                lightest
                lighter
                light
                dark
                darker
                darkest
              }
              brand_3 {
                base
                lightest
                lighter
                light
                dark
                darker
                darkest
              }
              brand_4 {
                base
                lightest
                lighter
                light
                dark
                darker
                darkest
              }
              campaign_1 {
                lightest
                lighter
                light
                base
                dark
                darker
                darkest
              }
              campaign_2 {
                lightest
                lighter
                light
                base
                dark
                darker
                darkest
              }
              campaign_3 {
                lightest
                lighter
                light
                base
                dark
                darker
                darkest
              }
              campaign_4 {
                lightest
                lighter
                light
                base
                dark
                darker
                darkest
              }
              campaign_5 {
                lightest
                lighter
                light
                base
                dark
                darker
                darkest
              }
              campaign_6 {
                lightest
                lighter
                light
                base
                dark
                darker
                darkest
              }
              campaign_7 {
                lightest
                lighter
                light
                base
                dark
                darker
                darkest
              }
              element {
                focus
              }
              mode {
                default {
                  border
                  scheme {
                    base
                    highlight
                    on {
                      base
                      highlight
                    }
                  }
                  surface {
                    background
                    foreground
                  }
                }
              }
              monochrome {
                white
                lighter
                lighter__rgb
                light
                light__rgb
                medium
                medium__rgb
                dark
                dark__rgb
                darker
                darker__rgb
                black
              }
              palette {
                black
                blue_100
                blue_90
                blue_80
                blue_70
                blue_60
                blue_50
                blue_40
                blue_30
                blue_20
                blue_10
                brand_1
                brand_1_highlight
                brand_1_lowlight
                brand_2
                brand_2_highlight
                brand_2_lowlight
                green_100
                green_90
                green_80
                green_70
                green_60
                green_50
                green_40
                green_30
                green_20
                green_10
                grey_100
                grey_90
                grey_80
                grey_70
                grey_60
                grey_50
                grey_40
                grey_30
                grey_20
                grey_10
                blue
                green
                red
                yellow
                red_100
                red_90
                red_80
                red_70
                red_60
                red_50
                red_40
                red_30
                red_20
                red_10
                white
                yellow_100
                yellow_90
                yellow_80
                yellow_70
                yellow_60
                yellow_50
                yellow_40
                yellow_30
                yellow_20
                yellow_10
                orange_10
                orange_50
                orange_60
                orange_70
                orange_lighter
                orange_light
                orange
                orange_dark
                plum_light
                plum
                plum_dark
                plum_50
                plum_60
                plum_70
                brand_3
                brand_3_highlight
                brand_3_lowlight
                brand_4
                brand_4_highlight
                green_light
                green_dark
                blue_light
                blue_dark
                red_light
                red_dark
                yellow_dark
                purple
                purple_dark
                purple_darker
                purple_darkest
                yellow_darker
                yellow_darkest
                red_darker
                red_darkest
                green_darker
                green_darkest
                putty
                purple_100
                purple_90
                purple_80
                purple_70
                purple_60
                purple_50
                purple_40
                purple_30
                purple_20
                purple_10
                putty_60
                black_50
                black_60
                cloud_100
                cloud_90
                cloud_80
                cloud_70
                cloud_60
                cloud_50
                cloud_40
                cloud_30
                cloud_20
                cloud_10
                earth_100
                earth_90
                earth_80
                earth_70
                earth_60
                earth_50
                earth_40
                earth_30
                earth_20
                earth_10
                kiss_100
                kiss_90
                kiss_80
                kiss_70
                kiss_60
                kiss_50
                kiss_40
                kiss_30
                kiss_20
                kiss_10
                lavender_100
                lavender_90
                lavender_80
                lavender_70
                lavender_60
                lavender_50
                lavender_40
                lavender_30
                lavender_20
                lavender_10
                leaf_100
                leaf_90
                leaf_80
                leaf_70
                leaf_60
                leaf_50
                leaf_40
                leaf_30
                leaf_20
                leaf_10
                black_light
                white_dark
                cloud
                earth
                kiss
                lavender
                sky
                water
                sky_100
                sky_90
                sky_80
                sky_70
                sky_60
                sky_50
                sky_40
                sky_30
                sky_20
                sky_10
                water_100
                water_90
                water_80
                water_70
                water_60
                water_50
                water_40
                water_30
                water_20
                water_10
                white_70
                white_60
                dark_blue_30
                dark_blue_40
                dark_blue_50
                dark_blue_60
                strong_blue_30
                strong_blue_40
                strong_blue_50
                strong_blue_60
                faux_malachite_30
                faux_malachite_40
                faux_malachite_50
                faux_malachite_60
                pink_30
                pink_40
                pink_50
                pink_60
                selective_yellow_30
                selective_yellow_40
                selective_yellow_50
                selective_yellow_60
                soft_red_30
                soft_red_40
                soft_red_50
                soft_red_60
              }
              promotion {
                new {
                  lightest
                  lighter
                  light
                  base
                  dark
                  darker
                  darkest
                }
                sale {
                  lightest
                  lighter
                  light
                  base
                  dark
                  darker
                  darkest
                }
              }
              scheme {
                brand_1 {
                  on {
                    base
                    highlight
                    lowlight
                  }
                  lowlight
                  highlight
                  base
                }
                brand_2 {
                  on {
                    base
                    highlight
                  }
                  lowlight
                  highlight
                  base
                }
                brand_3 {
                  on {
                    base
                    highlight
                  }
                  highlight
                  base
                }
                brand_4 {
                  on {
                    base
                    highlight
                  }
                  base
                  highlight
                }
                dark {
                  on {
                    base
                    highlight
                  }
                  base
                  highlight
                }
                light {
                  on {
                    base
                    highlight
                  }
                  base
                  highlight
                }
              }
              semantic {
                error {
                  lighter
                  light
                  base
                  dark
                  darker
                  text
                }
                info {
                  lighter
                  light
                  base
                  dark
                  darker
                  text
                }
                success {
                  lighter
                  light
                  base
                  dark
                  darker
                  text
                }
                warning {
                  lighter
                  light
                  base
                  dark
                  darker
                  text
                }
              }
              transparency {
                black
                default
                white
                brand_1
                brand_2
                orange_dark
              }
            }
          }
          modifiers {
            modifier {
              opacity {
                default
                light
                disabled
                background
              }
              border {
                radius
                radius_large
                width
                width_large
                width_extra_large
              }
              border_interactive {
                width
              }
              border {
                width
                width_large
                radius
                radius_large
              }
              button {
                border
                border_radius
                text_transform
              }
              focus: focus_ring {
                focus_width
                color
              }
              footer {
                background
                text
              }
              global_bar {
                height
              }
              header {
                background
                background_dark
              }
              overlay {
                color
                opacity
              }
              transition {
                duration
                duration_long
                timing_function {
                  default
                }
              }
            }
            name
          }
          space {
            name
            space {
              baseline {
                condensed
                grid
              }
              breakpoints {
                extra_extra_small
                extra_small
                small
                medium
                large
                extra_large
                extra_extra_large
              }
              fixed_width {
                extra_small
                small
                medium
                large
              }
              size {
                small
                medium
                large
              }
              spacing {
                default
                extra_small
                small
                medium
                large
                extra_large
                half
                x1
                x2
                x3
                x4
                x5
                x6
                x7
                x8
                x9
                x10
              }
            }
          }
          typography {
            name
            typography {
              base {
                font_family
                font
                letterform
                fallback
              }
              brand {
                font_family
                font
                letterform
                fallback
              }
              font_size {
                root
                default
                rem
              }
              font_weight {
                light
                regular
                medium
                semi_bold
                bold
                extra_bold
                black
              }
              monospace {
                font_family
              }
              small {
                font_family
                font
                letterform
              }
              system {
                font_family
              }
            }
            font_stack {
              body_1 {
                font_size
                line_height
                font_weight
                font_family
              }
              body_2 {
                font_size
                line_height
                font_weight
                font_family
              }
              button {
                font_size
                line_height
                font_weight
                font_family
              }
              caption {
                font_size
                line_height
                font_weight
                font_family
              }
              display_2 {
                font_size
                line_height
                font_weight
                font_family
              }
              display_1 {
                font_size
                line_height
                font_weight
                font_family
              }
              display_3 {
                font_size
                line_height
                font_weight
                font_family
              }
              display_4 {
                font_size
                line_height
                font_weight
                font_family
              }
              display_5 {
                font_size
                line_height
                font_weight
                font_family
              }
              display_6 {
                font_size
                line_height
                font_weight
                font_family
              }
              display_7 {
                font_size
                line_height
                font_weight
                font_family
              }
              label_1 {
                font_size
                line_height
                font_weight
                font_family
              }
              label_2 {
                font_size
                line_height
                font_weight
                font_family
              }
            }
          }
        }
      }
    }
  `)

  const modifiedData = data.allTokens.nodes.map(datum => {
    if (datum.type === 'modifiers') {
      const modifiers = datum[datum.type]

      return {
        ...datum,
        modifiers: modifiers.map(modifierTokens => ({
          ...modifierTokens,
          modifier: {
            ...modifierTokens.modifier,
            border: {
              ...modifierTokens.modifier.border,
              border_interactive:
                modifierTokens.modifier.border_interactive.width,
            },
            border_modifiers:
              modifierTokens.name === 'Tu'
                ? {
                    ...modifierTokens.modifier.border_modifiers,
                    border_interactive:
                      modifierTokens.modifier.border_interactive.width,
                  }
                : null,
            header: {
              ...modifierTokens.modifier.header,
              global_bar: modifierTokens.modifier.global_bar.height,
            },
          },
        })),
      }
    }

    return datum
  })

  return { data: modifiedData }
}
