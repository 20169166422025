import React from 'react'

import { Container } from '@jsluna/grid'

export default {
  title: 'Container',
  component: Container,
}

export const Standard = () => (
  <Container>
    <div className="ln-u-bg-color-light ln-u-soft" />
  </Container>
)

const StandardSourceCode = `
import { Container } from '@jsluna/grid'

export const Standard = () => (
  <Container>
    <div className="ln-u-bg-color-light ln-u-soft" />
  </Container>
)
`

Standard.parameters = {
  docs: {
    source: {
      code: StandardSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const OuterMargin = () => (
  <Container soft="lg">
    <div className="ln-u-bg-color-light ln-u-soft" />
  </Container>
)

const OuterMarginSourceCode = `
import { Container } from '@jsluna/grid'

export const OuterMargin = () => (
  <Container soft="lg">
    <div className="ln-u-bg-color-light ln-u-soft" />
  </Container>
)
`

OuterMargin.parameters = {
  docs: {
    source: {
      code: OuterMarginSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const ExtraSmall = () => (
  <Container size="xs">
    <div className="ln-u-bg-color-light ln-u-soft ln-u-text-align-center">
      Extra small content
    </div>
  </Container>
)

const ExtraSmallSourceCode = `
import { Container } from '@jsluna/grid'

export const ExtraSmall = () => (
  <Container size="xs">
    <div className="ln-u-bg-color-light ln-u-soft ln-u-text-align-center">
      Extra small content
    </div>
  </Container>
)
`

ExtraSmall.parameters = {
  docs: {
    source: {
      code: ExtraSmallSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Large = () => (
  <Container size="lg">
    <div className="ln-u-bg-color-light ln-u-soft ln-u-text-align-center">
      Large content
    </div>
  </Container>
)

const LargeSourceCode = `
import { Container } from '@jsluna/grid'

export const Large = () => (
  <Container size="lg">
    <div className="ln-u-bg-color-light ln-u-soft ln-u-text-align-center">
      Large content
    </div>
  </Container>
)
`

Large.parameters = {
  docs: {
    source: {
      code: LargeSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Fluid = () => (
  <Container fluid>
    <div className="ln-u-bg-color-light ln-u-soft" />
  </Container>
)

const FluidSourceCode = `
import { Container } from '@jsluna/grid'

export const Fluid = () => (
  <Container fluid>
    <div className="ln-u-bg-color-light ln-u-soft" />
  </Container>
)
`

Fluid.parameters = {
  docs: {
    source: {
      code: FluidSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Free = () => (
  <Container free size="xs">
    <div className="ln-u-bg-color-light ln-u-soft" />
  </Container>
)

const FreeSourceCode = `
import { Container } from '@jsluna/grid'

export const Free = () => (
  <Container free size="xs">
    <div className="ln-u-bg-color-light ln-u-soft" />
  </Container>
)
`

Free.parameters = {
  docs: {
    source: {
      code: FreeSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
