import React from 'react'
import { PasswordField } from '@jsluna/form'

export default {
  title: 'Password Input',
  component: PasswordField,
}

export const Standard = () => (
  <PasswordField name="password-field-1" label="Password" />
)

const StandardSourceCode = `
import { PasswordField } from '@jsluna/form'

export const Standard = () => (
  <PasswordField name="password-field-1" label="Password" />
)
`

Standard.parameters = {
  docs: {
    source: {
      code: StandardSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithVisibilityToggle = () => (
  <PasswordField name="password-field-2" label="Password" hasToggle />
)

const WithVisibilityToggleSourceCode = `
import { PasswordField } from '@jsluna/form'

export const WithVisibilityToggle = () => (
  <PasswordField name="password-field-2" label="Password" hasToggle />
)
`

WithVisibilityToggle.parameters = {
  docs: {
    source: {
      code: WithVisibilityToggleSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithStrengthHint = () => (
  <PasswordField name="password-field-3" label="Password" strengthIndicator />
)

const WithStrengthHintSourceCode = `
import { PasswordField } from '@jsluna/form'

export const WithStrengthHint = () => (
  <PasswordField name="password-field-3" label="Password" strengthIndicator />
)
`

WithStrengthHint.parameters = {
  docs: {
    source: {
      code: WithStrengthHintSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithErrorMessage = () => (
  <PasswordField
    name="password-field-4"
    label="Password"
    error={
      <span>
        <p className="ln-u-display-inline-block ln-u-margin-bottom">
          Your password must have:
        </p>
        <ul className="ln-u-flush-bottom ln-u-margin-left*4">
          <li>8 to 100 characters in length</li>
          <li>
            At least:
            <ul>
              <li>1 uppercase letter (A-Z)</li>
              <li>1 lowercase letter (a-z)</li>
              <li>1 number (0-9)</li>
            </ul>
          </li>
        </ul>
      </span>
    }
  />
)

const WithErrorMessageSourceCode = `
import { PasswordField } from '@jsluna/form'

export const WithErrorMessage = () => (
  <PasswordField
    name="password-field-4"
    label="Password"
    error={
      <span>
        <p className="ln-u-display-inline-block ln-u-margin-bottom">
          Your password must have:
        </p>
        <ul className="ln-u-flush-bottom ln-u-margin-left*4">
          <li>8 to 100 characters in length</li>
          <li>
            At least:
            <ul>
              <li>1 uppercase letter (A-Z)</li>
              <li>1 lowercase letter (a-z)</li>
              <li>1 number (0-9)</li>
            </ul>
          </li>
        </ul>
      </span>
    }
  />
)
`

WithErrorMessage.parameters = {
  docs: {
    source: {
      code: WithErrorMessageSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
