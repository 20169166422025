import React from 'react'
import { Link } from '@jsluna/link'
import { List, ListItem } from '@jsluna/list'

export default {
  title: 'Link',
  component: Link,
}

export const Inline = () => (
  <p>
    <Link href="#" color="alpha">
      Use our stock checker
    </Link>{' '}
    to see if the product you want is in stock.
  </p>
)

const InlineSourceCode = `
import { Link } from '@jsluna/link'
  
export const Inline = () => (
  <p>
    <Link href="#" color="alpha">
      Use our stock checker
    </Link>{' '}
    to see if the product you want is in stock.
  </p>
)
`

Inline.parameters = {
  docs: {
    source: {
      code: InlineSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const StandaloneBare = () => (
  <Link href="#" color="alpha" bare>
    Go to stock checker
  </Link>
)

const StandaloneBareSourceCode = `
import { Link } from '@jsluna/link'
  
export const StandaloneBare = () => (
  <Link href="#" color="alpha" bare>
    Go to stock checker
  </Link>
)
`
StandaloneBare.parameters = {
  docs: {
    source: {
      code: StandaloneBareSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Standalone = () => <Link href="#">Go to stock checker</Link>

const StandaloneSourceCode = `
import { Link } from '@jsluna/link'
  
export const Standalone = () => <Link href="#">Go to stock checker</Link>
`

Standalone.parameters = {
  docs: {
    source: {
      code: StandaloneSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const CallToAction = () => (
  <Link href="#" cta bare color="alpha">
    Go to stock checker
  </Link>
)

const CallToActionSourceCode = `
import { Link } from '@jsluna/link'
  
export const CallToAction = () => (
  <Link href="#" cta bare color="alpha">
    Go to stock checker
  </Link>
)
`

CallToAction.parameters = {
  docs: {
    source: {
      code: CallToActionSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Brand = () => (
  <>
    <List type="bare" className="ln-u-text-align-center">
      <ListItem type="bare">
        <p>
          <Link href="#" color="alpha">
            Use our stock checker
          </Link>{' '}
          to see if the product you want is in stock.
        </p>
      </ListItem>
      <ListItem type="bare">
        <p>
          <Link href="#" bare color="alpha">
            Go to stock checker
          </Link>
        </p>
      </ListItem>
      <ListItem type="bare">
        <p>
          <Link href="#" cta bare color="alpha">
            Go to stock checker
          </Link>
        </p>
      </ListItem>
    </List>
  </>
)

const BrandSourceCode = `
import { Link } from '@jsluna/link'
import { List, ListItem } from '@jsluna/list'
  
export const Brand = () => (
  <>
    <List type="bare" className="ln-u-text-align-center">
      <ListItem type="bare">
        <p>
          <Link href="#" color="alpha">
            Use our stock checker
          </Link>{' '}
          to see if the product you want is in stock.
        </p>
      </ListItem>
      <ListItem type="bare">
        <p>
          <Link href="#" bare color="alpha">
            Go to stock checker
          </Link>
        </p>
      </ListItem>
      <ListItem type="bare">
        <p>
          <Link href="#" cta bare color="alpha">
            Go to stock checker
          </Link>
        </p>
      </ListItem>
    </List>
  </>
)
`

Brand.parameters = {
  docs: {
    source: {
      code: BrandSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Light = () => (
  <>
    <List type="bare" className="ln-u-text-align-center">
      <ListItem type="bare">
        <p>
          <Link href="#">Use our stock checker</Link> to see if the product you
          want is in stock.
        </p>
      </ListItem>
      <ListItem type="bare">
        <p>
          <Link href="#" bare>
            Go to stock checker
          </Link>
        </p>
      </ListItem>
      <ListItem type="bare">
        <p>
          <Link href="#" cta bare>
            Go to stock checker
          </Link>
        </p>
      </ListItem>
    </List>
  </>
)

const LightSourceCode = `
import { Link } from '@jsluna/link'
import { List, ListItem } from '@jsluna/list'
  
export const Light = () => (
  <>
    <List type="bare" className="ln-u-text-align-center">
      <ListItem type="bare">
        <p>
          <Link href="#">Use our stock checker</Link> to see if the product you
          want is in stock.
        </p>
      </ListItem>
      <ListItem type="bare">
        <p>
          <Link href="#" bare>
            Go to stock checker
          </Link>
        </p>
      </ListItem>
      <ListItem type="bare">
        <p>
          <Link href="#" cta bare>
            Go to stock checker
          </Link>
        </p>
      </ListItem>
    </List>
  </>
)
`

Light.parameters = {
  docs: {
    source: {
      code: LightSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
  backgrounds: { default: 'grey-light' },
}

export const Dark = () => (
  <>
    <List type="bare" className="ln-u-text-align-center">
      <ListItem type="bare">
        <p className="ln-u-color-white">
          <Link href="#" color="light">
            Use our stock checker
          </Link>{' '}
          to see if the product you want is in stock.
        </p>
      </ListItem>
      <ListItem type="bare">
        <p>
          <Link href="#" bare color="light">
            Go to stock checker
          </Link>
        </p>
      </ListItem>
      <ListItem type="bare">
        <p>
          <Link href="#" cta bare color="light">
            Go to stock checker
          </Link>
        </p>
      </ListItem>
    </List>
  </>
)

const DarkSourceCode = `
import { Link } from '@jsluna/link'
import { List, ListItem } from '@jsluna/list'
  
export const Dark = () => (
  <>
    <List type="bare" className="ln-u-text-align-center">
      <ListItem type="bare">
        <p className="ln-u-color-white">
          <Link href="#" color="light">
            Use our stock checker
          </Link>{' '}
          to see if the product you want is in stock.
        </p>
      </ListItem>
      <ListItem type="bare">
        <p>
          <Link href="#" bare color="light">
            Go to stock checker
          </Link>
        </p>
      </ListItem>
      <ListItem type="bare">
        <p>
          <Link href="#" cta bare color="light">
            Go to stock checker
          </Link>
        </p>
      </ListItem>
    </List>
  </>
)
`

Dark.parameters = {
  docs: {
    source: {
      code: DarkSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
  backgrounds: { default: 'dark' },
}
