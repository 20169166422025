import React from 'react'
import { RadioButton, RadioButtonField } from '@jsluna/form'

export default {
  title: 'Radio Button',
  component: RadioButtonField,
}

export const Stacked = () => (
  <div style={{ maxWidth: '20rem' }}>
    <RadioButtonField
      label="What type of building is it?"
      name="radio-button-field-1"
      fullWidth
      options={[
        { value: 'detached', label: 'Detached', defaultChecked: true },
        { value: 'semi-detached', label: 'Semi-detached' },
        { value: 'terraced', label: 'Terraced' },
        { value: 'town-house', label: 'Townhouse' },
      ]}
    />
  </div>
)

const StackedSourceCode = `
import { RadioButtonField } from '@jsluna/form'

export const Stacked = () => (
  <div style={{ maxWidth: '20rem' }}>
    <RadioButtonField
      label="What type of building is it?"
      name="radio-button-field-1"
      fullWidth
      options={[
        { value: 'detached', label: 'Detached', defaultChecked: true },
        { value: 'semi-detached', label: 'Semi-detached' },
        { value: 'terraced', label: 'Terraced' },
        { value: 'town-house', label: 'Townhouse' },
      ]}
    />
  </div>
)
`

Stacked.parameters = {
  docs: {
    source: {
      code: StackedSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Inline = () => (
  <RadioButtonField
    label="What is the property type?"
    name="radio-button-field-2"
    listType="inline"
    options={[
      { value: 'house', label: 'House' },
      { value: 'flat', label: 'Flat', defaultChecked: true },
    ]}
  />
)

const InlineSourceCode = `
import { RadioButtonField } from '@jsluna/form'

export const Inline = () => (
  <RadioButtonField
    label="What is the property type?"
    name="radio-button-field-2"
    listType="inline"
    options={[
      { value: 'house', label: 'House' },
      { value: 'flat', label: 'Flat', defaultChecked: true },
    ]}
  />
)
`

Inline.parameters = {
  docs: {
    source: {
      code: InlineSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Outlined = () => (
  <div style={{ maxWidth: '20rem' }}>
    <RadioButtonField
      label="What type of building is it?"
      name="radio-button-field-3"
      fullWidth
      options={[
        { value: 'detached', label: 'Detached', defaultChecked: true },
        { value: 'semi-detached', label: 'Semi-detached' },
        { value: 'terraced', label: 'Terraced' },
        { value: 'town-house', label: 'Townhouse' },
      ]}
      outlined
    />
  </div>
)

const OutlinedSourceCode = `
import { RadioButtonField } from '@jsluna/form'

export const Outlined = () => (
  <div style={{ maxWidth: '20rem' }}>
    <RadioButtonField
      label="What type of building is it?"
      name="radio-button-field-3"
      fullWidth
      options={[
        { value: 'detached', label: 'Detached', defaultChecked: true },
        { value: 'semi-detached', label: 'Semi-detached' },
        { value: 'terraced', label: 'Terraced' },
        { value: 'town-house', label: 'Townhouse' },
      ]}
      outlined
    />
  </div>
)
`

Outlined.parameters = {
  docs: {
    source: {
      code: OutlinedSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const OutlinedInline = () => (
  <RadioButtonField
    label="What is the property type?"
    name="radio-button-field-4"
    listType="inline"
    options={[
      { value: 'house', label: 'House' },
      { value: 'flat', label: 'Flat', defaultChecked: true },
    ]}
    outlined
  />
)

const OutlinedInlineSourceCode = `
import { RadioButtonField } from '@jsluna/form'

export const OutlinedInline = () => (
  <RadioButtonField
    label="What is the property type?"
    name="radio-button-field-4"
    listType="inline"
    options={[
      { value: 'house', label: 'House' },
      { value: 'flat', label: 'Flat', defaultChecked: true },
    ]}
    outlined
  />
)
`

OutlinedInline.parameters = {
  docs: {
    source: {
      code: OutlinedInlineSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithErrorMessage = () => (
  <RadioButtonField
    label="Who’s travelling?"
    name="radio-button-field-5"
    options={[
      { value: 'single', label: 'Just me' },
      { value: 'couple', label: 'Couple' },
      { value: 'group', label: 'Group' },
    ]}
    error="Please select how many people will be travelling"
  />
)

const WithErrorMessageSourceCode = `
import { RadioButtonField } from '@jsluna/form'

export const WithErrorMessage = () => (
  <RadioButtonField
    label="Who’s travelling?"
    name="radio-button-field-5"
    options={[
      { value: 'single', label: 'Just me' },
      { value: 'couple', label: 'Couple' },
      { value: 'group', label: 'Group' },
    ]}
    error="Please select how many people will be travelling"
  />
)
`

WithErrorMessage.parameters = {
  docs: {
    source: {
      code: WithErrorMessageSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Standalone = () => (
  <React.Fragment>
    <RadioButton
      name="radio-button-with-hidden-label"
      label="Hidden label"
      hideLabel
    />
    <RadioButton
      name="radio-button-with-hidden-label-checked"
      label="Hidden label (default checked)"
      defaultChecked
      hideLabel
    />
  </React.Fragment>
)

const StandaloneSourceCode = `
import { RadioButton } from '@jsluna/form'

export const Standalone = () => (
  <React.Fragment>
    <RadioButton
      name="radio-button-with-hidden-label"
      label="Hidden label"
      hideLabel
    />
    <RadioButton
      name="radio-button-with-hidden-label-checked"
      label="Hidden label (default checked)"
      defaultChecked
      hideLabel
    />
  </React.Fragment>
)
`

Standalone.parameters = {
  docs: {
    source: {
      code: StandaloneSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
