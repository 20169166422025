import React from 'react'
import dedent from 'ts-dedent'

import '@jsluna/date-picker/react-dates-initialize'
import moment from 'moment'
import 'moment/min/locales'
import { DateRangePicker, SingleDatePicker } from '@jsluna/date-picker'
import { FormGroup } from '@jsluna/form'
import { filterProps } from '@jsluna/utils'
moment.locale('en-gb')

export default {
  title: 'Date Picker',
  component: SingleDatePicker,
}

export const Single = () => {
  const [date, setDate] = React.useState(null)
  const [focused, setFocused] = React.useState(false)

  return (
    <div style={{ height: '380px' }}>
      <FormGroup name="sdp-2" label="Select a date">
        {({ name, ...inputProps }) => (
          <SingleDatePicker
            id={name}
            {...filterProps(inputProps, ['aria-describedby', 'invalid'])}
            date={date}
            focused={focused}
            onDateChange={setDate}
            onFocusChange={({ focused }) => setFocused(focused)}
          />
        )}
      </FormGroup>
    </div>
  )
}

const SingleWithImports = `
import '@jsluna/date-picker/react-dates-initialize'
import { SingleDatePicker } from '@jsluna/date-picker'
import { FormGroup } from '@jsluna/form'
import { filterProps } from '@jsluna/utils'

export const Single = () => {
  const [date, setDate] = React.useState(null)
  const [focused, setFocused] = React.useState(false)

    return (
      <div style={{ height: '380px' }}>
        <FormGroup name="sdp-2" label="Select a date">
          {({ name, ...inputProps }) => (
            <SingleDatePicker
              id={name}
              {...filterProps(inputProps, ['aria-describedby', 'invalid'])}
              date={date}
              focused={focused}
              onDateChange={setDate}
              onFocusChange={({ focused }) => setFocused(focused)}
            />
          )}
        </FormGroup>
      </div>
    )
}
    `

Single.parameters = {
  docs: {
    source: {
      code: SingleWithImports,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Range = () => {
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [focusedInput, setFocusedInput] = React.useState(null)

  return (
    <div style={{ height: '380px' }}>
      <DateRangePicker
        startDate={startDate}
        startDateId="drp-start-date"
        endDate={endDate}
        endDateId="drp-end-date"
        onDatesChange={({ startDate, endDate }) => {
          setStartDate(startDate)
          setEndDate(endDate)
        }}
        focusedInput={focusedInput}
        onFocusChange={input => setFocusedInput(input)}
        numberOfMonths={2}
      />
    </div>
  )
}

const RangeWithImports = `
import '@jsluna/date-picker/react-dates-initialize'
import { DateRangePicker } from '@jsluna/date-picker'

export const Range = () => {
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [focusedInput, setFocusedInput] = React.useState(null)

  return (
    <div style={{ height: '380px' }}>
      <DateRangePicker
        startDate={startDate}
        startDateId="drp-start-date"
        endDate={endDate}
        endDateId="drp-end-date"
        onDatesChange={({ startDate, endDate }) => {
          setStartDate(startDate)
          setEndDate(endDate)
        }}
        focusedInput={focusedInput}
        onFocusChange={input => setFocusedInput(input)}
        numberOfMonths={2}
      />
    </div>
  )
}
    `

Range.parameters = {
  docs: {
    source: {
      code: RangeWithImports,
      language: 'jsx',
      type: 'auto',
    },
  },
}
