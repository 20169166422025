import React from 'react'

import {
  TextButton,
  FilledButton,
  OutlinedButton,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
} from '@jsluna/button'

export default {
  title: 'Button Group',
  component: ButtonGroupWrapper,
}

export const Standard = () => (
  <ButtonGroupWrapper>
    <ButtonGroupSecondary>
      <TextButton color="dark">Back</TextButton>
    </ButtonGroupSecondary>
    <ButtonGroupPrimary>
      <OutlinedButton className="ln-u-margin-right">Save</OutlinedButton>
      <FilledButton>Add</FilledButton>
    </ButtonGroupPrimary>
  </ButtonGroupWrapper>
)

const StandardSourceCode = `
import { 
  TextButton, 
  FilledButton, 
  OutlinedButton, 
  ButtonGroupWrapper, 
  ButtonGroupPrimary, 
  ButtonGroupSecondary 
} from '@jsluna/button'

export const Standard = () => (
  <ButtonGroupWrapper>
    <ButtonGroupSecondary>
      <TextButton color="dark">Back</TextButton>
    </ButtonGroupSecondary>
    <ButtonGroupPrimary>
      <OutlinedButton className="ln-u-margin-right">Save</OutlinedButton>
      <FilledButton>Add</FilledButton>
    </ButtonGroupPrimary>
  </ButtonGroupWrapper>
)
`

Standard.parameters = {
  docs: {
    source: {
      code: StandardSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
