import React from 'react'
import { SearchField, withClear } from '@jsluna/form'
import { Search } from '@jsluna/icons'
import { Button } from '@jsluna/button'

export default {
  title: 'Search',
  component: SearchField,
}

export const WithButton = () => (
  <SearchField
    name="search-field-1"
    label="Search products"
    hideLabel
    placeholder="Search products"
    hasAction
    action={
      <Button>
        <span className="ln-u-visually-hidden">Search products</span>
        <Search />
      </Button>
    }
  />
)

const WithButtonSourceCode = `
import { SearchField, withClear } from '@jsluna/form'
import { Search } from '@jsluna/icons'
import { Button } from '@jsluna/button'

export const WithButton = () => (
  <SearchField
    name="search-field-1"
    label="Search products"
    hideLabel
    placeholder="Search products"
    hasAction
    action={
      <Button>
        <span className="ln-u-visually-hidden">Search products</span>
        <Search />
      </Button>
    }
  />
)
`

WithButton.parameters = {
  docs: {
    source: {
      code: WithButtonSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Clearable = () => {
  const ClearableSearchField = withClear(SearchField)
  return (
    <ClearableSearchField
      name="search-field-2"
      hasButton
      label="Search products"
    />
  )
}

const ClearableSourceCode = `
import { SearchField, withClear } from '@jsluna/form'

export const Clearable = () => {
  const ClearableSearchField = withClear(SearchField)
  return (
    <ClearableSearchField
      name="search-field-2"
      hasButton
      label="Search products"
    />
  )
}
`

Clearable.parameters = {
  docs: {
    source: {
      code: ClearableSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithIcon = () => (
  <SearchField
    name="search-field-3"
    label="Search products"
    hideLabel
    placeholder="Search products"
    hasIcon
    icon={<Search />}
  />
)

const WithIconSourceCode = `
import { SearchField } from '@jsluna/form'
import { Search } from '@jsluna/icons'

export const WithIcon = () => (
  <SearchField
    name="search-field-3"
    label="Search products"
    hideLabel
    placeholder="Search products"
    hasIcon
    icon={<Search />}
  />
)
`

WithIcon.parameters = {
  docs: {
    source: {
      code: WithIconSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
