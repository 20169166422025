import React from 'react'
import formatToken from '@utils/formatToken'
import { formatFontName } from '@utils/formatTokenTable'

const TypographyToken = ({
  token,
  tokenItem,
  typographyTokens,
  suffix,
  display,
  brand,
}) => {
  let tokenValue = false

  if (typographyTokens) {
    token = formatToken(token)

    tokenValue = typographyTokens['font_stack'][token][tokenItem]
      ? typographyTokens['font_stack'][token][tokenItem]
      : false
  }

  if (tokenValue && tokenItem !== 'font_family') {
    return <div>{`${tokenValue}${suffix}`}</div>
  } else if (tokenValue && tokenItem === 'font_family') {
    const fontName = formatFontName(tokenValue, brand, display)
    const brandName = brand.toLowerCase().replace(/'/g, '')

    return React.createElement(
      'span',
      { className: `ds-theme--${brandName} ln-u-${display}` },
      fontName,
    )
  } else {
    return <div>No value found</div>
  }
}

export default TypographyToken
