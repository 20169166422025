import React from 'react'
import { FormGroup, InputControl, InputGroup, TextInput } from '@jsluna/form'

export default {
  title: 'Form Group',
  component: FormGroup,
  argTypes: {
    label: 'string',
  },
  args: {
    label: 'Form group',
  },
}

export const RenderProp = args => (
  <FormGroup name="form-group-1" required="*" {...args}>
    {inputProps => (
      <InputGroup>
        <InputControl>
          <TextInput {...inputProps} placeholder="Custom control" />
        </InputControl>
      </InputGroup>
    )}
  </FormGroup>
)

const RenderPropSourceCode = `
import { FormGroup, InputControl, InputGroup, TextInput } from '@jsluna/form'

export const RenderProp = () => (
  <FormGroup name="form-group-1" required label="Form group">
    {inputProps => (
      <InputGroup>
        <InputControl>
          <TextInput {...inputProps} placeholder="Custom control" />
        </InputControl>
      </InputGroup>
    )}
  </FormGroup>
)
`

RenderProp.parameters = {
  docs: {
    source: {
      code: RenderPropSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const ChildNode = args => (
  <FormGroup name="form-group-2" required {...args}>
    <InputGroup>
      <InputControl>
        <TextInput name="form-group-2" placeholder="Custom control" />
      </InputControl>
    </InputGroup>
  </FormGroup>
)

const ChildNodeSourceCode = `
import { FormGroup, InputControl, InputGroup, TextInput } from '@jsluna/form'

export const ChildNode = () => (
  <FormGroup name="form-group-2" required label="Form group">
    <InputGroup>
      <InputControl>
        <TextInput name="form-group-2" placeholder="Custom control" />
      </InputControl>
    </InputGroup>
  </FormGroup>
)
`

ChildNode.parameters = {
  docs: {
    source: {
      code: ChildNodeSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const HiddenLabel = args => (
  <FormGroup name="form-group-3" hideLabel {...args}>
    <InputGroup>
      <InputControl>
        <TextInput
          name="form-group-3"
          placeholder="Custom control with hidden label"
        />
      </InputControl>
    </InputGroup>
  </FormGroup>
)

const HiddenLabelSourceCode = `
import { FormGroup, InputControl, InputGroup, TextInput } from '@jsluna/form'

export const HiddenLabel = () => (
  <FormGroup name="form-group-3" hideLabel label="Form group">
    <InputGroup>
      <InputControl>
        <TextInput
          name="form-group-3"
          placeholder="Custom control with hidden label"
        />
      </InputControl>
    </InputGroup>
  </FormGroup>
)
`

HiddenLabel.parameters = {
  docs: {
    source: {
      code: HiddenLabelSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithInfo = args => (
  <FormGroup
    name="form-group-4"
    info={<span>Supporting text to help users complete the control</span>}
    {...args}
  >
    <InputGroup>
      <InputControl>
        <TextInput name="form-group-4" placeholder="Custom control" />
      </InputControl>
    </InputGroup>
  </FormGroup>
)

const WithInfoSourceCode = `
import { FormGroup, InputControl, InputGroup, TextInput } from '@jsluna/form'

export const WithInfo = () => (
  <FormGroup
    name="form-group-4"
    info={<span>Supporting text to help users complete the control</span>}
    label="Form group"
  >
    <InputGroup>
      <InputControl>
        <TextInput name="form-group-4" placeholder="Custom control" />
      </InputControl>
    </InputGroup>
  </FormGroup>
)
`

WithInfo.parameters = {
  docs: {
    source: {
      code: WithInfoSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithError = args => (
  <FormGroup
    name="form-group-5"
    error={<span>Please enter a valid value</span>}
    {...args}
  >
    <InputGroup>
      <InputControl>
        <TextInput name="form-group-5" placeholder="Custom control" />
      </InputControl>
    </InputGroup>
  </FormGroup>
)

const WithErrorSourceCode = `
import { FormGroup, InputControl, InputGroup, TextInput } from '@jsluna/form'



export const WithError = () => (
  <FormGroup
    name="form-group-5"
    error={<span>Please enter a valid value</span>}
    label="Form group"
  >
    <InputGroup>
      <InputControl>
        <TextInput name="form-group-5" placeholder="Custom control" />
      </InputControl>
    </InputGroup>
  </FormGroup>
)
`

WithError.parameters = {
  docs: {
    source: {
      code: WithErrorSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithWarning = args => (
  <FormGroup
    name="form-group-6"
    warning={<span>Are you sure that is correct?</span>}
    {...args}
  >
    <InputGroup>
      <InputControl>
        <TextInput name="form-group-6" placeholder="Custom control" />
      </InputControl>
    </InputGroup>
  </FormGroup>
)

const WithWarningSourceCode = `
import { FormGroup, InputControl, InputGroup, TextInput } from '@jsluna/form'

export const WithWarning = () => (
  <FormGroup
    name="form-group-6"
    warning={<span>Are you sure that is correct?</span>}
    label="Form group"
  >
    <InputGroup>
      <InputControl>
        <TextInput name="form-group-6" placeholder="Custom control" />
      </InputControl>
    </InputGroup>
  </FormGroup>
)
`

WithWarning.parameters = {
  docs: {
    source: {
      code: WithWarningSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
