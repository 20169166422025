import React from 'react'
import {
  ProgressIndicator,
  ProgressSpinner,
  ProgressBar,
  ProgressMessage,
} from '@jsluna/progress'
import { FilledButton } from '@jsluna/button'
import { Container } from '@jsluna/grid'
import { Card } from '@jsluna/card'

export default {
  title: 'Progress Indicator',
  component: ProgressIndicator,
}

export const Spinner = () => (
  <ProgressIndicator
    className="ln-u-justify-content-center"
    loading
    preventFocus
  >
    <ProgressSpinner size="icon" className="ln-u-push-right-sm" />
    <ProgressSpinner size="small" className="ln-u-push-right-sm" />
    <ProgressSpinner className="ln-u-push-right-sm" />
    <ProgressSpinner size="large" />
  </ProgressIndicator>
)

const SpinnerSourceCode = `
import {
  ProgressIndicator,
  ProgressSpinner,
} from '@jsluna/progress'

export const Spinner = () => (
  <ProgressIndicator
    className="ln-u-justify-content-center"
    loading
    preventFocus
  >
    <ProgressSpinner size="icon" className="ln-u-push-right-sm" />
    <ProgressSpinner size="small" className="ln-u-push-right-sm" />
    <ProgressSpinner className="ln-u-push-right-sm" />
    <ProgressSpinner size="large" />
  </ProgressIndicator>
)
`

Spinner.parameters = {
  docs: {
    source: {
      code: SpinnerSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Bar = () => (
  <ProgressIndicator loading preventFocus>
    <ProgressBar
      className="ln-u-push-bottom-lg"
      title="Regular sized progress indicator demo"
    />
    <ProgressBar size="small" title="Small sized progress indicator demo" />
  </ProgressIndicator>
)

const BarSourceCode = `
import {
  ProgressIndicator,
  ProgressBar,
} from '@jsluna/progress'

export const Bar = () => (
  <ProgressIndicator loading preventFocus>
    <ProgressBar
      className="ln-u-push-bottom-lg"
      title="Regular sized progress indicator demo"
    />
    <ProgressBar size="small" title="Small sized progress indicator demo" />
  </ProgressIndicator>
)
`

Bar.parameters = {
  docs: {
    source: {
      code: BarSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Determinate = () => {
  class Loading extends React.Component {
    constructor() {
      super()

      this.state = {
        loading: false,
        progress: 0,
      }

      this.start = this.start.bind(this)
      this.reset = this.reset.bind(this)
      this.increment = this.increment.bind(this)
    }

    componentWillUnmount() {
      clearTimeout(this.timeout)
    }

    increment() {
      this.timeout = setTimeout(() => {
        const { progress } = this.state
        const newProgress = Math.min(
          progress + Math.floor(Math.random(0, 1) * 10),
          100,
        )
        this.setState({ progress: newProgress })

        if (newProgress < 100) {
          this.increment()
        }
      }, Math.floor(Math.random(0, 1) * 500))
    }

    start() {
      this.setState({ loading: true })
      this.increment()
    }

    reset() {
      clearTimeout(this.timeout)
      this.setState({ progress: 0, loading: false })
    }

    render() {
      const { loading } = this.state
      return (
        <div className="ln-u-display-flex">
          <FilledButton
            onClick={() => (loading ? this.reset() : this.start())}
            className="ln-u-push-right"
          >
            {loading ? 'Reset' : 'Start'}
          </FilledButton>

          <div className="ln-u-display-flex ln-u-align-items-center ln-u-text-align-center">
            <ProgressIndicator
              value={this.state.progress}
              loading={loading && this.state.progress < 100}
            >
              <ProgressSpinner className="ln-u-margin-right" />
              Loading, {this.state.progress.toString()}% complete
            </ProgressIndicator>
            {!loading && 'Waiting to begin'}
            {this.state.progress === 100 && 'Complete'}
          </div>
        </div>
      )
    }
  }

  return <Loading />
}

const DeterminateSourceCode = `
import {
  ProgressIndicator,
  ProgressSpinner,
} from '@jsluna/progress'
import { FilledButton } from '@jsluna/button'

export const Determinate = () => {
  class Loading extends React.Component {
    constructor() {
      super()

      this.state = {
        loading: false,
        progress: 0,
      }

      this.start = this.start.bind(this)
      this.reset = this.reset.bind(this)
      this.increment = this.increment.bind(this)
    }

    componentWillUnmount() {
      clearTimeout(this.timeout)
    }

    increment() {
      this.timeout = setTimeout(() => {
        const { progress } = this.state
        const newProgress = Math.min(
          progress + Math.floor(Math.random(0, 1) * 10),
          100,
        )
        this.setState({ progress: newProgress })

        if (newProgress < 100) {
          this.increment()
        }
      }, Math.floor(Math.random(0, 1) * 500))
    }

    start() {
      this.setState({ loading: true })
      this.increment()
    }

    reset() {
      clearTimeout(this.timeout)
      this.setState({ progress: 0, loading: false })
    }

    render() {
      const { loading } = this.state
      return (
        <div className="ln-u-display-flex">
          <FilledButton
            onClick={() => (loading ? this.reset() : this.start())}
            className="ln-u-push-right"
          >
            {loading ? 'Reset' : 'Start'}
          </FilledButton>

          <div className="ln-u-display-flex ln-u-align-items-center ln-u-text-align-center">
            <ProgressIndicator
              value={this.state.progress}
              loading={loading && this.state.progress < 100}
            >
              <ProgressSpinner className="ln-u-margin-right" />
              Loading, {this.state.progress.toString()}% complete
            </ProgressIndicator>
            {!loading && 'Waiting to begin'}
            {this.state.progress === 100 && 'Complete'}
          </div>
        </div>
      )
    }
  }

  return <Loading />
}
`

Determinate.parameters = {
  docs: {
    source: {
      code: DeterminateSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Indeterminate = () => (
  <ProgressIndicator
    className="ln-u-justify-content-center"
    loading
    preventFocus
  >
    <ProgressSpinner className="ln-u-push-right-sm" />
  </ProgressIndicator>
)

const IndeterminateSourceCode = `
import {
  ProgressIndicator,
  ProgressSpinner,
} from '@jsluna/progress'

export const Indeterminate = () => (
  <ProgressIndicator
    className="ln-u-justify-content-center"
    loading
    preventFocus
  >
    <ProgressSpinner className="ln-u-push-right-sm" />
  </ProgressIndicator>
)
`

Indeterminate.parameters = {
  docs: {
    source: {
      code: IndeterminateSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const PageLoadingBar = () => (
  <div style={{ position: 'relative' }}>
    <Container size="xs" soft className="ln-u-soft-ends-lg">
      <Card>
        <h1>Lorem ipsum</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </Card>
      <ProgressIndicator
        page
        style={{ position: 'absolute', zIndex: 2 }}
        loading
        preventFocus
      >
        <ProgressBar color="light" />
        Loading...
      </ProgressIndicator>
    </Container>
  </div>
)

const PageLoadingBarSourceCode = `
import {
  ProgressIndicator,
  ProgressBar,
} from '@jsluna/progress'
import { Container } from '@jsluna/grid'
import { Card } from '@jsluna/card'

export const PageLoadingBar = () => (
  <div style={{ position: 'relative' }}>
    <Container size="xs" soft className="ln-u-soft-ends-lg">
      <Card>
        <h1>Lorem ipsum</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </Card>
      <ProgressIndicator
        page
        style={{ position: 'absolute', zIndex: 2 }}
        loading
        preventFocus
      >
        <ProgressBar color="light" />
        Loading...
      </ProgressIndicator>
    </Container>
  </div>
)
`

PageLoadingBar.parameters = {
  docs: {
    source: {
      code: PageLoadingBarSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const PageLoadingSpinner = () => (
  <div style={{ position: 'relative' }}>
    <Container size="xs" soft className="ln-u-soft-ends-lg">
      <Card>
        <h1>Lorem ipsum</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum holker st dolore eu
          fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
          sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </Card>
      <ProgressIndicator
        page
        style={{ position: 'absolute', zIndex: 2 }}
        loading
        preventFocus
      >
        <ProgressSpinner size="large" color="light" />
        Loading...
      </ProgressIndicator>
    </Container>
  </div>
)

const PageLoadingSpinnerSourceCode = `
import {
  ProgressIndicator,
  ProgressSpinner,
} from '@jsluna/progress'
import { Container } from '@jsluna/grid'
import { Card } from '@jsluna/card'

export const PageLoadingSpinner = () => (
  <div style={{ position: 'relative' }}>
    <Container size="xs" soft className="ln-u-soft-ends-lg">
      <Card>
        <h1>Lorem ipsum</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum holker st dolore eu
          fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
          sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </Card>
      <ProgressIndicator
        page
        style={{ position: 'absolute', zIndex: 2 }}
        loading
        preventFocus
      >
        <ProgressSpinner size="large" color="light" />
        Loading...
      </ProgressIndicator>
    </Container>
  </div>
)
`

PageLoadingSpinner.parameters = {
  docs: {
    source: {
      code: PageLoadingSpinnerSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const InsideComponent = () => {
  class Loading extends React.Component {
    constructor() {
      super()

      this.state = {
        loading: false,
        progress: 0,
      }

      this.start = this.start.bind(this)
      this.reset = this.reset.bind(this)
      this.increment = this.increment.bind(this)
    }
    componentWillUnmount() {
      clearTimeout(this.timeout)
    }

    increment() {
      this.timeout = setTimeout(() => {
        const { progress } = this.state
        const newProgress = Math.min(
          progress + Math.floor(Math.random(0, 1) * 10),
          100,
        )
        this.setState({ progress: newProgress })

        if (newProgress < 100) {
          this.increment()
        } else {
          this.reset()
        }
      }, Math.floor(Math.random(0, 1) * 500))
    }

    start() {
      this.setState({ loading: true })
      this.increment()
    }

    reset() {
      clearTimeout(this.timeout)
      this.setState({ progress: 0, loading: false })
    }

    render() {
      const { loading } = this.state
      return (
        <div className="ln-o-container">
          <FilledButton
            onClick={() => !loading && this.start()}
            disabled={loading}
            aria-live="assertive"
          >
            {loading && (
              <ProgressIndicator
                loading
                preventFocus
                message={<ProgressMessage>Logging in</ProgressMessage>}
              >
                <ProgressSpinner
                  color="light"
                  size="small"
                  className="ln-u-margin-right"
                />
              </ProgressIndicator>
            )}
            {!loading && 'Log in'}
          </FilledButton>
        </div>
      )
    }
  }
  return <Loading />
}

const InsideComponentSourceCode = `
import {
  ProgressIndicator,
  ProgressSpinner,
  ProgressMessage,
} from '@jsluna/progress'
import { FilledButton } from '@jsluna/button'

export const InsideComponent = () => {
  class Loading extends React.Component {
    constructor() {
      super()

      this.state = {
        loading: false,
        progress: 0,
      }

      this.start = this.start.bind(this)
      this.reset = this.reset.bind(this)
      this.increment = this.increment.bind(this)
    }
    componentWillUnmount() {
      clearTimeout(this.timeout)
    }

    increment() {
      this.timeout = setTimeout(() => {
        const { progress } = this.state
        const newProgress = Math.min(
          progress + Math.floor(Math.random(0, 1) * 10),
          100,
        )
        this.setState({ progress: newProgress })

        if (newProgress < 100) {
          this.increment()
        } else {
          this.reset()
        }
      }, Math.floor(Math.random(0, 1) * 500))
    }

    start() {
      this.setState({ loading: true })
      this.increment()
    }

    reset() {
      clearTimeout(this.timeout)
      this.setState({ progress: 0, loading: false })
    }

    render() {
      const { loading } = this.state
      return (
        <div className="ln-o-container">
          <FilledButton
            onClick={() => !loading && this.start()}
            disabled={loading}
            aria-live="assertive"
          >
            {loading && (
              <ProgressIndicator
                loading
                preventFocus
                message={<ProgressMessage>Logging in</ProgressMessage>}
              >
                <ProgressSpinner
                  color="light"
                  size="small"
                  className="ln-u-margin-right"
                />
              </ProgressIndicator>
            )}
            {!loading && 'Log in'}
          </FilledButton>
        </div>
      )
    }
  }
  return <Loading />
}
`

InsideComponent.parameters = {
  docs: {
    source: {
      code: InsideComponentSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
