import React from 'react'

import { GridWrapper, Accordion, AccordionItem, Link } from '@jsluna/react'

import CodeSnippet from './CodeSnippet'

const ColourContrast = () => (
  <section>
    <h2>Check your colour contrast</h2>

    <p>
      Use colours with enough contrast that all users can easily distinguish
      content.
    </p>

    <Accordion standalone>
      <AccordionItem title="Why does colour contrast matter and how is it measured?">
        <p>Colour contrast is important because:</p>
        <ul>
          <li>
            the more defined content is, the less effort and time it takes to
            understand
          </li>
          <li>
            Other factors can affect contrast, like the visual acuity of the
            user, the size of content and glare from external light sources
          </li>
          <li>
            using colours that have a higher contrast over less contrasting
            alternatives will benefit all users
          </li>
        </ul>

        <p>
          Colour contrast is measured as the ratio of the luminance of the
          brightest colour (white) to that of the darkest colour (black) in the
          range of adjacent colours.
        </p>

        <p>
          The{' '}
          <Link href="https://webaim.org/resources/contrastchecker/">
            WebAIM colour contrast checker
          </Link>{' '}
          is one of many tools available that can be used to check colour
          contrast.
        </p>

        <h3>W3C Web Content Accessibility Guideline requirements:</h3>

        <ul>
          <li>
            <Link href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html">
              Understanding Success Criterion 1.4.3: Contrast (Minimum)
            </Link>
          </li>
          <li>
            <Link href="https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html">
              Understanding Success Criterion 1.4.11: Non-text Contrast
            </Link>
          </li>
        </ul>
      </AccordionItem>
    </Accordion>

    <h3>Colour contrast for text</h3>

    <p>
      Foreground text should have a contrast ratio of 3:1 for large text and
      4.5:1 for normal text against the background colour.
    </p>

    <GridWrapper equalHeight>
      <CodeSnippet
        type="positive"
        codeFormat="css"
        code={`
          color: #404040;
          background-color: #FFFFFF;
        `}
        component={
          <h5 style={{ color: '#404040', fontSize: '1.2rem' }}>
            This text has a contrast of 10.36:1
          </h5>
        }
      />
    </GridWrapper>

    <h3>Colour contrast for UI controls</h3>

    <p>
      User interface components, like buttons, should have a contrast ratio of
      at least 3:1 between adjacent colours.
    </p>
    <p>
      The first example uses a dark grey (#737373) button on a white background.
      It has a colour contrast ratio of 4.74:1.
    </p>
    <p>
      The second example uses a light grey (#D8D8D8) button on a white
      background. With a contrast ratio of 1.42:1, it fails the colour contrast
      minimum of 3:1.
    </p>

    <GridWrapper equalHeight>
      <CodeSnippet
        type="positive"
        codeFormat="css"
        code={`
          button { background-color: #737373; }
          body { background-color: #FFFFFF; }
        `}
        component={
          <div style={{ backgroundColor: '#737373', padding: '1rem' }}>
            &nbsp;
          </div>
        }
      />
      <CodeSnippet
        type="negative"
        codeFormat="css"
        code={`
          button { background-color: #D8D8D8; }
          body { background-color: #FFFFFF; }
        `}
        component={
          <div style={{ backgroundColor: '#D8D8D8', padding: '1rem' }}>
            &nbsp;
          </div>
        }
      />
    </GridWrapper>
  </section>
)

export default ColourContrast
