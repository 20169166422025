import React from 'react'
import { Display5, Body1 } from '@jsluna/typography'
import { TableContainer, TableBody } from '@jsluna/table'

import { useQueryAllTokens } from './useQueryAllTokens'
import ColourBreadcrumbs from './ColourBreadcrumbs'
import Breadcrumbs from './Breadcrumbs'
import TokenTableRows from './TokenTableRows'
import ColourTableHeader from './ColourTableHeader'
import TableHeader from './TableHeader'

import TypographyToken from './TypographyToken'

const getTokenType = type => {
  switch (type) {
    case 'colours':
      return 'color'
    case 'modifiers':
      return 'modifier'
    default:
      return type
  }
}

const TokensLookup = ({
  title,
  tagline,
  brand,
  tokens,
  type,
  token,
  tokenItem,
  suffix,
  display,
  removeBottomBorder,
}) => {
  const { data } = useQueryAllTokens()

  const allTokens = data?.find(datum => datum.type === type)[type]

  const brandTokens = allTokens?.find(brandNode => brandNode.name === brand)

  if (type === 'typography' && token && tokenItem && suffix) {
    return (
      <TypographyToken
        token={token}
        tokenItem={tokenItem}
        typographyTokens={brandTokens}
        suffix={suffix}
        display={display}
        brand={brand}
      />
    )
  }

  const colourTokenTitle =
    type === 'colours' && title === 'Brand' ? 'Palette' : title

  const modifiersTokenTitle =
    type === 'modifiers' && brand === 'Tu' && title === 'Border'
      ? 'border_modifiers'
      : title

  const tokenTitle = type !== 'colours' ? modifiersTokenTitle : colourTokenTitle

  const tokenType = getTokenType(type)

  const selectTokens =
    brandTokens[tokenType][tokenTitle.toLowerCase().replace(' ', '_')]

  const dataKeys = Object.keys(selectTokens)

  const colourKeys =
    tokens ||
    dataKeys.filter(key =>
      (title === 'Monochrome' && key.includes('rgb')) || title === 'Brand'
        ? key.startsWith('brand')
        : !key.startsWith('brand'),
    )

  const tokenKeys = tokens || dataKeys

  return (
    <div
      className={`c-tokens-lookup ${
        !removeBottomBorder ? 'c-tokens-lookup--border-bottom' : ''
      }`}
    >
      <Display5 element="h2" className="c-table--title">
        {title}
      </Display5>
      {tokenType === 'color' ? (
        <ColourBreadcrumbs title={title} brand={brand} />
      ) : (
        <Breadcrumbs title={title} type={type} />
      )}
      <Body1 element="p">{tagline}</Body1>
      <TableContainer className="c-table--container">
        {tokenType === 'color' ? <ColourTableHeader /> : <TableHeader />}
        <TableBody>
          <TokenTableRows
            title={tokenType === 'color' ? colourTokenTitle : tokenTitle}
            tokens={tokenType === 'color' ? colourKeys : tokenKeys}
            selectTokens={selectTokens}
            type={tokenType}
          />
        </TableBody>
      </TableContainer>
    </div>
  )
}

export default TokensLookup
