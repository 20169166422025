import React from 'react'
import { Display4 } from "@jsluna/typography"

const TableOfContents = ({ title, children }) => (
  <div className="c-toc">
    <div className="c-toc--container">
    {title && <Display4 element="h2">{title}</Display4>}
    {children}
    </div>
  </div>
)

export default TableOfContents
