import React from 'react'
import { Switch } from '@jsluna/form'

export default {
  title: 'Switch',
  component: Switch,
}

export const Transparent = () => (
  <Switch
    name="transparent-switch"
    label="Transparent switch"
    defaultChecked
    fullWidth
  />
)

const TransparentSourceCode = `
import { Switch } from '@jsluna/form'

export const Transparent = () => (
  <Switch
    name="transparent-switch"
    label="Transparent switch"
    defaultChecked
    fullWidth
  />
)
`

Transparent.parameters = {
  docs: {
    source: {
      code: TransparentSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Outlined = () => (
  <Switch
    name="outlined-switch"
    label="Outlined switch"
    defaultChecked
    fullWidth
    outlined
  />
)

const OutlinedSourceCode = `
import { Switch } from '@jsluna/form'

export const Outlined = () => (
  <Switch
    name="outlined-switch"
    label="Outlined switch"
    defaultChecked
    fullWidth
    outlined
  />
)
`

Outlined.parameters = {
  docs: {
    source: {
      code: OutlinedSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithText = () => (
  <Switch name="switch-with-text" label="Switch with text" fullWidth />
)

const WithTextSourceCode = `
import { Switch } from '@jsluna/form'

export const WithText = () => (
  <Switch name="switch-with-text" label="Switch with text" fullWidth />
)
`

WithText.parameters = {
  docs: {
    source: {
      code: WithTextSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Standalone = () => (
  <React.Fragment>
    <Switch name="switch-with-hidden-label" label="Hidden label" hideLabel />
    <Switch
      name="switch-with-hidden-label-checked"
      label="Hidden label (default checked)"
      defaultChecked
      hideLabel
    />
  </React.Fragment>
)

const StandaloneSourceCode = `
import { Switch } from '@jsluna/form'

export const Standalone = () => (
  <React.Fragment>
    <Switch name="switch-with-hidden-label" label="Hidden label" hideLabel />
    <Switch
      name="switch-with-hidden-label-checked"
      label="Hidden label (default checked)"
      defaultChecked
      hideLabel
    />
  </React.Fragment>
)
`

Standalone.parameters = {
  docs: {
    source: {
      code: StandaloneSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
