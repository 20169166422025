import React from 'react'
import contrast from 'contrast'
import Card from '@components/card'

const ColourBlock = ({ children, center, fill }) => {
  const styles = {
    backgroundColor: fill,
    textAlign: center && `center`,
    color: contrast(fill) === 'dark' ? '#fff' : '#000',
    border:
      contrast(fill) === 'light'
        ? `1px solid var(--ds-mode-default-border)`
        : `none`,
  }
  return (
    <Card style={styles} className="c-card c-colour-block">
      <strong>{children}</strong>
      <span className="o-easy-select ln-u-display-block">{fill}</span>
    </Card>
  )
}

export default ColourBlock
