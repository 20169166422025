import React from 'react'
import { TableRow, TableCell } from '@jsluna/table'

import { formatTableText, responsiveScreenSizes } from '@utils/formatTokenTable'

import TokensLookup from '@components/tokens-lookup'

const typography = [ 'display-7', 'display-6', 'display-5', 'display-4', 'display-3', 'display-2', 'display-1', 'label-2', 'label-1', 'body-2', 'body-1', 'caption']

export const HabitatTypographyTableBody = () => {
  return (
    <>
      {typography.map((type) => {
        return (
          <TableRow>
            <TableCell>{formatTableText(type)}</TableCell>
            <TableCell>
              <TokensLookup
                type="typography"
                brand="Habitat"
                token={type}
                tokenItem="font_family"
                suffix="px"
                display={type}
              />
            </TableCell>
            <TableCell>
              <TokensLookup
                type="typography"
                brand="Habitat"
                token={type}
                tokenItem="font_size"
                suffix="px"
              />
            </TableCell>
            <TableCell>
              <TokensLookup
                type="typography"
                brand="Habitat"
                token={type}
                tokenItem="line_height"
                suffix="px"
              />
            </TableCell>
            <TableCell>{responsiveScreenSizes(type)}</TableCell>
        </TableRow>
        )
      })}
    </>
  )
}

export default HabitatTypographyTableBody
