import React from 'react'
import { Card } from '@jsluna/card'

const CardSourceCode = `
import { Card } from '@jsluna/card'

export const Standard = () => (
  <Card>
    <h3>Card title</h3>
    <p>Hello from Luna</p>
  </Card>
)
`

export default {
  title: 'Card',
  component: Card,
  parameters: {
    docs: {
      source: {
        code: CardSourceCode,
        language: 'jsx',
        type: 'auto',
      },
    },
  },
}

export const Standard = () => (
  <Card>
    <h3>Card title</h3>
    <p>Hello from Luna</p>
  </Card>
)
