import React, { Fragment, useRef, useMemo, useState, useContext } from 'react'
import { LiveProvider, LiveEditor, LiveError, LiveContext } from 'react-live'
import Highlight, { Prism } from 'prism-react-renderer'
import prismTheme from 'prism-react-renderer/themes/dracula'
import { Tabs, TabLink, TabPanel } from '@jsluna/tabs'
import prettier from 'prettier'
import parserHtml from 'prettier/esm/parser-html.mjs'

import { ExamplesThemeContext } from '@utils/theme'
import { LivePreview } from 'react-live'
import Card from '@components/card'

const HTMLPreviewContainer = ({ children }) => (
  <div className="c-html-wrapper">{children}</div>
)
const HTMLPreviewPre = ({ children }) => <pre>{children}</pre>
const LiveEditorTabContent = ({ children, visible }) => (
  <div style={{ display: visible ? 'block' : 'none' }}>{children}</div>
)
const TabContent = ({ children, visible }) => (
  <div className={!visible && 'ln-u-hidden'}>{children}</div>
)
const TabsWrapper = ({ children }) => <div>{children}</div>

const escapeRegExp = string => string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')

const replaceAll = (str, find, replace) =>
  str.replace(new RegExp(escapeRegExp(find), 'g'), replace)

const argToProp = (arg, val) => {
  if (typeof val === 'string') {
    return `${arg}="${val}"`
  }

  if (val === true) {
    return `${arg}`
  }

  return `${arg}={${val.toString()}}`
}

const argsToProps = args => {
  const props = []

  for (const arg in args) {
    props.push(argToProp(arg, args[arg]))
  }

  if (props.length) {
    return ` ${props.join(' ')}`
  }

  return ''
}

const getFullComponentName = (storiesDefault, component) => {
  // split component by capitalised pascalcase characters and __ to create componentName
  const componentName = component.split(/(?=[A-Z])+|[\s__]/).join(' ')

  return `${componentName} ${storiesDefault.name || storiesDefault.title}`
}

const Example = ({ stories, component, stretch, darkBackground, inline }) => {
  const { theme, hasThemeSelector } = useContext(ExamplesThemeContext)

  const [activeTab, setActiveTab] = useState('')
  const exampleRef = useRef()

  const stretchStyle = stretch ? { flex: 1 } : undefined
  const inlineStyle = inline
    ? {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
      }
    : undefined

  const cardStyle = {
    backgroundColor: darkBackground
      ? 'var(--ds-color-monochrome-dark)'
      : 'var(--ds-color-monochrome-white)',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    borderBottom: 'none',
    marginBottom: 'var(--ds-space-size-small)',
  }

  const story = stories[component]

  const { code, scope } = useMemo(() => {
    // see src/babel/story.plugin.js for these properties
    const scope = story.__lnScope ? story.__lnScope : {}
    let code = story.__lnSource ? story.__lnSource : ''
    code = replaceAll(
      code,
      ' {...args}',
      argsToProps({
        ...stories.default.args,
        ...story.args,
      }),
    )

    return { code, scope }
  }, [story])

  // use the Storybook background parameter to work out if we should display on a dark bg
  const background = story?.parameters?.backgrounds?.default

  const fullComponentName = getFullComponentName(stories.default, component)

  return (
    <Card
      background={background}
      aria-label={`${fullComponentName} Component demo`}
      hard={true}
      style={{ ...cardStyle }}
      className="c-example"
    >
      <ul className="ln-o-inline-list c-example--tags">
        <li
          className={`ln-o-inline-list__item ln-u-padding ${
            darkBackground ? 'ln-u-color-white' : ''
          }`}
        >
          {hasThemeSelector && `Interactive demo - ${theme.punctualName}`}
        </li>
      </ul>

      <LiveProvider code={code} scope={scope} language="jsx" theme={prismTheme}>
        <LiveError />

        <div
          ref={exampleRef}
          className={`c-example--body ds-theme--${theme.name}`}
        >
          <LivePreview
            style={{ ...stretchStyle, ...inlineStyle }}
            className="ln-u-padding-ends*4 ln-u-padding-sides*4"
          />
        </div>

        <TabsWrapper>
          <Tabs fill={true}>
            <TabLink
              onClick={() => setActiveTab('react')}
              active={activeTab === 'react'}
            >
              React editor
            </TabLink>
            <TabLink
              onClick={() => {
                setActiveTab('html')
              }}
              active={activeTab === 'html'}
            >
              HTML
            </TabLink>
          </Tabs>
          <TabPanel>
            {/* The editor needs to stay in the DOM so hide it with CSS */}
            <LiveEditorTabContent visible={activeTab === 'react'}>
              <LiveEditor />
            </LiveEditorTabContent>
            <TabContent visible={activeTab === 'html'}>
              <HTMLPreview preview={exampleRef} />
            </TabContent>
          </TabPanel>
        </TabsWrapper>
      </LiveProvider>
    </Card>
  )
}

// this is essentially copied from react-live to give us the same highlighting on the HTML tab
const highlightCode = (code, theme, language) => (
  <Highlight Prism={Prism} code={code} theme={theme} language={language}>
    {({ tokens, getLineProps, getTokenProps }) => (
      <Fragment>
        {tokens.map((line, i) => (
          // eslint-disable-next-line react/jsx-key
          <div {...getLineProps({ line, key: i })}>
            {line.map((token, key) => (
              // eslint-disable-next-line react/jsx-key
              <span {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}
      </Fragment>
    )}
  </Highlight>
)

// see https://prettier.io/docs/en/browser.html
const format = code =>
  prettier
    .format(code, {
      parser: 'html',
      // @ts-ignore
      plugins: [parserHtml],
      htmlWhitespaceSensitivity: 'ignore',
    })
    .trim()

const HTMLPreview = ({ preview }) => {
  if (!preview.current) {
    return null
  }

  return (
    <LiveContext.Consumer>
      {context => {
        const markup = format(preview.current.firstChild.innerHTML)

        return (
          <HTMLPreviewContainer>
            <HTMLPreviewPre>
              {highlightCode(markup, context.theme, context.language)}
            </HTMLPreviewPre>
          </HTMLPreviewContainer>
        )
      }}
    </LiveContext.Consumer>
  )
}

export default Example
