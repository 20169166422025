import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import {
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
} from '@jsluna/button'
import { Section } from '@jsluna/section'

import Button from '../button'
import MarkdownLink from '../markdown-link'
import GridList, { GridListItem } from '../grid-list'
import Example from '../example'
import GetInTouch from '../get-in-touch'
import {
  Tabs,
  Tab,
  TableTab,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from '../tabs'

import { TableCellResult } from '../design-tokens'

import links from '@utils/externalLinks'
import TableOfContents from '@components/table-of-contents'
import { Guideline, Do, Dont } from '@components/guideline'

const Partial = ({ children }) => {
  return (
    <MDXProvider
      components={{
        a: MarkdownLink,
        Button,
        ButtonGroupWrapper,
        ButtonGroupPrimary,
        ButtonGroupSecondary,
        GridList,
        GridListItem,
        links,
        TableOfContents,
        Guideline,
        Do,
        Dont,
        Example,
        GetInTouch,
        Section,
        Tabs,
        Tab,
        TableTab,
        TableHeader,
        TableBody,
        TableRow,
        TableCell,
        TableCellResult,
      }}
    >
      {children}
    </MDXProvider>
  )
}

export default Partial
