import React from 'react'

import {
  GridWrapper,
  Button,
  Accordion,
  AccordionItem,
  Link,
} from '@jsluna/react'

import CodeSnippet from './CodeSnippet'

const KeyboardTasks = () => (
  <section>
    <h2>Make sure all tasks can be completed with a keyboard</h2>

    <p>
      All elements that the user needs to interact with to complete a task must
      be keyboard accessible.
    </p>

    <Accordion standalone>
      <AccordionItem title="Why is keyboard accessibility important?">
        <p>Keyboard accessibility is important for:</p>

        <ul>
          <li>
            people with dexterity or mobility impairments using a keyboard
            instead of a mouse
          </li>
          <li>
            people who are blind or visually impaired using a screen reader that
            cannot use devices requiring eye-hand coordination, like mice
          </li>
          <li>
            people using alternate keyboards or input devices that act as
            keyboard emulators, like speech input software, sip-and-puff
            software and more
          </li>
        </ul>

        <h3>W3C Web Content Accessibility Guideline requirements:</h3>

        <ul>
          <li>
            <Link href="https://www.w3.org/WAI/WCAG21/Understanding/keyboard.html">
              Understanding Success Criterion 2.1.1: Keyboard
            </Link>
          </li>
          <li>
            <Link href="https://www.w3.org/WAI/WCAG21/Understanding/no-keyboard-trap.html">
              Understanding Success Criterion 2.1.2: No Keyboard Trap
            </Link>
          </li>
        </ul>
      </AccordionItem>
    </Accordion>

    <GridWrapper equalHeight>
      <CodeSnippet
        type="positive"
        component={
          <Button variant="outlined" fullWidth>
            This is keyboard accessible
          </Button>
        }
      />
      <CodeSnippet
        type="negative"
        component={
          <div className="ln-c-button ln-c-button--outlined ln-c-button--full">
            Not keyboard accessible
          </div>
        }
      />
    </GridWrapper>
  </section>
)

export default KeyboardTasks
