import React from 'react'
import { GridWrapper, GridItem } from '@jsluna/grid'

export default {
  title: 'Grid',
  component: GridWrapper,
}

export const FractionalGrid = () => {
  const sizes = [
    '1/1',
    '1/2',
    '1/3',
    '1/6',
    '1/10',
    '1/5',
    '3/5',
    '1/10',
    '1/8',
    '1/4',
    '1/2',
    '1/8',
    '3/12',
    '5/12',
    '1/3',
  ]
  return (
    <GridWrapper matrix element="ul">
      {sizes.map((size, index) => (
        <GridItem key={index} size={size} element="li">
          <div
            className={`ln-u-text-align-center ln-u-soft ${
              index % 2 === 0
                ? 'ln-u-bg-color-light'
                : 'ln-u-bg-color-medium ln-u-color-white'
            }`}
          >
            {size}
          </div>
        </GridItem>
      ))}
    </GridWrapper>
  )
}

const FractionalGridSourceCode = `
import { GridWrapper, GridItem } from '@jsluna/grid'

export const FractionalGrid = () => {
  const sizes = [
    '1/1',
    '1/2',
    '1/3',
    '1/6',
    '1/10',
    '1/5',
    '3/5',
    '1/10',
    '1/8',
    '1/4',
    '1/2',
    '1/8',
    '3/12',
    '5/12',
    '1/3',
  ]

  return (
    <GridWrapper matrix element="ul">
      {sizes.map((size, index) => (
        <GridItem key={index} size={size} element="li">
          <div
            className={\`ln-u-text-align-center ln-u-soft \${
              index % 2 === 0
                ? 'ln-u-bg-color-light'
                : 'ln-u-bg-color-medium ln-u-color-white'
            }\`}
          >
            {size}
          </div>
        </GridItem>
      ))}
    </GridWrapper>
  )
}
`

FractionalGrid.parameters = {
  docs: {
    source: {
      code: FractionalGridSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Responsive = () => (
  <GridWrapper matrix>
    <GridItem size={{ xs: '1/2' }}>
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-light">
        1/2 @ extra small
      </div>
    </GridItem>
    <GridItem size={{ sm: '1/2' }}>
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-medium ln-u-color-white">
        1/2 @ small
      </div>
    </GridItem>
    <GridItem size={{ md: '1/2' }}>
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-light">
        1/2 @ medium
      </div>
    </GridItem>
    <GridItem size={{ lg: '1/2' }}>
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-medium ln-u-color-white">
        1/2 @ large
      </div>
    </GridItem>
    <GridItem
      size={{
        xs: '4/5',
        sm: '3/5',
        md: '2/5',
        lg: '1/5',
      }}
    >
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-light">
        Multiple breakpoints
      </div>
    </GridItem>
  </GridWrapper>
)

const ResponsiveSourceCode = `
import { GridWrapper, GridItem } from '@jsluna/grid'


export const Responsive = () => (
  <GridWrapper matrix>
    <GridItem size={{ xs: '1/2' }}>
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-light">
        1/2 @ extra small
      </div>
    </GridItem>
    <GridItem size={{ sm: '1/2' }}>
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-medium ln-u-color-white">
        1/2 @ small
      </div>
    </GridItem>
    <GridItem size={{ md: '1/2' }}>
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-light">
        1/2 @ medium
      </div>
    </GridItem>
    <GridItem size={{ lg: '1/2' }}>
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-medium ln-u-color-white">
        1/2 @ large
      </div>
    </GridItem>
    <GridItem
      size={{
        xs: '4/5',
        sm: '3/5',
        md: '2/5',
        lg: '1/5',
      }}
    >
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-light">
        Multiple breakpoints
      </div>
    </GridItem>
  </GridWrapper>
)
`

Responsive.parameters = {
  docs: {
    source: {
      code: ResponsiveSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const EqualHeightColumns = () => (
  <GridWrapper equalHeight>
    <GridItem size="1/2">
      <div className="ln-u-soft ln-u-bg-color-light">
        Lorem ipsum dolor sit amet...
      </div>
    </GridItem>
    <GridItem size="1/2">
      <div className="ln-u-soft ln-u-bg-color-medium ln-u-color-white">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque
        perspiciatis iusto, quisquam doloremque dolore sed quis quasi ipsa
        dolorum quam incidunt excepturi consequatur dignissimos, veritatis
        cupiditate recusandae accusamus exercitationem ipsum ea. Consequatur,
        eos, autem! Excepturi dolorum voluptatibus, quis, soluta autem facere
        dolor beatae atque, ab modi sapiente mollitia incidunt labore.
      </div>
    </GridItem>
  </GridWrapper>
)

const EqualHeightColumnsSourceCode = `
import { GridWrapper, GridItem } from '@jsluna/grid'

export const EqualHeightColumns = () => (
  <GridWrapper equalHeight>
    <GridItem size="1/2">
      <div className="ln-u-soft ln-u-bg-color-light">
        Lorem ipsum dolor sit amet...
      </div>
    </GridItem>
    <GridItem size="1/2">
      <div className="ln-u-soft ln-u-bg-color-medium ln-u-color-white">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque
        perspiciatis iusto, quisquam doloremque dolore sed quis quasi ipsa
        dolorum quam incidunt excepturi consequatur dignissimos, veritatis
        cupiditate recusandae accusamus exercitationem ipsum ea. Consequatur,
        eos, autem! Excepturi dolorum voluptatibus, quis, soluta autem facere
        dolor beatae atque, ab modi sapiente mollitia incidunt labore.
      </div>
    </GridItem>
  </GridWrapper>
)
`

EqualHeightColumns.parameters = {
  docs: {
    source: {
      code: EqualHeightColumnsSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const VerticalAlignment = () => (
  <GridWrapper verticalAlign="middle" className="ln-u-push-bottom">
    <GridItem size="1/2">
      <div className="ln-u-soft ln-u-bg-color-light">
        Lorem ipsum dolor sit amet...
      </div>
    </GridItem>
    <GridItem size="1/2">
      <div className="ln-u-soft ln-u-bg-color-medium ln-u-color-white">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque
        perspiciatis iusto, quisquam doloremque dolore sed quis quasi ipsa
        dolorum quam incidunt excepturi consequatur dignissimos, veritatis
        cupiditate recusandae accusamus exercitationem ipsum ea. Consequatur,
        eos, autem! Excepturi dolorum voluptatibus, quis, soluta autem facere
        dolor beatae atque, ab modi sapiente mollitia incidunt labore.
      </div>
    </GridItem>
  </GridWrapper>
)

const VerticalAlignmentSourceCode = `
import { GridWrapper, GridItem } from '@jsluna/grid'

export const VerticalAlignment = () => (
  <GridWrapper verticalAlign="middle" className="ln-u-push-bottom">
    <GridItem size="1/2">
      <div className="ln-u-soft ln-u-bg-color-light">
        Lorem ipsum dolor sit amet...
      </div>
    </GridItem>
    <GridItem size="1/2">
      <div className="ln-u-soft ln-u-bg-color-medium ln-u-color-white">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque
        perspiciatis iusto, quisquam doloremque dolore sed quis quasi ipsa
        dolorum quam incidunt excepturi consequatur dignissimos, veritatis
        cupiditate recusandae accusamus exercitationem ipsum ea. Consequatur,
        eos, autem! Excepturi dolorum voluptatibus, quis, soluta autem facere
        dolor beatae atque, ab modi sapiente mollitia incidunt labore.
      </div>
    </GridItem>
  </GridWrapper>
)
`

VerticalAlignment.parameters = {
  docs: {
    source: {
      code: VerticalAlignmentSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const HorizontalAlignment = () => (
  <GridWrapper horizontalAlign="right">
    <GridItem size="1/4">
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-light">
        One
      </div>
    </GridItem>
    <GridItem size="1/4">
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-medium ln-u-color-white">
        Two
      </div>
    </GridItem>
  </GridWrapper>
)

const HorizontalAlignmentSourceCode = `
import { GridWrapper, GridItem } from '@jsluna/grid'

export const HorizontalAlignment = () => (
  <GridWrapper horizontalAlign="right">
    <GridItem size="1/4">
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-light">
        One
      </div>
    </GridItem>
    <GridItem size="1/4">
      <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-medium ln-u-color-white">
        Two
      </div>
    </GridItem>
  </GridWrapper>
)
`

HorizontalAlignment.parameters = {
  docs: {
    source: {
      code: HorizontalAlignmentSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const PositionOffset = () => (
  <>
    <GridWrapper horizontalAlign="center" className="ln-u-push-bottom">
      <GridItem size="1/4" offsetLeft={{ sm: '1/10', md: '2/10' }}>
        <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-light">
          Left offset
        </div>
      </GridItem>
    </GridWrapper>
    <GridWrapper horizontalAlign="center">
      <GridItem size="1/4" offsetRight={{ sm: '1/10', md: '2/10' }}>
        <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-medium ln-u-color-white">
          Right offset
        </div>
      </GridItem>
    </GridWrapper>
  </>
)

const PositionOffsetSourceCode = `
import { GridWrapper, GridItem } from '@jsluna/grid'

export const PositionOffset = () => (
  <>
    <GridWrapper horizontalAlign="center" className="ln-u-push-bottom">
      <GridItem size="1/4" offsetLeft={{ sm: '1/10', md: '2/10' }}>
        <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-light">
          Left offset
        </div>
      </GridItem>
    </GridWrapper>
    <GridWrapper horizontalAlign="center">
      <GridItem size="1/4" offsetRight={{ sm: '1/10', md: '2/10' }}>
        <div className="ln-u-text-align-center ln-u-soft ln-u-bg-color-medium ln-u-color-white">
          Right offset
        </div>
      </GridItem>
    </GridWrapper>
  </>
)
`

PositionOffset.parameters = {
  docs: {
    source: {
      code: PositionOffsetSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
