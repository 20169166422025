import React from 'react'
import { Footer, FooterNav } from '@jsluna/footer'
import {
  Container,
  GridWrapper,
  FlagWrapper,
  FlagBody,
  FlagComponent,
} from '@jsluna/grid'
import { Accordion, AccordionItem } from '@jsluna/accordion'
import { ListGroup, ListGroupItem } from '@jsluna/list'

export default {
  title: 'Footer',
  component: Footer,
}

export const Standard = () => {
  const links = [
    'Privacy hub',
    'Privacy policy',
    'Cookies policy',
    'Accessibility',
    'Terms of use',
  ]
  return (
    <Footer className="ln-u-soft-ends-xl">
      <FooterNav>
        <Container>
          <GridWrapper gutterSize="zero">
            <Accordion
              multipleOpen
              titleElement={({ className, ...props }) => (
                <h4
                  className={`${className} ln-u-button ln-u-padding-left*2`}
                  {...props}
                >
                  Footer
                </h4>
              )}
              respondAt="sm"
              color="light"
            >
              <AccordionItem
                id="help"
                title="Help & FAQs"
                className="ln-o-grid__item ln-u-1/4@sm"
              >
                <ListGroup
                  actionable
                  color="light"
                  className="ln-u-padding-right*2"
                >
                  <ListGroupItem element="a" href="#">
                    Help centre
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Contact us
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Store locator
                  </ListGroupItem>
                </ListGroup>
              </AccordionItem>
              <AccordionItem
                id="company"
                title="Company"
                className="ln-o-grid__item ln-u-1/4@sm"
              >
                <ListGroup
                  actionable
                  color="light"
                  className="ln-u-padding-right*2"
                >
                  <ListGroupItem element="a" href="#">
                    About Sainsbury&rsquo;s
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Local charity partnership
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Business gift cards
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Sainsbury&rsquo;s jobs
                  </ListGroupItem>
                </ListGroup>
              </AccordionItem>
              <AccordionItem
                id="shop"
                title="Shop with us"
                className="ln-o-grid__item ln-u-1/4@sm"
              >
                <ListGroup
                  actionable
                  color="light"
                  className="ln-u-padding-right*2"
                >
                  <ListGroupItem element="a" href="#">
                    Groceries
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Tu Clothing
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Sainsbury&rsquo;s Bank
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Argos
                  </ListGroupItem>
                </ListGroup>
              </AccordionItem>
              <AccordionItem
                id="contact"
                title="Tell us what you think"
                className="ln-o-grid__item ln-u-1/4@sm"
              >
                <ListGroup
                  actionable
                  color="light"
                  className="ln-u-padding-right*2"
                >
                  <ListGroupItem element="a" href="#">
                    About shopping in-store
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    About shopping online
                  </ListGroupItem>
                </ListGroup>
              </AccordionItem>
            </Accordion>
          </GridWrapper>
        </Container>
      </FooterNav>
      <Container>
        <hr className="ln-u-push-ends-xl" />

        <FlagWrapper respondAt="sm" className="ln-u-caption">
          <FlagBody className="ln-u-push-right-lg">
            &copy; J Sainsbury plc {new Date().getFullYear()}
          </FlagBody>
          <FlagComponent alignment="top">
            <div className="ln-u-display-flex ln-u-flex-wrap">
              {links.map((link, index) => (
                <span
                  className="ln-u-display-flex ln-u-align-items-center ln-u-text-align-center"
                  key={link}
                >
                  <a href="#link" className="ln-o-bare-link">
                    {link}
                  </a>
                  {index !== links.length - 1 && (
                    <span className="ln-u-push-sides-sm">|</span>
                  )}
                </span>
              ))}
            </div>
          </FlagComponent>
        </FlagWrapper>
      </Container>
    </Footer>
  )
}

const StandardSourceCode = `
import { Footer, FooterNav } from '@jsluna/footer'
import {
  Container,
  GridWrapper,
  FlagWrapper,
  FlagBody,
  FlagComponent,
} from '@jsluna/grid'
import { Accordion, AccordionItem } from '@jsluna/accordion'
import { ListGroup, ListGroupItem } from '@jsluna/list'

export const Standard = () => {
  const links = [
    'Privacy hub',
    'Privacy policy',
    'Cookies policy',
    'Accessibility',
    'Terms of use',
  ]

  return (
    <Footer className="ln-u-soft-ends-xl">
      <FooterNav>
        <Container>
          <GridWrapper gutterSize="zero">
            <Accordion
              multipleOpen
              titleElement={({ className, ...props }) => (
                <h4
                  className={\`\${className} ln-u-button ln-u-padding-left*2'\`}
                  {...props}
                >
                  Footer
                </h4>
              )}
              respondAt="sm"
              color="light"
            >
              <AccordionItem
                id="help"
                title="Help & FAQs"
                className="ln-o-grid__item ln-u-1/4@sm"
              >
                <ListGroup
                  actionable
                  color="light"
                  className="ln-u-padding-right*2"
                >
                  <ListGroupItem element="a" href="#">
                    Help centre
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Contact us
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Store locator
                  </ListGroupItem>
                </ListGroup>
              </AccordionItem>
              <AccordionItem
                id="company"
                title="Company"
                className="ln-o-grid__item ln-u-1/4@sm"
              >
                <ListGroup
                  actionable
                  color="light"
                  className="ln-u-padding-right*2"
                >
                  <ListGroupItem element="a" href="#">
                    About Sainsbury&rsquo;s
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Local charity partnership
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Business gift cards
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Sainsbury&rsquo;s jobs
                  </ListGroupItem>
                </ListGroup>
              </AccordionItem>
              <AccordionItem
                id="shop"
                title="Shop with us"
                className="ln-o-grid__item ln-u-1/4@sm"
              >
                <ListGroup
                  actionable
                  color="light"
                  className="ln-u-padding-right*2"
                >
                  <ListGroupItem element="a" href="#">
                    Groceries
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Tu Clothing
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Sainsbury&rsquo;s Bank
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    Argos
                  </ListGroupItem>
                </ListGroup>
              </AccordionItem>
              <AccordionItem
                id="contact"
                title="Tell us what you think"
                className="ln-o-grid__item ln-u-1/4@sm"
              >
                <ListGroup
                  actionable
                  color="light"
                  className="ln-u-padding-right*2"
                >
                  <ListGroupItem element="a" href="#">
                    About shopping in-store
                  </ListGroupItem>
                  <ListGroupItem element="a" href="#">
                    About shopping online
                  </ListGroupItem>
                </ListGroup>
              </AccordionItem>
            </Accordion>
          </GridWrapper>
        </Container>
      </FooterNav>

      <Container>
        <hr className="ln-u-push-ends-xl" />

        <FlagWrapper respondAt="sm" className="ln-u-caption">
          <FlagBody className="ln-u-push-right-lg">
            &copy; J Sainsbury plc {new Date().getFullYear()}
          </FlagBody>
          <FlagComponent alignment="top">
            <div className="ln-u-display-flex ln-u-flex-wrap">
              {links.map((link, index) => (
                <span
                  className="ln-u-display-flex ln-u-align-items-center ln-u-text-align-center"
                  key={link}
                >
                  <a href="#link" className="ln-o-bare-link">
                    {link}
                  </a>
                  {index !== links.length - 1 && (
                    <span className="ln-u-push-sides-sm">|</span>
                  )}
                </span>
              ))}
            </div>
          </FlagComponent>
        </FlagWrapper>
        
      </Container>
    </Footer>
  )
}
`

Standard.parameters = {
  docs: {
    source: {
      code: StandardSourceCode,
    },
  },
}
