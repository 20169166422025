exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-accessibility-accessibility-standards-email-mdx": () => import("./../../../src/pages/accessibility/accessibility-standards-email.mdx" /* webpackChunkName: "component---src-pages-accessibility-accessibility-standards-email-mdx" */),
  "component---src-pages-accessibility-accessibility-standards-for-native-mdx": () => import("./../../../src/pages/accessibility/accessibility-standards-for-native.mdx" /* webpackChunkName: "component---src-pages-accessibility-accessibility-standards-for-native-mdx" */),
  "component---src-pages-accessibility-accessibility-standards-for-web-mdx": () => import("./../../../src/pages/accessibility/accessibility-standards-for-web.mdx" /* webpackChunkName: "component---src-pages-accessibility-accessibility-standards-for-web-mdx" */),
  "component---src-pages-accessibility-index-mdx": () => import("./../../../src/pages/accessibility/index.mdx" /* webpackChunkName: "component---src-pages-accessibility-index-mdx" */),
  "component---src-pages-accessibility-testing-for-accessibility-mdx": () => import("./../../../src/pages/accessibility/testing-for-accessibility.mdx" /* webpackChunkName: "component---src-pages-accessibility-testing-for-accessibility-mdx" */),
  "component---src-pages-components-accordion-mdx": () => import("./../../../src/pages/components/accordion.mdx" /* webpackChunkName: "component---src-pages-components-accordion-mdx" */),
  "component---src-pages-components-alerts-mdx": () => import("./../../../src/pages/components/alerts.mdx" /* webpackChunkName: "component---src-pages-components-alerts-mdx" */),
  "component---src-pages-components-autocomplete-mdx": () => import("./../../../src/pages/components/autocomplete.mdx" /* webpackChunkName: "component---src-pages-components-autocomplete-mdx" */),
  "component---src-pages-components-breadcrumb-mdx": () => import("./../../../src/pages/components/breadcrumb.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumb-mdx" */),
  "component---src-pages-components-button-group-mdx": () => import("./../../../src/pages/components/button-group.mdx" /* webpackChunkName: "component---src-pages-components-button-group-mdx" */),
  "component---src-pages-components-button-mdx": () => import("./../../../src/pages/components/button.mdx" /* webpackChunkName: "component---src-pages-components-button-mdx" */),
  "component---src-pages-components-card-mdx": () => import("./../../../src/pages/components/card.mdx" /* webpackChunkName: "component---src-pages-components-card-mdx" */),
  "component---src-pages-components-checkbox-mdx": () => import("./../../../src/pages/components/checkbox.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-mdx" */),
  "component---src-pages-components-container-mdx": () => import("./../../../src/pages/components/container.mdx" /* webpackChunkName: "component---src-pages-components-container-mdx" */),
  "component---src-pages-components-date-picker-mdx": () => import("./../../../src/pages/components/date-picker.mdx" /* webpackChunkName: "component---src-pages-components-date-picker-mdx" */),
  "component---src-pages-components-footer-mdx": () => import("./../../../src/pages/components/footer.mdx" /* webpackChunkName: "component---src-pages-components-footer-mdx" */),
  "component---src-pages-components-form-group-mdx": () => import("./../../../src/pages/components/form-group.mdx" /* webpackChunkName: "component---src-pages-components-form-group-mdx" */),
  "component---src-pages-components-grid-mdx": () => import("./../../../src/pages/components/grid.mdx" /* webpackChunkName: "component---src-pages-components-grid-mdx" */),
  "component---src-pages-components-header-mdx": () => import("./../../../src/pages/components/header.mdx" /* webpackChunkName: "component---src-pages-components-header-mdx" */),
  "component---src-pages-components-index-mdx": () => import("./../../../src/pages/components/index.mdx" /* webpackChunkName: "component---src-pages-components-index-mdx" */),
  "component---src-pages-components-link-mdx": () => import("./../../../src/pages/components/link.mdx" /* webpackChunkName: "component---src-pages-components-link-mdx" */),
  "component---src-pages-components-list-mdx": () => import("./../../../src/pages/components/list.mdx" /* webpackChunkName: "component---src-pages-components-list-mdx" */),
  "component---src-pages-components-modal-mdx": () => import("./../../../src/pages/components/modal.mdx" /* webpackChunkName: "component---src-pages-components-modal-mdx" */),
  "component---src-pages-components-pagination-mdx": () => import("./../../../src/pages/components/pagination.mdx" /* webpackChunkName: "component---src-pages-components-pagination-mdx" */),
  "component---src-pages-components-password-input-mdx": () => import("./../../../src/pages/components/password-input.mdx" /* webpackChunkName: "component---src-pages-components-password-input-mdx" */),
  "component---src-pages-components-progress-indicator-mdx": () => import("./../../../src/pages/components/progress-indicator.mdx" /* webpackChunkName: "component---src-pages-components-progress-indicator-mdx" */),
  "component---src-pages-components-radio-button-mdx": () => import("./../../../src/pages/components/radio-button.mdx" /* webpackChunkName: "component---src-pages-components-radio-button-mdx" */),
  "component---src-pages-components-search-mdx": () => import("./../../../src/pages/components/search.mdx" /* webpackChunkName: "component---src-pages-components-search-mdx" */),
  "component---src-pages-components-select-mdx": () => import("./../../../src/pages/components/select.mdx" /* webpackChunkName: "component---src-pages-components-select-mdx" */),
  "component---src-pages-components-switch-mdx": () => import("./../../../src/pages/components/switch.mdx" /* webpackChunkName: "component---src-pages-components-switch-mdx" */),
  "component---src-pages-components-tables-mdx": () => import("./../../../src/pages/components/tables.mdx" /* webpackChunkName: "component---src-pages-components-tables-mdx" */),
  "component---src-pages-components-tabs-mdx": () => import("./../../../src/pages/components/tabs.mdx" /* webpackChunkName: "component---src-pages-components-tabs-mdx" */),
  "component---src-pages-components-text-input-mdx": () => import("./../../../src/pages/components/text-input.mdx" /* webpackChunkName: "component---src-pages-components-text-input-mdx" */),
  "component---src-pages-components-toggle-button-mdx": () => import("./../../../src/pages/components/toggle-button.mdx" /* webpackChunkName: "component---src-pages-components-toggle-button-mdx" */),
  "component---src-pages-components-tooltip-mdx": () => import("./../../../src/pages/components/tooltip.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-mdx" */),
  "component---src-pages-content-content-foundations-accessible-content-mdx": () => import("./../../../src/pages/content/content-foundations/accessible-content.mdx" /* webpackChunkName: "component---src-pages-content-content-foundations-accessible-content-mdx" */),
  "component---src-pages-content-content-foundations-index-mdx": () => import("./../../../src/pages/content/content-foundations/index.mdx" /* webpackChunkName: "component---src-pages-content-content-foundations-index-mdx" */),
  "component---src-pages-content-content-foundations-structuring-content-mdx": () => import("./../../../src/pages/content/content-foundations/structuring-content.mdx" /* webpackChunkName: "component---src-pages-content-content-foundations-structuring-content-mdx" */),
  "component---src-pages-content-content-foundations-writing-content-mdx": () => import("./../../../src/pages/content/content-foundations/writing-content.mdx" /* webpackChunkName: "component---src-pages-content-content-foundations-writing-content-mdx" */),
  "component---src-pages-content-index-mdx": () => import("./../../../src/pages/content/index.mdx" /* webpackChunkName: "component---src-pages-content-index-mdx" */),
  "component---src-pages-content-style-guide-mdx": () => import("./../../../src/pages/content/style-guide.mdx" /* webpackChunkName: "component---src-pages-content-style-guide-mdx" */),
  "component---src-pages-content-writing-for-our-brands-argos-voice-guidelines-mdx": () => import("./../../../src/pages/content/writing-for-our-brands/argos-voice-guidelines.mdx" /* webpackChunkName: "component---src-pages-content-writing-for-our-brands-argos-voice-guidelines-mdx" */),
  "component---src-pages-content-writing-for-our-brands-comparing-brand-voices-mdx": () => import("./../../../src/pages/content/writing-for-our-brands/comparing-brand-voices.mdx" /* webpackChunkName: "component---src-pages-content-writing-for-our-brands-comparing-brand-voices-mdx" */),
  "component---src-pages-content-writing-for-our-brands-habitat-voice-guidelines-mdx": () => import("./../../../src/pages/content/writing-for-our-brands/habitat-voice-guidelines.mdx" /* webpackChunkName: "component---src-pages-content-writing-for-our-brands-habitat-voice-guidelines-mdx" */),
  "component---src-pages-content-writing-for-our-brands-index-mdx": () => import("./../../../src/pages/content/writing-for-our-brands/index.mdx" /* webpackChunkName: "component---src-pages-content-writing-for-our-brands-index-mdx" */),
  "component---src-pages-content-writing-for-our-brands-nectar-voice-guidelines-mdx": () => import("./../../../src/pages/content/writing-for-our-brands/nectar-voice-guidelines.mdx" /* webpackChunkName: "component---src-pages-content-writing-for-our-brands-nectar-voice-guidelines-mdx" */),
  "component---src-pages-content-writing-for-our-brands-sainsburys-voice-guidelines-mdx": () => import("./../../../src/pages/content/writing-for-our-brands/sainsburys-voice-guidelines.mdx" /* webpackChunkName: "component---src-pages-content-writing-for-our-brands-sainsburys-voice-guidelines-mdx" */),
  "component---src-pages-content-writing-in-experiences-buttons-and-links-mdx": () => import("./../../../src/pages/content/writing-in-experiences/buttons-and-links.mdx" /* webpackChunkName: "component---src-pages-content-writing-in-experiences-buttons-and-links-mdx" */),
  "component---src-pages-content-writing-in-experiences-error-messages-mdx": () => import("./../../../src/pages/content/writing-in-experiences/error-messages.mdx" /* webpackChunkName: "component---src-pages-content-writing-in-experiences-error-messages-mdx" */),
  "component---src-pages-content-writing-in-experiences-index-mdx": () => import("./../../../src/pages/content/writing-in-experiences/index.mdx" /* webpackChunkName: "component---src-pages-content-writing-in-experiences-index-mdx" */),
  "component---src-pages-content-writing-in-experiences-numbers-dates-and-time-mdx": () => import("./../../../src/pages/content/writing-in-experiences/numbers-dates-and-time.mdx" /* webpackChunkName: "component---src-pages-content-writing-in-experiences-numbers-dates-and-time-mdx" */),
  "component---src-pages-content-writing-in-experiences-onboarding-messages-mdx": () => import("./../../../src/pages/content/writing-in-experiences/onboarding-messages.mdx" /* webpackChunkName: "component---src-pages-content-writing-in-experiences-onboarding-messages-mdx" */),
  "component---src-pages-content-writing-in-experiences-success-messages-mdx": () => import("./../../../src/pages/content/writing-in-experiences/success-messages.mdx" /* webpackChunkName: "component---src-pages-content-writing-in-experiences-success-messages-mdx" */),
  "component---src-pages-experiences-digital-kiosks-mdx": () => import("./../../../src/pages/experiences/digital-kiosks.mdx" /* webpackChunkName: "component---src-pages-experiences-digital-kiosks-mdx" */),
  "component---src-pages-experiences-index-mdx": () => import("./../../../src/pages/experiences/index.mdx" /* webpackChunkName: "component---src-pages-experiences-index-mdx" */),
  "component---src-pages-foundations-colour-argos-colours-mdx": () => import("./../../../src/pages/foundations/colour/argos-colours.mdx" /* webpackChunkName: "component---src-pages-foundations-colour-argos-colours-mdx" */),
  "component---src-pages-foundations-colour-habitat-colours-mdx": () => import("./../../../src/pages/foundations/colour/habitat-colours.mdx" /* webpackChunkName: "component---src-pages-foundations-colour-habitat-colours-mdx" */),
  "component---src-pages-foundations-colour-index-mdx": () => import("./../../../src/pages/foundations/colour/index.mdx" /* webpackChunkName: "component---src-pages-foundations-colour-index-mdx" */),
  "component---src-pages-foundations-colour-nectar-colours-mdx": () => import("./../../../src/pages/foundations/colour/nectar-colours.mdx" /* webpackChunkName: "component---src-pages-foundations-colour-nectar-colours-mdx" */),
  "component---src-pages-foundations-colour-sainsburys-colours-mdx": () => import("./../../../src/pages/foundations/colour/sainsburys-colours.mdx" /* webpackChunkName: "component---src-pages-foundations-colour-sainsburys-colours-mdx" */),
  "component---src-pages-foundations-colour-tu-colours-mdx": () => import("./../../../src/pages/foundations/colour/tu-colours.mdx" /* webpackChunkName: "component---src-pages-foundations-colour-tu-colours-mdx" */),
  "component---src-pages-foundations-design-tokens-colour-tokens-mdx": () => import("./../../../src/pages/foundations/design-tokens/colour-tokens.mdx" /* webpackChunkName: "component---src-pages-foundations-design-tokens-colour-tokens-mdx" */),
  "component---src-pages-foundations-design-tokens-index-mdx": () => import("./../../../src/pages/foundations/design-tokens/index.mdx" /* webpackChunkName: "component---src-pages-foundations-design-tokens-index-mdx" */),
  "component---src-pages-foundations-design-tokens-lookup-mdx": () => import("./../../../src/pages/foundations/design-tokens/lookup.mdx" /* webpackChunkName: "component---src-pages-foundations-design-tokens-lookup-mdx" */),
  "component---src-pages-foundations-design-tokens-modifiers-tokens-mdx": () => import("./../../../src/pages/foundations/design-tokens/modifiers-tokens.mdx" /* webpackChunkName: "component---src-pages-foundations-design-tokens-modifiers-tokens-mdx" */),
  "component---src-pages-foundations-design-tokens-space-tokens-mdx": () => import("./../../../src/pages/foundations/design-tokens/space-tokens.mdx" /* webpackChunkName: "component---src-pages-foundations-design-tokens-space-tokens-mdx" */),
  "component---src-pages-foundations-index-mdx": () => import("./../../../src/pages/foundations/index.mdx" /* webpackChunkName: "component---src-pages-foundations-index-mdx" */),
  "component---src-pages-foundations-layout-mdx": () => import("./../../../src/pages/foundations/layout.mdx" /* webpackChunkName: "component---src-pages-foundations-layout-mdx" */),
  "component---src-pages-foundations-logos-mdx": () => import("./../../../src/pages/foundations/logos.mdx" /* webpackChunkName: "component---src-pages-foundations-logos-mdx" */),
  "component---src-pages-foundations-states-mdx": () => import("./../../../src/pages/foundations/states.mdx" /* webpackChunkName: "component---src-pages-foundations-states-mdx" */),
  "component---src-pages-foundations-typography-mdx": () => import("./../../../src/pages/foundations/typography.mdx" /* webpackChunkName: "component---src-pages-foundations-typography-mdx" */),
  "component---src-pages-gallery-index-mdx": () => import("./../../../src/pages/gallery/index.mdx" /* webpackChunkName: "component---src-pages-gallery-index-mdx" */),
  "component---src-pages-getting-started-for-designers-mdx": () => import("./../../../src/pages/getting-started/for-designers.mdx" /* webpackChunkName: "component---src-pages-getting-started-for-designers-mdx" */),
  "component---src-pages-getting-started-for-developers-mdx": () => import("./../../../src/pages/getting-started/for-developers.mdx" /* webpackChunkName: "component---src-pages-getting-started-for-developers-mdx" */),
  "component---src-pages-getting-started-for-third-party-partners-mdx": () => import("./../../../src/pages/getting-started/for-third-party-partners.mdx" /* webpackChunkName: "component---src-pages-getting-started-for-third-party-partners-mdx" */),
  "component---src-pages-getting-started-index-mdx": () => import("./../../../src/pages/getting-started/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-patterns-index-mdx": () => import("./../../../src/pages/patterns/index.mdx" /* webpackChunkName: "component---src-pages-patterns-index-mdx" */),
  "component---src-pages-resources-index-mdx": () => import("./../../../src/pages/resources/index.mdx" /* webpackChunkName: "component---src-pages-resources-index-mdx" */)
}

