import { Tick, Cancel } from '@jsluna/icons';
import { Body2 } from '@jsluna/typography';
import * as TabStories from '@stories/tabs.stories.js';
import * as React from 'react';
export default {
  Tick,
  Cancel,
  Body2,
  TabStories,
  React
};