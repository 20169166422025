import GlobalTokens from '@sainsburys-tech/tokens/dist/global/js/json/color/index.json';
import ColourBlock from '@components/colour-block';
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableRow, TableCell } from '@jsluna/table';
import { TypographyTableHeader, SainsburysTypographyTableBody, ArgosTypographyTableBody, HabitatTypographyTableBody, TuTypographyTableBody, NectarTypographyTableBody } from '@shared/tables/typography';
import HeadingDisplayTable from '@shared/tables/heading-display';
import { Tick, Cancel } from '@jsluna/icons';
import * as React from 'react';
export default {
  GlobalTokens,
  ColourBlock,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  TypographyTableHeader,
  SainsburysTypographyTableBody,
  ArgosTypographyTableBody,
  HabitatTypographyTableBody,
  TuTypographyTableBody,
  NectarTypographyTableBody,
  HeadingDisplayTable,
  Tick,
  Cancel,
  React
};