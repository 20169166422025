import React from 'react'
import { Alert, AlertIcon } from '@jsluna/alert'
import { ErrorCircle, InfoCircle } from '@jsluna/icons'
import { List, ListItem } from '@jsluna/list'

export default {
  title: 'Alert',
  name: 'Alert',
  component: Alert,
}

export const Standard = () => (
  <List type="bare" spaced>
    <ListItem type="bare">
      <Alert>1 item selected</Alert>
    </ListItem>
    <ListItem type="bare">
      <Alert variant="info">
        <AlertIcon>
          <InfoCircle aria-label="Information" role="img" />
        </AlertIcon>
        1 new task’s been assigned to you since you last logged in.
      </Alert>
    </ListItem>
    <ListItem type="bare">
      <Alert variant="error">
        <AlertIcon>
          <ErrorCircle aria-label="Error" role="img" />
        </AlertIcon>
        You haven’t added your address. Please provide your address to continue.
      </Alert>
    </ListItem>
    <ListItem type="bare">
      <Alert variant="warning">
        You haven’t added a phone number to your account. Adding a number means
        we can get in touch if we need to.
      </Alert>
    </ListItem>
    <ListItem type="bare">
      <Alert variant="success">
        We’ve added ‘Heinz Baked Beans’ to your trolley.
      </Alert>
    </ListItem>
  </List>
)

const ExampleSourceCode = (story, props) => `
import { Alert, AlertIcon } from '@jsluna/alert'
import { ErrorCircle, InfoCircle } from '@jsluna/icons'
import { List, ListItem } from '@jsluna/list'

export const ${story} = () => (
  <List type="bare" spaced>
    <ListItem type="bare">
      <Alert>1 item selected</Alert>
    </ListItem>
    <ListItem type="bare">
      <Alert variant="info"${props ? ` ${props}` : ''}>
        <AlertIcon>
          <InfoCircle aria-label="Information" role="img" />
        </AlertIcon>
        1 new task’s been assigned to you since you last logged in.
      </Alert>
    </ListItem>
    <ListItem type="bare">
      <Alert variant="error"${props ? ` ${props}` : ''}>
        <AlertIcon>
          <ErrorCircle aria-label="Error" role="img" />
        </AlertIcon>
        You haven’t added your address. Please provide your address to continue.
      </Alert>
    </ListItem>
    <ListItem type="bare">
      <Alert variant="warning"${props ? ` ${props}` : ''}>
        You haven’t added a phone number to your account. Adding a number means
        we can get in touch if we need to.
      </Alert>
    </ListItem>
    <ListItem type="bare">
      <Alert variant="success"${props ? ` ${props}` : ''}>
        We’ve added ‘Heinz Baked Beans’ to your trolley.
      </Alert>
    </ListItem>
  </List>
)
`

Standard.parameters = {
  docs: {
    source: {
      code: ExampleSourceCode('Standard'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Dismissable = args => (
  <List type="bare" spaced>
    <ListItem type="bare">
      <Alert {...args}>1 item selected</Alert>
    </ListItem>
    <ListItem type="bare">
      <Alert variant="info" {...args}>
        <AlertIcon>
          <InfoCircle aria-label="Information" role="img" />
        </AlertIcon>
        1 new task’s been assigned to you since you last logged in.
      </Alert>
    </ListItem>
    <ListItem type="bare">
      <Alert variant="error" {...args}>
        <AlertIcon>
          <ErrorCircle aria-label="Error" role="img" />
        </AlertIcon>
        You haven’t added your address. Please provide your address to continue.
      </Alert>
    </ListItem>
    <ListItem type="bare">
      <Alert variant="warning" {...args}>
        You haven’t added a phone number to your account. Adding a number means
        we can get in touch if we need to.
      </Alert>
    </ListItem>
    <ListItem type="bare">
      <Alert variant="success" {...args}>
        We’ve added ‘Heinz Baked Beans’ to your trolley.
      </Alert>
    </ListItem>
  </List>
)

Dismissable.args = {
  open: true,
  onClose: () => {},
  squared: true,
}

Dismissable.parameters = {
  docs: {
    source: {
      code: ExampleSourceCode('Dismissable', 'open onClose={() => {}} squared'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const InformationMessages = () => (
  <Alert variant="info" open onClose={() => {}} squared>
    <AlertIcon>
      <InfoCircle aria-label="Information" role="img" />
    </AlertIcon>
    1 new task’s been assigned to you since you last logged in.
  </Alert>
)

const InformationMessagesSourceCode = `
import { Alert, AlertIcon } from '@jsluna/alert'
import { InfoCircle } from '@jsluna/icons'
      
export const InformationMessages = () => (
  <Alert variant="info" open onClose={() => {}} squared>
    <AlertIcon>
      <InfoCircle aria-label="Information" role="img" />
    </AlertIcon>
    1 new task’s been assigned to you since you last logged in.
  </Alert>
)
`

InformationMessages.parameters = {
  docs: {
    source: {
      code: InformationMessagesSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const ErrorMessages = () => (
  <Alert variant="error" open onClose={() => {}} squared>
    <AlertIcon>
      <InfoCircle aria-label="Error" role="img" />
    </AlertIcon>
    You haven’t added your address. Please provide your address to continue.
  </Alert>
)

const ErrorMessagesSourceCode = `
import { Alert, AlertIcon } from '@jsluna/alert'
import { InfoCircle } from '@jsluna/icons'
      
export const ErrorMessages = () => (
  <Alert variant="error" open onClose={() => {}} squared>
    <AlertIcon>
      <InfoCircle aria-label="Error" role="img" />
    </AlertIcon>
    You haven’t added your address. Please provide your address to continue.
  </Alert>
)
`

ErrorMessages.parameters = {
  docs: {
    source: {
      code: ErrorMessagesSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WarningMessages = () => (
  <Alert variant="warning" open onClose={() => {}} squared>
    You haven’t added a phone number to your account. Adding a number means we
    can get in touch if we need to.
  </Alert>
)

const WarningMessagesSourceCode = `
import { Alert } from '@jsluna/alert'
      
export const WarningMessages = () => (
  <Alert variant="warning" open onClose={() => {}} squared>
    You haven’t added a phone number to your account. Adding a number means we
    can get in touch if we need to.
  </Alert>
)
`

WarningMessages.parameters = {
  docs: {
    source: {
      code: WarningMessagesSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const SuccessMessages = () => (
  <Alert variant="success" open onClose={() => {}} squared>
    We’ve added ‘Heinz Baked Beans’ to your trolley.
  </Alert>
)

const SuccessMessagesSourceCode = `
import { Alert } from '@jsluna/alert'
      
export const SuccessMessages = () => (
  <Alert variant="success" open onClose={() => {}} squared>
    We’ve added ‘Heinz Baked Beans’ to your trolley.
  </Alert>
)
`

SuccessMessages.parameters = {
  docs: {
    source: {
      code: SuccessMessagesSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
