import React from 'react'
import { Pagination } from '@jsluna/pagination'

export default {
  title: 'Pagination',
  component: Pagination,
}

export const Standard = () => {
  const [currentPage, setCurrentPage] = React.useState(1)

  return (
    <div class="ln-u-text-align-center">
      <Pagination
        showFirstAndLast
        showPages
        current={currentPage}
        total={12}
        onChange={(page, e) => {
          e.preventDefault()
          setCurrentPage(page)
        }}
      />
    </div>
  )
}

const StandardSourceCode = `
import { Pagination } from '@jsluna/pagination'

export const Standard = () => {
  const [currentPage, setCurrentPage] = React.useState(1)

  return (
    <div class="ln-u-text-align-center">
      <Pagination
        showFirstAndLast
        showPages
        current={currentPage}
        total={12}
        onChange={(page, e) => {
          e.preventDefault()
          setCurrentPage(page)
        }}
      />
    </div>
  )
}
`

Standard.parameters = {
  docs: {
    source: {
      code: StandardSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Compact = () => {
  const [currentPage, setCurrentPage] = React.useState(1)

  return (
    <div class="ln-u-text-align-center">
      <Pagination
        showFirstAndLast
        current={currentPage}
        total={12}
        onChange={(page, e) => {
          e.preventDefault()
          setCurrentPage(page)
        }}
        createHref={page => `/${page}`}
      />
    </div>
  )
}

const CompactSourceCode = `
import { Pagination } from '@jsluna/pagination'

export const Compact = () => {
  const [currentPage, setCurrentPage] = React.useState(1)

  return (
    <div class="ln-u-text-align-center">
      <Pagination
        showFirstAndLast
        current={currentPage}
        total={12}
        onChange={(page, e) => {
          e.preventDefault()
          setCurrentPage(page)
        }}
        createHref={page => \`/\${page}\`}
      />
    </div>
  )
}
`

Compact.parameters = {
  docs: {
    source: {
      code: CompactSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
