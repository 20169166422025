import React from 'react'
import { SelectField } from '@jsluna/form'

export default {
  title: 'Select',
  component: SelectField,
}

export const Single = () => {
  const options = [
    { label: "Sainsbury's Groceries", value: 'groceries' },
    { label: "Sainsbury's Bank", value: 'bank' },
    { label: 'Tu', value: 'tu' },
    { label: 'Argos', value: 'argos' },
    { label: 'Habitat', value: 'habitat' },
    { label: 'Nectar', value: 'nectar' },
  ]
  return (
    <SelectField
      name="select-field-1"
      label="Select mission"
      options={options}
    />
  )
}

const SingleSourceCode = `
import { SelectField } from '@jsluna/form'

export const Single = () => {
  const options = [
    { label: "Sainsbury's Groceries", value: 'groceries' },
    { label: "Sainsbury's Bank", value: 'bank' },
    { label: 'Tu', value: 'tu' },
    { label: 'Argos', value: 'argos' },
    { label: 'Habitat', value: 'habitat' },
    { label: 'Nectar', value: 'nectar' },
  ]
  return (
    <SelectField
      name="select-field-1"
      label="Select mission"
      options={options}
    />
  )
}
`

Single.parameters = {
  docs: {
    source: {
      code: SingleSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const WithErrorMessage = () => {
  const options = [
    { label: 'Credit card', value: 'credit' },
    { label: 'Savings account', value: 'savings' },
    { label: 'Loan', value: 'loan' },
    { label: 'Mortgage', value: 'mortgage' },
  ]
  return (
    <SelectField
      name="select-field-2"
      label="Select existing account held with us"
      options={options}
      error="Sorry online account management is only available for credit cardholders"
      defaultValue="loan"
    />
  )
}

const WithErrorMessageSourceCode = `
import { SelectField } from '@jsluna/form'

export const WithErrorMessage = () => {
  const options = [
    { label: 'Credit card', value: 'credit' },
    { label: 'Savings account', value: 'savings' },
    { label: 'Loan', value: 'loan' },
    { label: 'Mortgage', value: 'mortgage' },
  ]
  return (
    <SelectField
      name="select-field-2"
      label="Select existing account held with us"
      options={options}
      error="Sorry online account management is only available for credit cardholders"
      defaultValue="loan"
    />
  )
}
`

WithErrorMessage.parameters = {
  docs: {
    source: {
      code: WithErrorMessageSourceCode,
      language: 'jsx',
      type: 'auto',
    },
  },
}
