import React from 'react'
import { Button, IconButton } from '@jsluna/button'
import { Plus } from '@jsluna/icons'

export default {
  title: 'Button',
  component: Button,
  subcomponents: { IconButton },
  argTypes: {
    circle: 'boolean',
    disabled: 'boolean',
    hard: 'boolean',
    fullWidth: 'boolean',
    element: 'string',
    label: 'string',
    variant: {
      control: {
        type: 'inline-radio',
        options: ['filled', 'link', 'outlined', 'text'],
      },
    },
    color: {
      control: {
        type: 'inline-radio',
        options: ['dark', 'light', 'brand'],
      },
    },
    onClick: { action: 'onClick' },
  },
  args: {
    circle: false,
    disabled: false,
    hard: false,
    element: 'button',
  },
}

const SingleExampleSourceCode = (story, props) => `
import { Button } from '@jsluna/button'

export const ${story} = () => (
  <Button${props ? ` ${props}` : ''}>Add to trolley</Button$>
)
`

const GroupedExampleSourceCode = (story, props) => `
import { Button } from '@jsluna/button'

export const ${story} = () => (
  <div
  style={{
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  }}
  >
    <Button variant="filled"${props ? ` ${props}` : ''}>Add to trolley</Button>
    <Button variant="outlined"${
      props ? ` ${props}` : ''
    }>Add to trolley</Button>
    <Button variant="text"${props ? ` ${props}` : ''}>Add to trolley</Button>
  </div>
)
`

const IconExampleSourceCode = (story, props) => `
import { IconButton } from '@jsluna/button'
import { Plus } from '@jsluna/icons'

export const ${story} = () => (
  <div
  style={{
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  }}
  >
    <IconButton variant="filled"${props ? ` ${props}` : ''}>
      <Plus />
    </IconButton>
    <IconButton variant="outlined"${props ? ` ${props}` : ''}>
      <Plus />
    </IconButton>
    <IconButton variant="text"${props ? ` ${props}` : ''}>
      <Plus />
    </IconButton>
  </div>
)
`

export const Filled = args => <Button {...args}>Add to trolley</Button>
Filled.args = {
  variant: 'filled',
}

Filled.parameters = {
  docs: {
    source: {
      code: SingleExampleSourceCode('Filled', 'variant="filled"'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Outlined = args => <Button {...args}>Add to trolley</Button>
Outlined.args = {
  variant: 'outlined',
}

Outlined.parameters = {
  docs: {
    source: {
      code: SingleExampleSourceCode('Outlined', 'variant="outlined"'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Transparent = args => <Button {...args}>Add to trolley</Button>
Transparent.args = {
  variant: 'text',
}

Transparent.parameters = {
  docs: {
    source: {
      code: SingleExampleSourceCode('Transparent', 'variant="text"'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Click = args => (
  <Button onClick={() => alert('You clicked me!')} {...args}>
    Click event
  </Button>
)
Click.args = {
  variant: 'filled',
}

Click.parameters = {
  docs: {
    source: {
      code: SingleExampleSourceCode(
        'Click',
        'variant="filled" onClick={() => alert("You clicked me!")',
      ),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Element = args => <Button {...args}>Add to trolley</Button>
Element.args = {
  variant: 'filled',
  element: 'a',
}

Element.parameters = {
  docs: {
    source: {
      code: SingleExampleSourceCode('Element', 'variant="filled" element="a"'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Text = () => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
    }}
  >
    <Button variant="filled">Add to trolley</Button>
    <Button variant="outlined">Add to trolley</Button>
    <Button variant="text">Add to trolley</Button>
  </div>
)

Text.parameters = {
  docs: {
    source: {
      code: GroupedExampleSourceCode('Text'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const IconAndText = args => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
    }}
  >
    <IconButton variant="filled" {...args}>
      <Plus />
    </IconButton>
    <IconButton variant="outlined" {...args}>
      <Plus />
    </IconButton>
    <IconButton variant="text" {...args}>
      <Plus />
    </IconButton>
  </div>
)
IconAndText.args = {
  label: 'Add to trolley',
}

IconAndText.parameters = {
  docs: {
    source: {
      code: IconExampleSourceCode('IconAndText', 'label="Add to trolley"'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Icon = args => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
    }}
  >
    <IconButton variant="filled" {...args}>
      <Plus />
    </IconButton>
    <IconButton variant="outlined" {...args}>
      <Plus />
    </IconButton>
    <IconButton variant="text" {...args}>
      <Plus />
    </IconButton>
  </div>
)
Icon.args = {
  label: 'Add to trolley',
  hideLabel: true,
}

Icon.parameters = {
  docs: {
    source: {
      code: IconExampleSourceCode('Icon', 'hideLabel label="Add to trolley"'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Brand = () => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
    }}
  >
    <Button variant="filled">Add to trolley</Button>
    <Button variant="outlined">Add to trolley</Button>
    <Button variant="text">Add to trolley</Button>
  </div>
)

Brand.parameters = {
  docs: {
    source: {
      code: GroupedExampleSourceCode('Brand'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Dark = args => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
    }}
  >
    <Button variant="filled" {...args}>
      Add to trolley
    </Button>
    <Button variant="outlined" {...args}>
      Add to trolley
    </Button>
    <Button variant="text" {...args}>
      Add to trolley
    </Button>
  </div>
)

Dark.args = {
  color: 'dark',
}

Dark.parameters = {
  docs: {
    source: {
      code: GroupedExampleSourceCode('Dark', 'color="dark"'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Light = args => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
    }}
  >
    <Button variant="filled" {...args}>
      Add to trolley
    </Button>
    <Button variant="outlined" {...args}>
      Add to trolley
    </Button>
    <Button variant="text" {...args}>
      Add to trolley
    </Button>
  </div>
)
Light.args = {
  color: 'light',
}

Light.parameters = {
  backgrounds: { default: 'dark' },
  docs: {
    source: {
      code: GroupedExampleSourceCode('Light', 'color="light"'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const Disabled = args => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
    }}
  >
    <Button variant="filled" {...args}>
      Add to trolley
    </Button>
    <Button variant="outlined" {...args}>
      Add to trolley
    </Button>
    <Button variant="text" {...args}>
      Add to trolley
    </Button>
  </div>
)

Disabled.args = {
  disabled: true,
}

Disabled.parameters = {
  docs: {
    source: {
      code: GroupedExampleSourceCode('Disabled', 'disabled'),
      language: 'jsx',
      type: 'auto',
    },
  },
}

export const FullWidth = args => <Button {...args}>Full Width</Button>

FullWidth.args = {
  variant: 'filled',
  fullWidth: true,
}

FullWidth.parameters = {
  docs: {
    source: {
      code: SingleExampleSourceCode('FullWidth', 'variant="filled" fullWidth'),
      language: 'jsx',
      type: 'auto',
    },
  },
}
