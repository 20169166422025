import React from 'react'

import {
  GridWrapper,
  Accordion,
  AccordionItem,
  Link,
  SearchInput,
  SearchButton,
} from '@jsluna/react'

import CodeSnippet from './CodeSnippet'

const ResponsiveLayout = () => (
  <section>
    <h2>Make sure page layouts respond and adapt without losing information</h2>

    <p>Only make users scroll in one direction when zoomed in at 400%.</p>

    <Accordion standalone>
      <AccordionItem title="Why must layouts respond and adapt?">
        <p>Layouts should respond and adapt because:</p>

        <ul>
          <li>
            it gives the best user experience for different viewport sizes,
            application and operating system text settings, and zooming
          </li>
          <li>
            fixed widths, heights and font sizes can cause information on the
            page to be lost if text content gets clipped
          </li>
          <li>
            two-directional scrolling puts additional strain on users with
            dexterity and mobility impairments
          </li>
          <li>
            two-directional scrolling is disorientating for visually impaired
            users who are zoomed into a page that doesn’t respond and reflow to
            fit the viewport
          </li>
        </ul>

        <h3>W3C Web Content Accessibility Guideline requirements:</h3>

        <ul>
          <li>
            <Link href="https://www.w3.org/WAI/WCAG21/Understanding/resize-text.html">
              Understanding Success Criterion 1.4.4: Resize text
            </Link>
          </li>
          <li>
            <Link href="https://www.w3.org/WAI/WCAG21/Understanding/reflow.html">
              Understanding Success Criterion 1.4.10: Reflow
            </Link>
          </li>
        </ul>
      </AccordionItem>
    </Accordion>
    <GridWrapper equalHeight>
      <CodeSnippet
        type="positive"
        code={null}
        component={
          <div style={{ height: '260px', overflow: 'scroll' }}>
            <h2>This is a responsive layout example</h2>
            <p>A single design that reflows across different displays</p>

            <img
              src="/assets/images/visual-content-1.png"
              alt=""
              style={{ width: '100%', height: 'auto' }}
            />
            <img
              src="/assets/images/visual-content-2.png"
              alt=""
              style={{ width: '48%', height: 'auto', marginRight: '4%' }}
            />
            <img
              src="/assets/images/visual-content-3.png"
              alt=""
              style={{ width: '48%', height: 'auto' }}
            />
          </div>
        }
      />
      <CodeSnippet
        type="negative"
        code={null}
        component={
          <div style={{ height: '260px', overflow: 'scroll' }}>
            <div style={{ width: '820px' }}>
              <div>
                <img
                  src="/assets/images/visual-content-1.png"
                  alt=""
                  style={{
                    width: '400px',
                    height: 'auto',
                    marginRight: '10px',
                  }}
                />
                <img
                  src="/assets/images/visual-content-2.png"
                  alt=""
                  style={{
                    width: '200px',
                    height: 'auto',
                    marginRight: '10px',
                  }}
                />
                <img
                  src="/assets/images/visual-content-3.png"
                  alt=""
                  style={{ width: '200px', height: 'auto' }}
                />
              </div>
            </div>
          </div>
        }
      />
    </GridWrapper>

    <p>
      Make sure larger font settings and zoom don’t cause information on the
      page to be lost.
    </p>

    <GridWrapper equalHeight>
      <CodeSnippet
        type="positive"
        code={null}
        component={
          <SearchInput
            name="zoom-example-search-1"
            placeholder="Search products"
            hasButton
            button={<SearchButton />}
          />
        }
      />

      <CodeSnippet
        type="negative"
        code={null}
        component={
          <SearchInput
            name="zoom-example-search-2"
            placeholder="Search products"
            style={{ fontSize: '90px', height: '48px' }}
            hasButton
            button={<SearchButton />}
          />
        }
      />
    </GridWrapper>
  </section>
)

export default ResponsiveLayout
