import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Display1 } from '@jsluna/typography'
import Card from '@components/card'
import { GridItem, GridWrapper } from '@jsluna/grid'
import { pascalCase } from 'change-case'

import * as Tile from '@images/svg/tiles'

export default function ComponentLanding() {
  const componentPages = useStaticQuery(graphql`
    query ComponentListingQuery {
      allMdx(
        sort: { fields: frontmatter___title, order: ASC }
        filter: { slug: { glob: "components/*" } }
      ) {
        edges {
          node {
            id
            slug
            frontmatter {
              title
              featuredImage
            }
          }
        }
      }
    }
  `)

  const images = {
    Accordion: <Tile.Accordion aria-hidden="true" />,
    Autocomplete: <Tile.Autocomplete aria-hidden="true" />,
    Breadcrumb: <Tile.Breadcrumb aria-hidden="true" />,
    ButtonGroup: <Tile.ButtonGroup aria-hidden="true" />,
    Button: <Tile.Button aria-hidden="true" />,
    Card: <Tile.Card aria-hidden="true" />,
    Checkbox: <Tile.Checkbox aria-hidden="true" />,
    Container: <Tile.Container aria-hidden="true" />,
    DatePicker: <Tile.DatePicker aria-hidden="true" />,
    Flag: <Tile.Flag aria-hidden="true" />,
    Footer: <Tile.Footer aria-hidden="true" />,
    FormGroup: <Tile.FormGroup aria-hidden="true" />,
    Grid: <Tile.Grid aria-hidden="true" />,
    Header: <Tile.Header aria-hidden="true" />,
    Link: <Tile.Link aria-hidden="true" />,
    List: <Tile.List aria-hidden="true" />,
    Modal: <Tile.Modal aria-hidden="true" />,
    Pagination: <Tile.Pagination aria-hidden="true" />,
    PasswordInput: <Tile.PasswordInput aria-hidden="true" />,
    ProgressIndicator: <Tile.ProgressIndicator aria-hidden="true" />,
    RadioButton: <Tile.RadioButton aria-hidden="true" />,
    Search: <Tile.Search aria-hidden="true" />,
    Select: <Tile.Select aria-hidden="true" />,
    Switch: <Tile.Switch aria-hidden="true" />,
    Table: <Tile.Table aria-hidden="true" />,
    Tabs: <Tile.Tabs aria-hidden="true" />,
    TextInput: <Tile.TextInput aria-hidden="true" />,
    ToggleButton: <Tile.ToggleButton aria-hidden="true" />,
  }

  return (
    <GridWrapper equalHeight aria-label="list of components">
      {componentPages.allMdx.edges.map(({ node }) => (
        <GridItem size={{ sm: '1/2', md: '1/3' }} key={node.id}>
          <Link to={`/${node.slug}`} className="c-tile">
            <Display1 element="h2" className="ln-u-margin-bottom">
              {node.frontmatter.title}
            </Display1>
            <Card>
              {images[node.frontmatter.featuredImage]
                ? images[node.frontmatter.featuredImage]
                : images[pascalCase(node.frontmatter.title)]
                ? images[pascalCase(node.frontmatter.title)]
                : images['Card']}
            </Card>
          </Link>
        </GridItem>
      ))}
    </GridWrapper>
  )
}
